import { Box, Flex, Stack, Image, Heading, Text } from '@chakra-ui/react'
import AAgroD from 'components/Home/A_AgroD'
import ACMD from 'components/Home/A_CMD'
import AGPSTracker from 'components/Home/A_GPSTracker'
import ALed from 'components/Home/A_Led'

//* Images
import background from 'assets/redesignImages/solutions/Group 1613.png'
import firstDivider from 'assets/redesignImages/solutions/firstDivider.svg'
import secondDivider from 'assets/redesignImages/solutions/secondDivider.svg'
import thirdDivider from 'assets/redesignImages/solutions/thirdDivider.svg'

const Section = ({
  animation,
  title,
  shortDesc,
  description,
  reverse,
  divider,
}: any) => {
  return (
    <Flex
      direction={reverse ? 'row-reverse' : 'row'}
      alignItems='center'
      color='#707070'
      paddingX='2em'
      height='50vh'
      position='relative'
    >
      {/* Container on left */}
      <Stack position='relative' alignItems='center' paddingTop='20em' flex={1}>
        <Flex zIndex={50}>{animation}</Flex>
        <Image src={background} position='absolute' top={0} zIndex={5} />
        <Box
          borderRadius='100%'
          width='600px'
          height='600px'
          position='absolute'
          top='140px'
          zIndex={10}
          backgroundColor='rgba(255, 255, 255, 0.5)'
        />
        <Box
          borderRadius='100%'
          width='650px'
          height='650px'
          position='absolute'
          top='115px'
          zIndex={20}
          backgroundColor='transparent'
          border='2px dashed white'
        />
      </Stack>
      {/* Text on right */}
      <Stack flex={1} paddingTop='10em'>
        <Heading fontSize='20px'>{title}</Heading>
        <Text fontSize='18px' paddingBottom='1em'>
          {shortDesc}
        </Text>
        <Text fontSize='16px'>{description}</Text>
      </Stack>
      {/* Bottom Divider */}
      <Image
        src={divider}
        alt='divider'
        position='absolute'
        left={0}
        bottom={-500}
        w='100%'
      />
    </Flex>
  )
}

const Solutions = () => {
  return (
    <Stack backgroundColor='white' minHeight='100vh' gap='50vh'>
      <Section
        animation={<ACMD />}
        title='CMD'
        shortDesc='Condition Monitoring Device - See, Evaluate and Feel the Invisible!'
        description='While breathing is a motor sensory function of the body to keep us alive, what we breathe in is usually unknown. Our solutions inform and give you a choice to find out what exactly you will be breathing before you even go there. Additionally, since we continuously monitor, you can decide to leave the venue, when it becomes not healthy anymore, or when it is too loud/noisy for your standards, or too hot. Our mission is to ensure that our green technology informs and offers a right choice for your healthy wellbeing!'
        divider={firstDivider}
      />
      <Section
        animation={<AGPSTracker />}
        title='GPS TRACKER'
        shortDesc='Chain logistics/dispatching- have your always available and reliable awareness agent!'
        description='Your business is expecting a sensitive shipment and you keep wondering about its whereabouts, temperature, box positioning etc? Obviously, you will not want to spend your time sitting and watching, nevertheless you need an awareness. Therefore we offer complete chain logistics solutions. Are you into the delivery business? Our dispatch solution allows you to schedule trips and continuously monitor them.'
        divider={secondDivider}
        reverse
      />
      <Section
        animation={<ALed />}
        title='LED'
        shortDesc='LED-Lighting- Create Your Perfect Nature and Ambience!'
        description="Ambient light sensing and complete spectrum control is the greatest challenge for LED lighting fixtures. Our solution, true to principles of hardware and software equality, orchestrates total control of light in the space. Our dynamic controls are able to measure and adjust continuously. As Harshaw experiments revealed, this feature has immense impact and amazing results on the plant growth. True sunlight that we create inside the building can have incredible effects on people's attitude in living spaces and boost productivity and workplace satisfaction to new heights."
        divider={thirdDivider}
      />
      <Section
        animation={<AAgroD />}
        title='AGRO-D'
        shortDesc='Smart Farming: When Nature Pairs With Technology'
        description='Our IoT smart farming solution is a system that is built for monitoring the crop field with the help of sensors (light, humidity, temperature, soil moisture, crop health, etc., automating the irrigation system, tracking/calculating and managing the water and use. Customized Alert notification system to monitor real time data reporting. The farmers can monitor the field conditions from anywhere. Spend your resources smart, let the new technology take care of your farming and multiply your agribusiness income. Use of modern technology allows drastically cut your expenses on workforce, various agricultural equipment and machinery. The accurate sensors collect and transmit your agribusiness data to one single dashboard. Use and manage your monitoring data to make informed and timely decisions for your business prosperity.'
        reverse
      />
      <Text
        fontSize='15px'
        textAlign='center'
        boxShadow='0px 3px 25px #0033FFF0'
        backgroundColor='#06083E'
        width='100%'
        padding='1em'
        color='white'
      >
        © 2022 www.greenwaveiot.com All Rights Reserved.
      </Text>
    </Stack>
  )
}

export default Solutions
