import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'

import background from 'assets/redesignImages/partner/background.png'
import curve from 'assets/redesignImages/partner/curve.svg'
//* social icons
import facebook from 'assets/redesignImages/home/facebook.png'
import linkedin from 'assets/redesignImages/home/linkedin.png'
import twitter from 'assets/redesignImages/home/twitter.png'

const TopSection = () => {
  return (
    <Stack position='relative'>
      <Image
        src={background}
        alt='background'
        position='absolute'
        width='100%'
        maxHeight='100vh'
        objectFit='cover'
        top='-100px'
        left={0}
        zIndex={0}
      />
      <Box
        width='100%'
        height='100vh'
        position='absolute'
        top='-108px'
        left={0}
        zIndex={10}
        backgroundColor='rgba(0, 0, 0, 0.4)'
      />
      <Flex
        width='100%'
        height='calc(100vh - 100px)'
        justifyContent='center'
        alignItems='center'
        zIndex={20}
        position='relative'
      >
        <Heading
          color='white'
          maxW='600px'
          textAlign='center'
          letterSpacing='1.75px'
        >
          WE WANT OUR PARTNERS TO BE A RELIABLE SOLUTION PROVIDERS FOR THEIR
          CUSTOMERS.
        </Heading>
        <Stack position='absolute' top='40%' left='auto' right='2em' gap='1em'>
          {/* TODO: Add links here for socials */}
          <a href='#'>
            <img
              src={facebook}
              alt='facebook'
              loading='lazy'
              width='45px'
              height='45px'
            />
          </a>
          <a href='#'>
            <img
              src={twitter}
              alt='twitter'
              loading='lazy'
              width='45px'
              height='45px'
            />
          </a>
          <a href='#'>
            <img
              src={linkedin}
              alt='linkedIn'
              loading='lazy'
              width='45px'
              height='45px'
            />
          </a>
        </Stack>
      </Flex>
    </Stack>
  )
}

const FormSection = () => {
  return (
    <Stack
      alignItems='center'
      paddingY='3em'
      backgroundColor='white'
      position='relative'
      minHeight='100vh'
      maxHeight='100vh'
      overflow='hidden'
    >
      <Heading color='#090842' fontSize='2.4rem'>
        Become a Partner
      </Heading>
      <Text color='#06083E' paddingBottom='2em' fontSize='1.4rem'>
        FILL OUT OUR FORM BELLOW AND BE PROFESSIONAL AND SUCCESSFUL WITH US.
      </Text>
      {/* Form card */}

      <Stack
        padding='2em'
        boxShadow='5px 5px 42px #0101015E'
        maxWidth='575px'
        width='100%'
        borderRadius='10px'
        gap='1.5em'
        zIndex={100}
        backgroundColor='white'
      >
        <Input
          borderColor='#040D2F'
          focusBorderColor='#040D2F'
          placeholder='First name *'
          required
        />
        <Input
          borderColor='#040D2F'
          focusBorderColor='#040D2F'
          placeholder='Last name *'
          required
        />
        <Input
          borderColor='#040D2F'
          focusBorderColor='#040D2F'
          placeholder='Organization name *'
          required
        />
        <Input
          borderColor='#040D2F'
          focusBorderColor='#040D2F'
          placeholder='Email *'
          required
        />
        <Textarea
          borderColor='#040D2F'
          focusBorderColor='#040D2F'
          placeholder='Message'
          resize='none'
          height='134px'
        />
        <Button
          backgroundColor='#06083E'
          _hover={{ backgroundColor: '#080b4f' }}
          color='white'
        >
          Send message
        </Button>
      </Stack>

      {/* Bottom rights message */}
      <Text
        color='white'
        position='absolute'
        bottom={0}
        zIndex={90}
        fontSize='.8rem'
        paddingBottom='1em'
      >
        © 2022 www.greenwaveiot.com All Rights Reserved.
      </Text>

      {/* Bottom curve */}
      <Image
        src={curve}
        alt='curve'
        position='absolute'
        top='auto '
        bottom='-100px'
        right='-100px'
        zIndex={10}
      />
    </Stack>
  )
}

const Partners = () => {
  return (
    <>
      <TopSection />
      <FormSection />
    </>
  )
}

export default Partners
