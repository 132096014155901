import { BrowserRouter } from 'react-router-dom';

import { TranslatorProvider } from 'utils/translator';

import Body from './Body';

const Layout = () => {
  return (
    <TranslatorProvider>
      <BrowserRouter>
        <Body />
      </BrowserRouter>
    </TranslatorProvider>
  );
};

export default Layout;
