import { Button, MenuItem, MenuList, useToast } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { FiRefreshCcw } from 'react-icons/fi';

import Section from 'components/Section';
import ComboTable from 'components/Table/ComboTable';

import { THING_BILLING } from 'constants/api';
import useFetchData from 'utils/hooks/useFetchData';
import { useTranslator } from 'utils/translator';

import { columns } from './configs';
import { RowType } from './types';
import { useConfirmationDialog, useFetchOnce } from 'utils/hooks';
import { DELETE } from 'constants/method';
import { ERROR, SUCCESS } from 'constants/toaster';

const TypesList = () => {
  const { t } = useTranslator();
  const toast = useToast();
  const { getConfirmation } = useConfirmationDialog();

  const { data, fetchData, isLoading } = useFetchData( [],`${ THING_BILLING }/list-all-billing-groups` );
  const { fetchData: fetchDeleteBilling } = useFetchOnce( [],DELETE );

  const handleOnDelete = async (name: string) => {
    const fetchDelete = async () => {
      const response = await fetchDeleteBilling( `${THING_BILLING}/${name}` );
      if( !response?.hasError ) {
        toast(SUCCESS);
        fetchData();
      } else {
        toast(ERROR);
      }
    };
    
    await getConfirmation(fetchDelete);
  };
  
  const getActions = (row: RowType) => {
    const { original: { thingBillingGroupName } } = row;
    return (
      <MenuList>
        <MenuItem icon={<DeleteIcon />} onClick={() => handleOnDelete(thingBillingGroupName)}>
          Delete
        </MenuItem>
      </MenuList>
    );
  };
  
  return (
      <Section
        title={t('_Billing_')}
        actionBar={(
          <>
            <Button
              size="sm"
              variant="secondary"
              onClick={fetchData}
            >
              <FiRefreshCcw />
            </Button>
          </>
        )}
      >
        <ComboTable isLoading={isLoading} columns={columns} data={data} getActions={getActions} />
      </Section>
  );
};

export default TypesList;
