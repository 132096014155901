import { useMemo } from 'react';
import { SubmitHandler } from 'react-hook-form';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';

import Form from 'components/Form';
import { FormElementType } from 'components/Form/type';

import { POST } from 'constants/method';
import { useTranslator } from 'utils/translator';
import { ERROR, SUCCESS } from 'constants/toaster';
import useFetchOnce from 'utils/hooks/useFetchOnce';
import { ADD_ESTIMATE_LINE_ITEM } from 'constants/api';

import { EditLineItemPropsType, AddLineItemType } from './types';

const EditItem = ({
  closeModal,
  qty,
  componentId,
  deviceEstimateId,
}: EditLineItemPropsType) => {
  const { t } = useTranslator();
  const toast = useToast();
  
  const { fetchData } = useFetchOnce({}, POST);

  const handleCloseModal = (update?: boolean) => {
    closeModal(update);
  };

  const addTier = async (newData: AddLineItemType) => {
    const data: any = { ...newData, deviceEstimateId, componentId };
    const params = new URLSearchParams(data);
    const response = await fetchData(`${ADD_ESTIMATE_LINE_ITEM}?${params.toString()}`, {});
    if (response?.ok) {
      handleCloseModal(true);
      toast(SUCCESS);
    } else {
      toast(ERROR);
    }
  };

  const handleAddTier: SubmitHandler<AddLineItemType> = async (
    data: AddLineItemType,
  ) => addTier(data);

  const formElements: FormElementType[] = useMemo(() => ([
    {
      placeholder: t('_Qty_'),
      label: t('_Qty_'),
      registerProp: ['qtyOnPcb', { required: t('_ErrorFill_') }],
      type: 'input',
      typeInput: 'number',
      defaultValue: qty,
    },
  ]), [qty, t]);

  return (
    <Modal isOpen onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('_EditItem_')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Form
            elements={formElements}
            onClose={handleCloseModal}
            submitHandler={handleAddTier}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditItem;
