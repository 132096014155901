import { useContext, useCallback } from 'react';

import { ConfirmDialogContext } from 'components/ConfirmDialog';

const useConfirmationDialog = () => {
  const { setDialog, dialog } = useContext(ConfirmDialogContext);

  const getConfirmation = useCallback((actionCallback, options = {}) => {
    setDialog({ ...dialog, ...options, isOpen: true, actionCallback })
  }, [dialog, setDialog]);

  return { getConfirmation };
};

export default useConfirmationDialog;
