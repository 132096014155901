import {
  Box,
  Link,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  SkeletonText,
  Text,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MinusIcon, AddIcon, ExternalLinkIcon } from '@chakra-ui/icons';

import { GET } from 'constants/method';
import { useFetchOnce } from 'utils/hooks';
import { useTranslator } from 'utils/translator';
import { setLocationsValues } from 'store/features/locationSearch';

const MapList = ({ api, options, id }: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslator();

  const { data, fetchData, isLoading } = useFetchOnce([], GET);

  useEffect(() => {
    fetchData(`${api}${id ? `/${id}?page=0&size=20` : ''}`);
  }, [api, fetchData, id]);

  useEffect(() => {
    if (!id) {
      dispatch(setLocationsValues(data));
    }
  }, [data, dispatch, id]);

  return (
    <SkeletonText isLoaded={!isLoading} w="100%" mt='1' noOfLines={2} spacing='2' >
      <Accordion allowMultiple>
        {(data && data.totalCount) ? (data.items || data)?.map((item: any) => (
          <AccordionItem key={item.id} w='100%'>
            {({ isExpanded }) => (
              <>
                <AccordionButton _focus={{ ringColor: 'none' }}>
                  <Box flex='1' textAlign='left'>
                    {`${item.name}${item.postalCode ? `(${item.postalCode})` : ''}`}
                  </Box>
                  {options && isExpanded ? <MinusIcon fontSize='12px' />
                    : (options ?
                      <AddIcon fontSize='12px' /> :
                      <Link href={`/cmd/device?deviceId=${item?.id}&deviceName=${item?.name}&roomName=${item?.room?.name}&roomId=${item?.room?.id}&locationName=${item?.room?.location?.name}&locationId=${item?.room?.location?.id}&unitType=DEVICE&historical=TODAY`} >
                        <ExternalLinkIcon mx='2px' />
                      </Link>)}
                </AccordionButton>
                {(options && isExpanded) && <AccordionPanel pb={4}>
                  <MapList api={options.api} id={item.id} options={options.options} />
                </AccordionPanel>}
              </>
            )}
          </AccordionItem>
        )) : (
          <AccordionItem w='100%'>
            <Text fontSize="13px" p='2'>{t('_NoData_')}</Text>
          </AccordionItem>
        )}
      </Accordion>
    </SkeletonText>
  );
};

export default MapList;