import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';
import { USER } from 'constants/api';
import { GET } from 'constants/method';
import { setUserInfo } from 'store/features/user';
import { clearToken } from 'store/features/token';
import useFetchOnce from 'utils/hooks/useFetchOnce';

import MainRoutes from './MainRoutes';

import { LayoutStyle } from './style';

export interface IRoleState {
  name: string;
  id: string
}


const Body = () => {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state: RootState) => ({
    user: state.user.value,
    token: state.token.value,
  }));

  const { fetchData } = useFetchOnce({}, GET);

  const getUserInfo = useCallback(async () => {
    const response = await fetchData(USER);
    if (response && !response.hasError) {
      dispatch(setUserInfo({...response.data, roles: response.data.roles.map((role:IRoleState) => role.name)}))
    } else {
      dispatch(clearToken());
      dispatch(setUserInfo({}))
    }
  }, [dispatch, fetchData]);

  useEffect(() => {
    if (token && !user) getUserInfo();
  }, [getUserInfo, token, user]);

  return (
    <LayoutStyle>
      <MainRoutes auth={token} />
    </LayoutStyle>
  );
};

export default Body;
