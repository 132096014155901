import { TableContainer, Table, Thead, Tr, Th, Tbody, Td } from "@chakra-ui/react"

const CalcResultTable = ({tier, hardChecked, bothChecked}:any) => {

    return (
      <TableContainer
     background='#FFFFFF 0% 0% no-repeat padding-box' 
     border='1px solid #CBCBCB' 
     borderRadius='10px'
     padding={2}>
      <Table size='sm'>
        <Thead>
          <Tr>
            <Th>Calculate Results</Th>
          </Tr>
        </Thead>
        <Tbody>
            {bothChecked ? 
            <>
            <Tr display='flex' flexDirection='row' justifyContent='space-between'>
              <Td>Device name</Td>
              <Td>{tier?.device_name}</Td>
            </Tr>
            <Tr display='flex' flexDirection='row' justifyContent='space-between'>
              <Td>Aggregiation cost</Td>
              <Td>{tier?.device_estimate_for_tier?.aggregated_cost}</Td>
            </Tr>
            <Tr display='flex' flexDirection='row' justifyContent='space-between'>
              <Td>Daily cost</Td>
              <Td>{tier?.service_estimate_for_tier?.daily_cost}</Td>
            </Tr>
            <Tr display='flex' flexDirection='row' justifyContent='space-between'>
              <Td>Total data foot print</Td>
              <Td>{tier?.service_estimate_for_tier?.total_data_foot_print}</Td>
            </Tr>
            <Tr display='flex' flexDirection='row' justifyContent='space-between'>
              <Td>Publishing frequency</Td>
              <Td>{tier?.service_estimate_for_tier?.publishing_frequency}</Td>
            </Tr></>
          : hardChecked ? 
          <>
          <Tr display='flex' flexDirection='row' justifyContent='space-between'>
            <Td>Device name</Td>
            <Td>{tier?.device_name}</Td>
          </Tr>
          <Tr display='flex' flexDirection='row' justifyContent='space-between'>
            <Td>Aggregiation cost</Td>
            <Td>{tier?.aggregated_cost}</Td>
          </Tr>
          </>
        :<>
        <Tr display='flex' flexDirection='row' justifyContent='space-between'>
          <Td>Device name</Td>
          <Td>{tier?.device_name}</Td>
        </Tr>
        <Tr display='flex' flexDirection='row' justifyContent='space-between'>
          <Td>Daily Cost</Td>
          <Td>{tier?.daily_cost}</Td>
        </Tr>
        <Tr display='flex' flexDirection='row' justifyContent='space-between'>
          <Td>Total data foot print</Td>
          <Td>{tier?.total_data_foot_print}</Td>
        </Tr>
        <Tr display='flex' flexDirection='row' justifyContent='space-between'>
          <Td>Publishing frequency</Td>
          <Td>{tier?.publishing_frequency}</Td>
        </Tr></>}
        </Tbody>
      </Table>
    </TableContainer>
    )
}

export default CalcResultTable;