import { UseToastOptions } from '@chakra-ui/react';

export const SUCCESS: UseToastOptions = {
	description: 'Successfully',
	status: 'success',
	duration: 2500,
	isClosable: true,
	position: 'top-right',
};

export const ERROR: UseToastOptions = {
	description: 'Something went wrong',
	status: 'error',
	duration: 2500,
	isClosable: true,
	position: 'top-right',
};

