import styled from '@emotion/styled';
import { adminBackgroundColor, borderColor } from 'styles/variables';

export default styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spiner-box{
    width: 100%;
    height:95vh;
    display:flex;
    justify-content: center;
    align-items: center;
  }
  .table-wrapper {
    width: 100%;
    padding: 0 10px;

    &.selectable-table {
      th:first-of-type  {
        min-width: auto;
      }
    }

    table {
      border-spacing: 0;
      background-color: white;
      position: sticky; 
      top: 0; 
      z-index: 1;
      th {
        min-width: 180px;
      }
      tbody {
        position: relative;
        tr {
          button {
            top: 10px;
            background: white;
            margin: 5px 0;
            div {
              z-index:10;
            }
          }
        }
      }
    
      tr {
        &:hover {
          background-color: ${adminBackgroundColor};
        }
      }
    }
  }
  

  th,
  td {
    position:relative;
    margin: 0;
    padding: 0.8rem 0rem;
    text-align: left;
    :last-child {
      border-right: 0;
    }
  }
  td  { 
    &:hover {
      .copy {
        display: inline-block;
      }
    }
  }

  .action-bar {
    display: contents;
  }

  .pagination {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${borderColor};

    .control {
      display: flex;

      .btn {
        min-width: 20px;
        margin-right: 10px;

        &:hover {
          background-color: ${adminBackgroundColor};
        }
      }
    }

    .options {
      display: flex;

      .info {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;

        p {
          font-size: 16px;
          margin-right: 5px;
        }
      }
    }
  }

  .table-checkbox {
    padding: 3px 10px 0 5px;
  }
`;
