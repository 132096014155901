export const costumerListColumns = [
  {
    Header: 'Type',
    accessor: 'customer_type',
  },
  {
    Header: 'Name',
    accessor: 'customer_name'
  },
  {
    Header: 'Email',
    accessor: 'customer_email',
  },
];
