import styled from '@emotion/styled';
import { borderColor, borderRadius, green } from 'styles/variables';

export const SectionStyle = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid ${borderColor};
  border-radius: ${borderRadius};
  background-color: white;
  box-shadow: 0 0.3rem 0.6rem 0 rgb(0 0 0 / 5%);
  display: flex;
  flex-direction: column;

  .section-header {
    padding: 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${borderColor};
    justify-content: space-between;
    .section-title {
      color: black;
      font-weight: bold;
    }
    .action-bar {
      display: flex;
    }

    p {
      color: ${green};
    }

    a:hover {
      color: ${green};
    }
  }
  
  .section-content {
    height: 100%;
    overflow-x: auto;
  }
`