import React, { ChangeEvent } from 'react';
import { Box } from '@chakra-ui/react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
} from 'use-places-autocomplete';
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';

import '@reach/combobox/styles.css';

import { SearchLocationType } from './types';

const SearchLocation = React.forwardRef(({ panTo }: SearchLocationType, ref: any) => {
  const {
    ready,
    value,
    setValue,
    clearSuggestions,
    suggestions: {
      status,
      data,
    },
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 36.778259, lng: () => -119.417931 } as google.maps.LatLng,
      radius: 100 * 1000,
    },
    cache: 24 * 60 * 60,
  } );

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address: string) => {
    setValue(address, false);
    clearSuggestions();
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      const zipCode = await getZipCode( results[ 0 ],false );

      panTo({ latitude: lat, longitude: lng, postalCode: zipCode, address } as google.maps.LatLng | any);
    } catch (error) {}
  };

  return (
        <Box position='relative' ref={ref}>
          <Combobox onSelect={handleSelect} >
            <Box borderWidth='1px' borderRadius='lg' p={3} width='100%' _hover={{bg: 'grey.50'}}>
              <ComboboxInput
                value={value}
                onChange={handleInput}
                disabled={!ready}
                placeholder='Enter an Address'
                width='100%'
                height='100%'
                />
            </Box>
            <Box
              borderWidth={value.length > 0 ? '1px' : '0px'}
              borderRadius={value.length > 0 ? 'lg' : ''}
              backgroundColor='white'
              position='absolute'
              width='100%'
              left='0'
              top='50px'
              zIndex={1}
            >
              <ComboboxList>
                {status === 'OK' &&
                  data?.map(({ place_id, description }) => (
                    <ComboboxOption key={place_id} value={description} />
                  ))}
              </ComboboxList>
            </Box>
        </Combobox>
      </Box>
  );
});

export default SearchLocation;
