import { SubmitHandler } from 'react-hook-form';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';

import Form from 'components/Form';

import { uuid } from 'utils';
import { PUT, POST } from 'constants/method';
import { DEVICE_ESTIMATE } from 'constants/api';
import { useTranslator } from 'utils/translator';
import { ERROR, SUCCESS } from 'constants/toaster';
import useFetchOnce from 'utils/hooks/useFetchOnce';

import { AddEstimatePropsType, AddEstimateType } from './types';

const AddEstimate = ({
  closeModal,
  estimateInfo,
}: AddEstimatePropsType) => {
  const { t } = useTranslator();
  const toast = useToast();

  const { fetchData, hasError } = useFetchOnce({}, estimateInfo ? PUT : POST);

  const handleCloseModal = (update?: boolean) => {
    closeModal(update);
  };

  const addEstimate = async (data: AddEstimateType) => {
    const customData = estimateInfo ? { ...data, id: estimateInfo.id } : { id: uuid(), ...data };
    await fetchData(DEVICE_ESTIMATE, customData);
    if (!hasError) {
      handleCloseModal(true);
      toast(SUCCESS);
    } else {
      toast(ERROR);
    }
  };

  const handleAddEstimate: SubmitHandler<AddEstimateType> = async (
    data: AddEstimateType
  ) => addEstimate(data);

  return (
    <Modal isOpen onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('_AddEstimate_')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Form submitHandler={handleAddEstimate} onClose={handleCloseModal}
            elements={[
              {
                placeholder: t('_DeviceName_'),
                label: t('_DeviceName_'),
                registerProp: ['device_name', { required: t('_ErrorFill_') }],
                type: 'input',
                typeInput: 'text',
                defaultValue: estimateInfo?.device_name
              },
              {
                placeholder: t('_DataFootPrint_'),
                label: t('_DataFootPrint_'),
                registerProp: ['data_foot_print', {
                  required: t('_ErrorFill_'),
                  pattern: {
                    value: /^[0-9]*$/,
                    message: t('_ErrorNumber_'),
                  }
                }],
                type: 'input',
                typeInput: 'text',
                defaultValue: estimateInfo?.data_foot_print
              },
              {
                placeholder: t('_BaseCost_'),
                label: t('_BaseCost_'),
                registerProp: ['base_cost', {
                  required: t('_ErrorFill_'),
                  pattern: {
                    value: /^[0-9]*$/,
                    message: t('_ErrorNumber_'),
                  }
                }],
                type: 'input',
                typeInput: 'text',
                defaultValue: estimateInfo?.base_cost || 0,
              },
            ]} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddEstimate;
