const descIcons = [
      {
        icon: '/temp.svg',
        key: 'temperature',
        label: 'Temperature',
        unit: 'C',
      },
      {
        icon: '/Humidity.svg',
        key: 'humidity',
        label: 'Humidity',
        unit: '%',
      },
      {
        icon: '/Carbon_dioxide.svg',
        key: 'coTwo',
        label: 'CO2',
        unit: '',
      },
      {
        icon: '/VOC.svg',
        key: 'tvoc',
        label: 'TVOC',
        unit: '',
      },
      {
        icon: '/suspended particles.svg',
        key: 'pmiOne',
        label: 'PMIOne',
        unit: '',
      },
];

export const descRisk = [
  {
    key: 'allergyRisk',
    label: 'Allergy',
    color: '',
  },
  {
    key: 'moldRisk',
    label: 'Mold',
    color: '',
  },
  {
    key: 'virusRisk',
    label: 'Virus',
    color: '',
  },
];

export const descAQI = [
  {
    key: 'totalAQIScore',
    label: 'Total AQI',
    color: '',
  },
  {
    key: 'totalEAQIScore',
    label: 'Total EAQI',
    color: '',
  },
];

export const risksDesc = [
  {
    key: 'risks',
    label: 'Risks',
  },
  {
    key: 'aqi',
    label: 'Air Quality Index',
  },];

export const periodDesc = ['Average', 'Last'];

export const dateDesc = [
  {
    key: 'TODAY',
    label: 'Today',
  },
  {
    key: 'YESTERDAY',
    label: 'Yesterday',
  },
  {
    key: 'LAST_WEEK',
    label: 'Last Week',
  },
  {
    key: 'LAST_MOUNT',
    label: 'Last Mount',
  },
  {
    key: 'LAST_YEAR',
    label: 'Last Year',
  }];

export default descIcons;