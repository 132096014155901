import {
  Input,
  Button,
  VStack,
  HStack,
  Container,
  FormLabel,
  InputGroup,
  FormControl,
  InputLeftElement,
  InputRightElement,
  Select,
  Stack,
  Textarea,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { uuid } from 'utils';
import { useTranslator } from 'utils/translator';

import { FormProps } from './type';

const Form = ({ elements, onClose, submitHandler }: FormProps) => {
  const { t } = useTranslator();

  const {
    reset,
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleCloseForm = () => {
    reset();
    clearErrors();
    onClose?.();
  };

  return (
    <Container
      p={1}
      maxW='full'
      maxH='full'
      centerContent
      bg='transparent'
      overflow='hidden'
      color={useColorModeValue('gray.700', 'whiteAlpha.900')}
    >
      <form onSubmit={handleSubmit(submitHandler)} style={{ width: '100%' }} >
        <VStack spacing={4} >
          <Stack spacing={2} width='100%'>
            {elements?.map(({
              type,
              defaultValue,
              placeholder,
              label,
              typeInput,
              icon,
              registerProp,
              isRequired,
              options,
              readOnly,
              disabled,
            }) => {
              const errorMessage = errors?.[registerProp[0]]?.message;
              const borderColor = errorMessage ? 'red' : '#CBCBCB';
              return (
                <FormControl isRequired={isRequired} key={uuid()}>
                  <FormLabel htmlFor={registerProp[0] || 'info'}>{label}</FormLabel>
                  {type === 'select' && (
                    <>
                      <Tooltip label={errorMessage}>
                        <div style={{ border: `1px solid ${borderColor}`, borderRadius: '8px' }}>
                          <Select
                            size='lg'
                            variant='primary'
                            placeholder={placeholder}
                            defaultValue={defaultValue}
                            disabled={disabled}
                            {...register(...registerProp)}
                          >
                            {options?.map(option => (
                              <option style={{ fontSize: '14px', color: 'green.800' }}
                                key={option?.value || uuid()}
                                value={option?.value || option}
                              >
                                {option?.label || option}
                              </option>
                            ))}
                          </Select>
                        </div>
                      </Tooltip>
                    </>
                  )}
                  {type === 'input' && (
                    <>
                      <Tooltip label={errorMessage}>
                        <InputGroup size='lg'>
                          {icon && (icon[0] === 'left'
                            ? <InputLeftElement children={icon[1]} />
                            : <InputRightElement children={icon[1]} />
                          )}
                          <Input
                            fontSize='14px'
                            readOnly={readOnly}
                            type={typeInput}
                            colorScheme='#959595'
                            borderColor={borderColor}
                            placeholder={placeholder}
                            defaultValue={defaultValue}
                            disabled={disabled}
                            {...register(...registerProp)}
                          />
                        </InputGroup>
                      </Tooltip>
                    </>
                  )}
                  {type === 'textarea' && (
                    <>
                      <Tooltip label={errorMessage}>
                        <Textarea
                          size='lg'
                          resize='none'
                          readOnly={readOnly}
                          borderColor={borderColor}
                          placeholder={placeholder}
                          defaultValue={defaultValue}
                          disabled={disabled}
                          {...register(...registerProp)}
                        />
                      </Tooltip>
                    </>
                  )}
                </FormControl>
              );
            })}
          </Stack>
          <HStack width='100%'>
            <FormControl display='flex' justifyContent='flex-end'>
              <Button variant='outline' mr={3} onClick={handleCloseForm}>
                {t('_Cancel_')}
              </Button>
              <Button variant='primary' type='submit'>
                {t('_Submit_')}
              </Button>
            </FormControl>
          </HStack>
        </VStack>
      </form>
    </Container>
  );
};

export default Form;