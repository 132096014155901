export const columns = [
  {
    Header: 'Name',
    accessor: 'thingTypeName',
  },
  {
    Header: 'Arn',
    accessor: 'thingTypeArn',
    type: 'COPYABLE_TEXT',
  },
];