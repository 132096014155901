import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { Button, Flex, Box, useDisclosure } from '@chakra-ui/react';

import Section from 'components/Section';
import GoogleMap from 'components/GoogleMap';

import { useTranslator } from 'utils/translator';

import TabsContent from './TabsContent';
import AddLocation from '../AddLocation';

import { tabsInfo } from './configs';

enum selectedTab {
  public,
}

const Locations = () => {
  const { t } = useTranslator();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tabIndex, setTabIndex] = useState(selectedTab.public);

  const { searchLocation } = useSelector((state: RootState) => ({
    searchLocation: state.locationSearch.locations?.items,
  }));

  return (
    <Section
      title={t('_Location_')}
      actionBar={(
        <Button size="md" variant="primary" onClick={onOpen}>
          {t('_AddLocation_')}
        </Button>
      )}
    >
      <Flex flexDirection="row" flexWrap="nowrap" width="100%" padding='2'>
        <Flex flexDirection="column" width='36vw' height='100%' mr={2}>
          <TabsContent tabsInfo={tabsInfo} tabIndex={tabIndex} setTabIndex={setTabIndex} />
        </Flex>
        <Box flex="1" height='100%' ml={3} borderRadius='10px'>
          <GoogleMap searchLocation={searchLocation} />
        </Box>
      </Flex>
      <AddLocation
        visible={isOpen}
        closeModal={onClose}
        setTabIndex={setTabIndex}
      />
    </Section>
  );
};

export default Locations;
