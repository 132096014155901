import { useMemo } from 'react';
import { Badge } from '@chakra-ui/react';
import moment from 'moment';

import CopyText from 'components/Table/CopyText';

import { ColumnType } from 'models';
import dataDuration from 'utils/dataDuration';

const getOriginalValue = (value: string, obj: any) => {
  const currentValue = value.split('.');
  return currentValue.reduce((acc, cur) => {
    if (acc) {
      return acc[cur];
    }
    return obj[cur];
  }, null);
};

const getDate = (value: string | null) => value ? moment(new Date(value)).format('MMMM Do YYYY, h:mm') : null;

const useColumns = (columns: ColumnType[]) => {
  return useMemo(
    () =>
      columns?.map((item: any) => {
        const { type, accessor, currencyKey, ...restItem } = item;
        const column = { accessor, ...restItem };
        switch (type) {
          case 'TEXT':
            column.Cell = (props: any) => {
              const {
                row: { original },
              } = props;
              return getOriginalValue(accessor, original);
            };
            break;
          case 'NUMBER_BY_CURRENCY':
            column.Cell = (props: any) => {
              const {
                row: { original },
              } = props;
              const value = getOriginalValue(accessor, original);
              const currency =
                currencyKey && getOriginalValue(currencyKey, original);
              return (value || 0) + (currency || '$');
            };
            break; 
          case 'DATEDURATION':
            column.Cell = (props: any) => {
              const {
                row: { original },
              } = props;
              const data = getOriginalValue(accessor, original);
              return dataDuration(data) >= 1 ? `${dataDuration(data)} days ago` : 'Today';
            };
            break;
          case 'DATE':
            column.Cell = (props: any) => {
              const {
                row: { original },
              } = props;
              const value = getOriginalValue(accessor, original);
              return getDate(value);
            };
            break;
          case 'STATUS_TEXT':
            column.Cell = (props: any) => {
              const {
                row: { original },
              } = props;
              const value = getOriginalValue(accessor, original);
              const isActive = value === 'ACTIVE';
              return (
                <Badge colorScheme={isActive ? 'green' : 'red'}>
                  {getOriginalValue(accessor, original)}
                </Badge>
              );
            };
            break;
          case 'SUSPEND_STATUS':
            column.Cell = (props: any) => {
              const {
                row: { original },
              } = props;
              return (
                <Badge colorScheme={original.suspended ? 'red' : 'green'}>
                  {getOriginalValue(accessor, original)
                    ? 'Suspended'
                    : 'Active'}
                </Badge>
              );
            };
            break;
          case 'COPYABLE_TEXT':
            column.Cell = (props: any) => {
              const {
                row: { original },
              } = props;
              return <CopyText text={getOriginalValue(accessor, original)} />;
            };
            break;
          default:
            break;
        }
        return column;
      }),
    [columns]
  );
};

export default useColumns;
