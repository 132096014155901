export const deviceListColumns = [
  {
    Header: 'Device Name',
    accessor: 'device_name',
  },
  {
    Header: 'Data foot print',
    accessor: 'data_foot_print',
  },
  {
    Header: 'Base Cost',
    accessor: 'base_cost',
  },
];

export const devicesListColumns = [
  {
    Header: 'QTY',
    accessor: 'line_item_qty'
  },
  {
    Header: 'Manufacturer',
    accessor: 'line_item_component.manufacturer'
  },
  {
    Header: 'Manufacturer part number',
    accessor: 'line_item_component.manufacturer_part_number'
  },
  {
    Header: 'Supplier part number',
    accessor: 'line_item_component.supplier_part_number'
  },
  {
    Header: 'Description',
    accessor: 'line_item_component.description'
  },
  {
    Header: 'Comment',
    accessor: 'line_item_component.comment'
  },
  {
    Header: 'Value',
    accessor: 'line_item_component.value'
  },
  {
    Header: 'Note',
    accessor: 'line_item_component.note'
  },
];
