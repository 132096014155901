import { Box } from '@chakra-ui/react';

import SearchInput from './SearchInput';

const Search = () => {
  return (
    <Box
      p='4'
      width='100%'
      background='#F7F9FA 0% 0% no-repeat padding-box'
      borderRadius='10px'
      marginBottom='10px'
    >
      <SearchInput />
    </Box>
  );
};

export default Search;
