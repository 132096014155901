import { useMemo, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';

import Form from 'components/Form';
import { FormElementType } from 'components/Form/type';

import { ADD_ESTIMATE_LINE_ITEM } from 'constants/api';
import { POST } from 'constants/method';
import { useTranslator } from 'utils/translator';
import { ERROR, SUCCESS } from 'constants/toaster';
import useFetchOnce from 'utils/hooks/useFetchOnce';

import { AddLineItemPropsType, AddLineItemType } from './types';

const AddItem = ({
  closeModal,
  lineItem,
  componentList,
  deviceEstimateId,
}: AddLineItemPropsType) => {
  const { t } = useTranslator();
  const toast = useToast();

  const [data] = useState(lineItem);
  
  const { fetchData } = useFetchOnce({}, POST);

  const handleCloseModal = (update?: boolean) => {
    closeModal(update);
  };

  const addTier = async (newData: AddLineItemType) => {
    const data: any = { ...newData, deviceEstimateId };
    const params = new URLSearchParams(data);
    const response = await fetchData(`${ADD_ESTIMATE_LINE_ITEM}?${params.toString()}`, {});
    if (response?.ok) {
      handleCloseModal(true);
      toast(SUCCESS);
    } else {
      toast(ERROR);
    }
  };

  const handleAddTier: SubmitHandler<AddLineItemType> = async (
    data: AddLineItemType,
  ) => addTier(data);

  const formElements: FormElementType[] = useMemo(() => ([
    {
      placeholder: t('_Component_'),
      registerProp: ['componentId'],
      options: componentList?.content
        ? componentList?.content.map((item: { id: string; value: string }) => ({ label: item.value, value: item.id }))
        : [],
      label: t('_Component_'),
      type: 'select',
    },
    {
      placeholder: t('_Qty_'),
      label: t('_Qty_'),
      registerProp: ['qtyOnPcb', { required: t('_ErrorFill_') }],
      type: 'input',
      typeInput: 'number',
      defaultValue: data?.qtyOnPcb,
    },
  ]), [data, componentList, t]);

  return (
    <Modal isOpen onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('_AddItem_')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Form
            elements={formElements}
            onClose={handleCloseModal}
            submitHandler={handleAddTier}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddItem;
