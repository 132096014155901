import { useCallback, useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const ACMD = () => {
  const { ref, inView } = useInView({ threshold: 0.2, triggerOnce: true })

  const road = useAnimation()
  const home = useAnimation()
  const chart = useAnimation()
  const droplet = useAnimation()
  const co2 = useAnimation()
  const temperature = useAnimation()
  const cloud = useAnimation()
  const app = useAnimation()

  const animateIn = useCallback(() => {
    //* Animation
    road.start({ opacity: 1, transition: { duration: 1, delay: 0 } })
    home.start({ opacity: 1, transition: { duration: 1, delay: 1 } })
    app.start({ opacity: 1, transition: { duration: 1, delay: 2 } })
    temperature.start({ opacity: 1, transition: { duration: 1, delay: 3 } })
    cloud.start({ opacity: 1, transition: { duration: 1, delay: 3 } })
    chart.start({ opacity: 1, transition: { duration: 1, delay: 4 } })
    co2.start({ opacity: 1, transition: { duration: 1, delay: 4 } })
    droplet.start({ opacity: 1, transition: { duration: 1, delay: 5 } })
  }, [chart, home, droplet, road, co2, temperature, cloud, app])

  const animateOut = useCallback(() => {
    //* Setting Initials
    road.set({ opacity: 0 })
    home.set({ opacity: 0 })
    app.set({ opacity: 0 })
    chart.set({ opacity: 0 })
    droplet.set({ opacity: 0 })
    co2.set({ opacity: 0 })
    temperature.set({ opacity: 0 })
    cloud.set({ opacity: 0 })
  }, [app, chart, cloud, co2, droplet, home, road, temperature])

  useEffect(() => {
    if (inView) animateIn()
    else animateOut()
  }, [inView, animateIn, animateOut])

  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='250'
      height='250'
      viewBox='0 0 550.5 510.244'
    >
      <defs>
        <filter
          id='Rectangle_3237'
          x='0'
          y='462.089'
          width='527'
          height='48.155'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' result='blur' />
          <feFlood flood-opacity='0.89' />
          <feComposite operator='in' in2='blur' />
          <feComposite in='SourceGraphic' />
        </filter>
        <clipPath id='clip-path'>
          <rect
            id='Rectangle_398'
            data-name='Rectangle 398'
            width='38.375'
            height='25.903'
            transform='translate(0 0)'
            fill='none'
          />
        </clipPath>
      </defs>
      <g
        id='Group_1770'
        data-name='Group 1770'
        transform='translate(-106.5 -2865)'
      >
        {/* Road */}
        <motion.g
          animate={road}
          id='Group_1590'
          data-name='Group 1590'
          transform='translate(-606.543 1188.284)'
        >
          <g
            transform='matrix(1, 0, 0, 1, 713.04, 1676.72)'
            filter='url(#Rectangle_3237)'
          >
            <path
              id='Rectangle_323-2'
              data-name='Rectangle 323'
              d='M22.04,36.155H504.96c1.8,0,3.359-.056,4.633-.158a17.119,17.119,0,0,0,2.924-.428c.662-.18,1-.394.979-.63s-.4-.493-1.174-.763L434.828,7.168a13,13,0,0,0-2.1-.458c-.9-.14-1.989-.266-3.185-.371s-2.5-.19-3.848-.248S422.964,6,421.6,6H55.832c-1.364,0-2.7.032-3.95.091s-2.418.143-3.439.248-1.9.231-2.576.371a3.667,3.667,0,0,0-1.343.458l-33.1,27.008c-.33.27-.286.527.08.763a6.257,6.257,0,0,0,2.015.63A32.752,32.752,0,0,0,17.148,36C18.589,36.1,20.24,36.155,22.04,36.155Z'
              transform='translate(0 462.09)'
              fill='#434343'
            />
          </g>
        </motion.g>
        {/* Cloud */}
        <motion.path
          animate={cloud}
          id='Union_13'
          data-name='Union 13'
          d='M127-6529v-248.662A37.011,37.011,0,0,1,97-6814a37,37,0,0,1,37-37,37,37,0,0,1,37,37,37.014,37.014,0,0,1-27,35.632V-6529Z'
          transform='translate(97.255 9867)'
          fill='#00af40'
        />
        {/* Temperature */}
        <motion.path
          animate={temperature}
          id='Union_14'
          data-name='Union 14'
          d='M127-6689v-80.1A37.016,37.016,0,0,1,99-6805a37,37,0,0,1,37-37,37,37,0,0,1,37,37,37.017,37.017,0,0,1-29,36.133V-6689Z'
          transform='translate(346.255 9859)'
          fill='#f6a400'
        />
        {/* CO2 */}
        <motion.path
          animate={co2}
          id='Union_15'
          data-name='Union 15'
          d='M127-6665v-112.661A37.011,37.011,0,0,1,97-6814a37,37,0,0,1,37-37,37,37,0,0,1,37,37,37.014,37.014,0,0,1-27,35.632V-6665Z'
          transform='translate(153.255 9773)'
          fill='#878787'
        />
        {/* Chart */}
        <motion.path
          animate={chart}
          id='Union_16'
          data-name='Union 16'
          d='M127-6651v-126.662A37.011,37.011,0,0,1,97-6814a37,37,0,0,1,37-37,37,37,0,0,1,37,37,37.014,37.014,0,0,1-27,35.632V-6651Z'
          transform='translate(296.255 9783)'
          fill='#8e02c8'
        />
        {/* Droplet */}
        <motion.path
          animate={droplet}
          id='Union_17'
          data-name='Union 17'
          d='M127-6656v-121.662A37.011,37.011,0,0,1,97-6814a37,37,0,0,1,37-37,37,37,0,0,1,37,37,37.014,37.014,0,0,1-27,35.632V-6656Z'
          transform='translate(219.255 9716)'
          fill='#0087ef'
        />
        {/* Home */}
        <motion.g
          animate={home}
          id='home-svgrepo-com_3_'
          data-name='home-svgrepo-com (3)'
          transform='translate(193.989 3032)'
        >
          <path
            id='Path_20478'
            data-name='Path 20478'
            d='M302.285,138.283l-72.344-69.65V13.2a13.028,13.028,0,1,0-26.056,0V43.578L162.17,3.429c-4.572-4.572-12.4-4.572-17.6,0L4.517,138.283c-5.2,5.2-5.888,13.028-.626,18.227s13.028,5.888,18.227.626l1.942-1.879V293.3a13.1,13.1,0,0,0,13.028,13.028H270.341c7.14,0,12.4-5.888,13.028-12.4V156.572l1.315,1.253c9.521,6.577,16.285,1.942,18.227-1.315C307.483,151.311,307.483,142.855,302.285,138.283ZM176.513,280.966h-45.6V211.253h45.6Zm80.8,0h-55.37V198.225A13.1,13.1,0,0,0,188.915,185.2H117.887a13.1,13.1,0,0,0-13.028,13.028v82.741H50.115V153.253h207.2V280.966ZM52.621,127.885,153.714,30.8l100.091,97.085Z'
            transform='translate(0)'
            fill='#434343'
          />
        </motion.g>
        {/*  */}
        {/* Cloud inner */}
        <motion.g
          animate={cloud}
          id='Group_1591'
          data-name='Group 1591'
          transform='translate(212.068 3039)'
        >
          <g id='Group_1397' data-name='Group 1397' clip-path='url(#clip-path)'>
            <path
              id='Path_20479'
              data-name='Path 20479'
              d='M30.94,9.779A11.936,11.936,0,0,0,8.554,6.541,9.717,9.717,0,0,0,9.594,25.9H30.38a8.075,8.075,0,0,0,.56-16.125'
              fill='#fff'
            />
          </g>
        </motion.g>
        {/* Chart inner */}
        <motion.path
          animate={chart}
          id='Path_20554'
          data-name='Path 20554'
          d='M257.484,61.483h5.1V32.13h-5.1Zm-7.657-1.276h5.105V46.169h-5.105Zm15.315,1.276h5.105V41.064h-5.105Z'
          transform='translate(170.218 2921.193)'
          fill='#fff'
        />
        {/* Temperature inner */}
        <motion.path
          animate={temperature}
          id='Path_20555'
          data-name='Path 20555'
          d='M321.166,132.717V121a5.474,5.474,0,1,0-10.922,0v11.713a10.176,10.176,0,0,0-1.82,13.607v.059h.037a8.673,8.673,0,0,0,14.488,0h.037v-.059a10.176,10.176,0,0,0-1.82-13.607m-10.922,7.809a6.031,6.031,0,0,1,2.185-4.685l1.456-1.171V121a1.824,1.824,0,1,1,3.64,0V134.67l1.456,1.171a6.064,6.064,0,0,1,2.185,4.685ZM335.036,117.1h-2.931l-6.152,17.571h2.839l1.475-4.491h6.607l1.456,4.491h2.858Zm-3.914,10.523,2.385-7.262h.145l2.385,7.262Z'
          transform='translate(159.067 2919.234)'
          fill='#fff'
        />
        {/* App */}
        <motion.g animate={app} id='页面-1' transform='translate(508 3184)'>
          <g id='导航图标' transform='translate(1 1)'>
            <g id='数据' transform='translate(0 0)'>
              <g id='编组'>
                <rect
                  id='矩形'
                  width='148'
                  height='148'
                  fill='rgba(255,255,255,0.01)'
                />
                <path
                  id='路径'
                  d='M125.167,2.292H1.833v37H125.167Z'
                  transform='translate(10.5 13.125)'
                  fill='none'
                  stroke='#434343'
                  stroke-linejoin='round'
                  stroke-width='9.5'
                  fill-rule='evenodd'
                />
                <path
                  id='路径-2'
                  data-name='路径'
                  d='M1.833,55.635,39.375,17.707,59.661,37.135l24.8-24.76L98.276,25.843'
                  transform='translate(10.5 70.875)'
                  fill='none'
                  stroke='#434343'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='9.5'
                  fill-rule='evenodd'
                />
                <line
                  id='路径-3'
                  data-name='路径'
                  y2='80.167'
                  transform='translate(135.667 49.863)'
                  fill='none'
                  stroke='#434343'
                  stroke-linecap='round'
                  stroke-width='9.5'
                />
                <line
                  id='路径-4'
                  data-name='路径'
                  y2='43.167'
                  transform='translate(12.333 49.863)'
                  fill='none'
                  stroke='#434343'
                  stroke-linecap='round'
                  stroke-width='9.5'
                />
                <line
                  id='路径-5'
                  data-name='路径'
                  x2='95.535'
                  transform='translate(40.131 132.583)'
                  fill='none'
                  stroke='#434343'
                  stroke-linecap='round'
                  stroke-width='9.5'
                />
                <line
                  id='路径-6'
                  data-name='路径'
                  x2='64.75'
                  transform='translate(52.417 33.917)'
                  fill='none'
                  stroke='#434343'
                  stroke-linecap='round'
                  stroke-width='9.5'
                />
                <line
                  id='路径-7'
                  data-name='路径'
                  x2='3.083'
                  transform='translate(30.833 33.906)'
                  fill='none'
                  stroke='#434343'
                  stroke-linecap='round'
                  stroke-width='9.5'
                />
              </g>
            </g>
          </g>
        </motion.g>
        {/* CO2 inner */}
        <motion.g animate={co2} id='Group_1599' data-name='Group 1599'>
          <g
            id='Group_1592'
            data-name='Group 1592'
            transform='translate(268.068 2944)'
          >
            <g
              id='Group_1397-2'
              data-name='Group 1397'
              clip-path='url(#clip-path)'
            >
              <path
                id='Path_20479-2'
                data-name='Path 20479'
                d='M30.94,9.779A11.936,11.936,0,0,0,8.554,6.541,9.717,9.717,0,0,0,9.594,25.9H30.38a8.075,8.075,0,0,0,.56-16.125'
                fill='#fff'
              />
            </g>
          </g>
          <text
            id='CO2'
            transform='translate(272 2963)'
            fill='#434343'
            font-size='11'
            font-family='Arial-BoldMT, Arial'
            font-weight='700'
            letter-spacing='0.22em'
          >
            <tspan x='0' y='0'>
              CO2
            </tspan>
          </text>
        </motion.g>
        {/* Droplet inner */}
        <motion.path
          animate={droplet}
          id='Path_20631'
          data-name='Path 20631'
          d='M185.528,111.322,177.12,103.1l-8.408,8.218a11.471,11.471,0,0,0,0,16.452,12.05,12.05,0,0,0,16.816,0,11.474,11.474,0,0,0,0-16.452m-17.321,8.727a8.2,8.2,0,0,1,2.614-6.4l6.3-6.3,6.3,6.371a8.017,8.017,0,0,1,2.615,6.327Z'
          transform='translate(176.136 2786.808)'
          fill='#fff'
        />
      </g>
    </svg>
  )
}

export default ACMD
