import { Box, Spinner } from '@chakra-ui/react';

const Loader = () => {
  return (
    <Box className="spiner-box">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="green.200"
        color="green.500"
        size="xl"
      />
    </Box>
  )
};

export default Loader;
