export const columns = [
  {
    Header: 'Name',
    accessor: 'thingGroupName',
  },
  {
    Header: 'Arn',
    accessor: 'thingGroupArn',
    type: 'COPYABLE_TEXT',
  },
];