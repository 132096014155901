import { Divider, Center, Button, useDisclosure } from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { FiRefreshCcw } from 'react-icons/fi';
import { BiDollar } from 'react-icons/bi';

import Section from 'components/Section';
import ActionBarWrap from 'components/ActionBar';
import ComboTable from 'components/Table/ComboTable';

import { COMPONENT, PRICE } from 'constants/api';
import { DELETE, GET, POST } from 'constants/method';
import { useTranslator } from 'utils/translator';
import useFetchData from 'utils/hooks/useFetchData';
import { useConfirmationDialog, useFetchOnce } from 'utils/hooks';

import AddCost from './AddCost';
import AddComponent from './AddComponent';

import { RowType } from './types';
import { componentListColumns } from './configs';

const ComponentList = () => {
  const { t } = useTranslator();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isCostOpen, onOpen: onCostOpen, onClose: onCostClose } = useDisclosure();
  const { getConfirmation } = useConfirmationDialog();

  const { fetchData: fetchOnce } = useFetchOnce({}, DELETE);
  const { data, fetchData, isLoading } = useFetchData({ content: [] }, `${COMPONENT}`);
  const { data: componentInfo, setData, fetchData: fetchComponentInfo } = useFetchOnce(null, GET);
  const { data: costInfo, fetchData: fetchCostInfo } = useFetchOnce(null, POST);

  const handleOnDelete = async (id: string) => {
    const onDelete = async () => {
      const response = await fetchOnce(`${COMPONENT}/${id}`);
      !response?.hasError && fetchData();
    };
    await getConfirmation(onDelete);
  };

  const handleOnEdit = async (id: string) => {
    const response = await fetchComponentInfo(`${COMPONENT}/${id}`);
    !response?.hasError && onOpen();
  };

  const handleOnCost = async (manufacturer?: string, id?: string) => {
    const response = await fetchCostInfo(`${PRICE}/list-component-dynamic_price-by-manufacturer?manufacturer=${manufacturer}`);
    await fetchComponentInfo(`${COMPONENT}/${id}`);
    response?.ok && onCostOpen();
  };

  const handleCloseModal = (isUpdate: boolean) => {
    if (isUpdate) fetchData();
    setData(null);
    isOpen && onClose();
    isCostOpen && onCostClose();
  };

  const getActions = (row: RowType) => {
    const { original: { id, manufacturer } } = row;
    return (
      <ActionBarWrap options={[
        {
          name: t('_Edit_'),
          icon: <EditIcon />,
          onClick: () => handleOnEdit(id),
        },
        {
          name: t('_Cost_'),
          icon: <BiDollar />,
          onClick: () => handleOnCost(manufacturer, id),
        },
        {
          name: t('_Delete_'),
          icon: <DeleteIcon />,
          onClick: () => handleOnDelete(id),
        },
      ]}
      />
    );
  };

  return (
    <Section
      title={t('_ComponentsList_')}
      actionBar={(
        <>
          <Button size="md" variant="primary" onClick={onOpen}>
            {t('_AddComponent_')}
          </Button>
          <Center height="40px" marginLeft="5px">
            <Divider orientation="vertical" />
          </Center>
          <Button
            size="sm"
            variant="secondary"
            onClick={fetchData}
          >
            <FiRefreshCcw />
          </Button>
        </>
      )}
    >
      <ComboTable
        columns={componentListColumns}
        data={data?.content}
        isLoading={isLoading}
        getActions={getActions}
      />
      <AddComponent
        visible={isOpen}
        closeModal={handleCloseModal}
        componentInfo={componentInfo}
      />
      <AddCost
        visible={isCostOpen}
        closeModal={handleCloseModal}
        componentInfo={costInfo?.length ? costInfo[0] : componentInfo}
      />
    </Section>
  );
};

export default ComponentList;
