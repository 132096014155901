import { useForm, SubmitHandler } from 'react-hook-form';
import {
  Modal,
  Input,
  Button,
  Tooltip,
  ModalBody,
  FormLabel,
  ModalFooter,
  ModalHeader,
  FormControl,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';

import { useTranslator } from 'utils/translator';
import useFetchOnce from 'utils/hooks/useFetchOnce';
import { ORGANIZATIONS_INVITE } from 'constants/api';
import { ERROR, SUCCESS } from 'constants/toaster';
import { toastError } from 'utils/notifications';

import { AddOrganizationsPropsType, AddOrganizationsType } from './types';

const AddOrganizations = ({
  visible = false,
  closeModal,
}: AddOrganizationsPropsType) => {
  const toast = useToast();
  const { t } = useTranslator();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddOrganizationsType>();

  const { fetchData } = useFetchOnce();

  const handleCloseModal = () => {
    reset();
    closeModal();
  };

  const addOrganization = async (data: AddOrganizationsType) => {
    if (errors && !Object.values(errors).length) {
      const response = await fetchData(
        `${ORGANIZATIONS_INVITE}?email=${data.email}`,
        null
      );
      if (response?.hasError) {
        toast(ERROR);
      } else {
        toast(SUCCESS);
        handleCloseModal();
      }
    } else {
      toastError(errors?.email?.message);
    }
  };

  const handleSingIn: SubmitHandler<AddOrganizationsType> = async (
    data: AddOrganizationsType
  ) => addOrganization(data);

  return (
    <Modal isOpen={visible} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('_AddOrganizations_')}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handleSingIn)}>
          <ModalBody pb={6}>
            <FormControl isInvalid={!!errors.email}>
              <FormLabel>{t('_Email_')}</FormLabel>
              <Tooltip label={errors?.email?.message}>
                <Input
                  placeholder={t('_Email_')}
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t('_InvalidEmailAddress_'),
                    },
                  })}
                />
              </Tooltip>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant='primary' mr={3} type='submit'>
              {t('_Submit_')}
            </Button>
            <Button onClick={handleCloseModal}>{t('_Cancel_')}</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default AddOrganizations;
