import { useMemo, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';

import Form from 'components/Form';
import { FormElementType } from 'components/Form/type';

import { SERVICE_TIER } from 'constants/api';
import { PUT, POST } from 'constants/method';
import { useTranslator } from 'utils/translator';
import { ERROR, SUCCESS } from 'constants/toaster';
import useFetchOnce from 'utils/hooks/useFetchOnce';

import { AddTierPropsType, AddTierType } from './types';

const AddTier = ({
  closeModal,
  tierInfo,
  isDuplicate,
}: AddTierPropsType) => {
  const { t } = useTranslator();
  const toast = useToast();

  const [data] = useState(isDuplicate ? { ...tierInfo, tier_name: tierInfo?.tier_name + ' Copy' } : tierInfo);

  const { fetchData } = useFetchOnce({}, data && !isDuplicate ? PUT : POST);

  const handleCloseModal = (update?: boolean) => {
    closeModal(update);
  };

  const addTier = async (newData: AddTierType) => {
    const customData = data && !isDuplicate ? { ...newData, id: data.id } : { ...newData };
    const response = await fetchData(SERVICE_TIER, customData);
    if (response?.ok) {
      handleCloseModal(true);
      toast(SUCCESS);
    } else {
      toast(ERROR);
    }
  };

  const handleAddTier: SubmitHandler<AddTierType> = async (
    data: AddTierType,
  ) => addTier(data);

  const formElements: FormElementType[] = useMemo(() => ([
    {
      placeholder: t('_TierName_'),
      registerProp: ['tier_name', { required: t('_ErrorFill_') }],
      type: 'input',
      typeInput: 'text',
      defaultValue: data?.tier_name,
    },
    {
      placeholder: t('_BaseRatePerPublish_'),
      registerProp: ['base_rate_per_publish', { required: t('_ErrorFill_') }],
      type: 'input',
      typeInput: 'number',
      defaultValue: data?.base_rate_per_publish,
    },
    {
      placeholder: t('_Margin_'),
      registerProp: ['margine_p', { required: t('_ErrorFill_') }],
      type: 'input',
      typeInput: 'number',
      defaultValue: data?.margine_p,
    },
    {
      placeholder: t('_Overhead_'),
      registerProp: ['overhead_p', { required: t('_ErrorFill_') }],
      type: 'input',
      typeInput: 'number',
      defaultValue: data?.overhead_p,
    },
    {
      placeholder: t('_R&D_'),
      registerProp: ['rnd_p', { required: t('_ErrorFill_') }],
      type: 'input',
      typeInput: 'number',
      defaultValue: data?.rnd_p,
    },
  ]), [data, t]);

  const modalTitle = isDuplicate ? '_DuplicateTier_' : (data ? '_EditTier_' : '_AddTier_');

  return (
    <Modal isOpen onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t(modalTitle)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Form
            elements={formElements}
            onClose={handleCloseModal}
            submitHandler={handleAddTier}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddTier;
