import {
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  Image,
  Box,
  Input,
  Textarea,
} from '@chakra-ui/react'
//* Images
import facebook from 'assets/redesignImages/home/facebook.png'
import linkedin from 'assets/redesignImages/home/linkedin.png'
import twitter from 'assets/redesignImages/home/twitter.png'
import background from 'assets/redesignImages/careers/background.jpeg'
import { Controller, useForm } from 'react-hook-form'
import { ReactElement } from 'react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FormSection = () => {

  const formCurrentDefaultValues = {
    firstName: '',
    lastName: '',
    jobTitle: '',
    message: '',
    email: '',
    file: ''
  }


  const { handleSubmit, reset, control } = useForm({
    defaultValues: {
      ...formCurrentDefaultValues
    }
  });

  const handleSubmitRef = () => {
    toast("Application received!🚀 Stay tuned for lift-off! 🌟");
    reset()
  }

  return (
    <Stack
      alignItems='center'
      padding="20px"
      // paddingY='3em'
      // backgroundColor='white'
      position='relative'
    // minHeight='100vh'
    // maxHeight='100vh'
    // overflow='hidden'
    >
              <ToastContainer />

      <Heading color='#fff' fontSize='2.4rem'>
        JOIN OUR TEAM
      </Heading>
      <Text color='#FFF' paddingBottom='1.5em' fontSize='1.4rem'>
        Make a Difference. Work with one of the most trusted and dedicated teams
      </Text>
      {/* Form card */}
      <form onSubmit={handleSubmit(handleSubmitRef)}>

        <Stack
          padding='2em'
          boxShadow='5px 5px 42px #0101015E'
          maxWidth='575px'
          width='100%'
          borderRadius='10px'
          gap='1.5em'
          zIndex={100}
          backgroundColor='white'
        >


          <Controller
            name='firstName'
            key='firstName'
            control={control}
            render={({ field: { onChange, value } }): ReactElement => (
              <Input
                borderColor='#040D2F'
                focusBorderColor='#040D2F'
                placeholder='First name *'
                required
                value={value}
                onChange={onChange}
              />
            )}
          />

          <Controller
            name='lastName'
            key='lastName'
            control={control}
            render={({ field: { onChange, value } }): ReactElement => (
              <Input
                borderColor='#040D2F'
                focusBorderColor='#040D2F'
                placeholder='Last name *'
                required
                value={value}
                onChange={onChange}
              />
            )}
          />
            <Controller
            name='jobTitle'
            key='jobTitle'
            control={control}
            render={({ field: { onChange, value } }): ReactElement => (
              <Input
                borderColor='#040D2F'
                focusBorderColor='#040D2F'
                placeholder='Job Title *'
                required
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name='email'
            key='email'
            control={control}
            render={({ field: { onChange, value } }): ReactElement => (
              <Input
                borderColor='#040D2F'
                focusBorderColor='#040D2F'
                placeholder='Email *'
                required
                value={value}
                onChange={onChange}
              />
            )}
          />

          <Controller
            name='message'
            key='message'
            control={control}
            render={({ field: { onChange, value } }): ReactElement => (
              <Textarea
                borderColor='#040D2F'
                focusBorderColor='#040D2F'
                placeholder='Message'
                resize='none'
                height='134px'
                value={value}
                onChange={onChange}
              />
            )}
          />

          <Controller
            name='file'
            key='file'
            control={control}
            render={({ field: { onChange, value } }): ReactElement => (
              <Input
              accept=".pdf, .doc, .docx"
                type='file'
                borderColor='#040D2F'
                focusBorderColor='#040D2F'
                placeholder='File *'
                required
                padding="5px"
                value={value}
                onChange={onChange}
              />
            )}
          />


          <Button
            type='submit'
            backgroundColor='#06083E'
            _hover={{ backgroundColor: '#080b4f' }}
            color='white'
          >
            Send message
          </Button>

        </Stack>
      </form>
    </Stack>
  )
}

const JobSection = () => {
  const makeCard = (title: string, role: string, description: string) => (
    <Flex gap='1em'>
      {/* Tile and role on the left */}
      <Stack minWidth='220px'>
        <Heading fontSize='20px'>{title}</Heading>
        <Text fontSize='12px'>{role}</Text>
      </Stack>
      {/* Description on the left */}
      <Text fontSize='15px'>{description}</Text>
    </Flex>
  )

  return (
    <Stack padding='2em 5em' id='jobs'>
      <Stack
        padding='2em 5em'
        color='white'
        boxShadow='0px 3px 19px #0032FF'
        borderRadius='10px'
        gap='2em'
      >
        {makeCard(
          'Frontend Engineer',
          'SENIOR FRONTEND ENGINEER',
          'We are looking for an experienced JavaScript developer who is proficient with React.js. The primary focus of the selected candidate would be on developing user interface components implementing and executing them following well-known React.js workflows (such as Flux or Redux). Also ensuring that these components and the overall application are robust and easy to manage. A commitment to collaborative problem solving, sophisticated design, and quality products are important.'
        )}
        {makeCard(
          'Frontend Engineer',
          'SENIOR FRONTEND ENGINEER',
          'We are looking for an experienced JavaScript developer who is proficient with React.js. The primary focus of the selected candidate would be on developing user interface components implementing and executing them following well-known React.js workflows (such as Flux or Redux). Also ensuring that these components and the overall application are robust and easy to manage. A commitment to collaborative problem solving, sophisticated design, and quality products are important.'
        )}
        {makeCard(
          'Frontend Engineer',
          'SENIOR FRONTEND ENGINEER',
          'We are looking for an experienced JavaScript developer who is proficient with React.js. The primary focus of the selected candidate would be on developing user interface components implementing and executing them following well-known React.js workflows (such as Flux or Redux). Also ensuring that these components and the overall application are robust and easy to manage. A commitment to collaborative problem solving, sophisticated design, and quality products are important.'
        )}
      </Stack>

      <FormSection />
    </Stack>
  )
}


const Careers = () => {
  return (
    <>
      <Stack
        minHeight='calc(100vh - 100px)'
        justifyContent='center'
        alignItems='center'
        position='relative'
      >
        <a href='#jobs' style={{ zIndex: 100 }}>
          <Button
            backgroundColor='#1E2050'
            boxShadow='0 0 15px #004CFF'
            color='white'
            w='525px'
            h='80px'
            fontSize='30px'
            _hover={{
              backgroundColor: 'transparent',
              border: '3px solid white',
              boxShadow: 'none',
            }}
          >
            Join our team
          </Button>
        </a>
        {/* Social icons */}
        <Stack
          position='absolute'
          top='40%'
          left='auto'
          right='2em'
          gap='1em'
          zIndex={100}
        >
          {/* TODO: Add links here for socials */}
          <a href='#'>
            <img
              src={facebook}
              alt='facebook'
              loading='lazy'
              width='45px'
              height='45px'
            />
          </a>
          <a href='#'>
            <img
              src={twitter}
              alt='twitter'
              loading='lazy'
              width='45px'
              height='45px'
            />
          </a>
          <a href='#'>
            <img
              src={linkedin}
              alt='linkedIn'
              loading='lazy'
              width='45px'
              height='45px'
            />
          </a>
        </Stack>
        {/* Background image */}
        <Image
          src={background}
          alt='background'
          position='absolute'
          top={0}
          left={0}
          zIndex={10}
          maxHeight='calc(100vh - 100px)'
          width='100%'
          objectFit='cover'
        />
        <Box
          width='100%'
          height='calc(100vh - 100px)'
          position='absolute'
          top={0}
          left={0}
          zIndex={20}
          backgroundColor='rgba(0, 0, 0, .4)'
        />
      </Stack>
      <JobSection />
    </>
  )
}

export default Careers
