import React from 'react';
import { Link } from 'react-router-dom';
import{Flex, Box, Text} from '@chakra-ui/react'

import backgroundImage from 'assets/images/brockenBackground.png';

const NotFound = () => (
    <Box 
    position='relative'
    marginTop='10vh'
    marginRight='20vw'
    marginLeft='20vw'
    width='60vw'
    maxH='600px'
    backgroundImage={backgroundImage}     
    backgroundPosition='center'
    backgroundRepeat="no-repeat"
    backgroundSize='contain'
    display='flex'
    > 
    <Flex 
    position='absolute'
    top='30%'
    width='100%'
    flexDirection='column'
    alignItems='start'>
    <Text maxWidth='50%' fontSize='2vw'>Oops! Something went wrong. Please revisit the page shortly.</Text>
    <Link to="/home"><Text _hover={{textDecoration:'underline'}} marginTop='10px' color='green' fontSize='1.5vw'>Go to homepage</Text></Link>
    </Flex>
    </Box>
);

export default NotFound;