export const locationsListColumns = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Postal code',
    accessor: 'postalCode',
  },
  {
    Header: 'Category',
    accessor: 'category',
  },
  {
    Header: 'Accessibility',
    accessor: 'accessibility',
  },
];

export const roomsListColumns = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Id',
    accessor: 'id',
  },
  {
    Header: 'Geometry Id',
    accessor: 'geometryId',
  },
];

export const devicesListColumns = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Id',
    accessor: 'id',
  },
  {
    Header: 'Thing Arn',
    accessor: 'thingArn',
    type: 'COPYABLE_TEXT',
  },
];

