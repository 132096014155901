import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  SkeletonText,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom';

const BreadcrumbWrapper = ({ items }: any) => {
  return (
    <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
      {items?.map((item: any, index: number) => (
        <BreadcrumbItem key={index}>
          {item.name
            ? (item.path
                ? <BreadcrumbLink as={Link} to={item.path}>{item.name}</BreadcrumbLink>
                : <p>{item.name}</p>
              )
            : <SkeletonText w="5rem" mt='1' noOfLines={1} spacing='1' />
          }
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default BreadcrumbWrapper;
