import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useToast,
  Button,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Tooltip,
  Select,
  Divider,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';

import { uuid } from 'utils';
import { AnyObject } from 'utils/types';
import { POST } from 'constants/method';
import { useTranslator } from 'utils/translator';
import { ERROR, SUCCESS } from 'constants/toaster';
import useFetchOnce from 'utils/hooks/useFetchOnce';
import { SERVICE_TIER_CALCULATION } from 'constants/api';
import { EstimateType } from 'pages/Dynamo/DeviceEstimate/types';

type ServiceCostCalculatorPropsType = {
  deviceList?: Array<any>,
  tierList?: Array<any>,
  closeModal: Function;
  estimateInfo?: AnyObject;
};

const ServiceCostCalculator = ({
  deviceList,
  tierList,
  closeModal,
}: ServiceCostCalculatorPropsType) => {
  const { t } = useTranslator();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const [baseCost, setBaseCost] = useState(0);

  const { fetchData, isLoading } = useFetchOnce({}, POST);

  const handleCloseModal = (update?: boolean) => {
    closeModal(update);
  };

  const calculate = async (data: EstimateType) => {
    const params = new URLSearchParams(data);
    const response = await fetchData(`${SERVICE_TIER_CALCULATION}?${params.toString()}`);
    if (response?.ok) {
      toast(SUCCESS);
      setBaseCost(response.data.daily_cost);
    } else {
      toast(ERROR);
    }
  };

  const handlePriceCalculate: SubmitHandler<EstimateType> = async (
    data: EstimateType
  ) => calculate(data);

  const tierOptions = tierList ? tierList.map(({ tier_name }) => ({ label: tier_name, value: tier_name })) : [];
  const deviceOptions = deviceList ? deviceList.map(({ device_name }) => ({ label: device_name, value: device_name })) : [];

  return (
    <Modal isOpen onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('_DeviceQuantityCalculate_')}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handlePriceCalculate)}>
          <ModalBody pb={6}>
            <FormControl isInvalid={!!errors.deviceEstimateName}>
              <FormLabel>{t('_Device_') + ' *'}</FormLabel>
              <Tooltip label={errors?.deviceEstimateName?.message}>
                <div style={{
                  border: `${errors?.deviceEstimateName?.message ? 2 : 1}px solid`,
                  borderColor: errors?.deviceEstimateName?.message ? 'red' : 'inherit',
                  borderRadius: '8px',
                  marginBottom: '10px',
                }}>
                  <Select
                    size='lg'
                    variant='primary'
                    placeholder={t('_DeviceName_')}
                    {...register('deviceEstimateName', {
                      required: t('_ErrorFill_'),
                    })}
                  >
                    {deviceOptions?.map(option => (
                      <option style={{ fontSize: '14px', color: 'green.800' }}
                        key={option?.value || uuid()}
                        value={option?.value || option}
                      >
                        {option?.label || option}
                      </option>
                    ))}
                  </Select>
                </div>
              </Tooltip>
            </FormControl>
            <FormControl>
              <FormLabel>{t('_Tier_')}</FormLabel>
              <div style={{
                border: '1px solid',
                borderColor: 'inherit',
                borderRadius: '8px',
                marginBottom: '10px',
              }}>
                <Select
                  size='lg'
                  variant='primary'
                  placeholder={t('_TierName_')}
                  {...register('serviceTierName')}
                >
                  {tierOptions?.map(option => (
                    <option style={{ fontSize: '14px', color: 'green.800' }}
                      key={option?.value || uuid()}
                      value={option?.value || option}
                    >
                      {option?.label || option}
                    </option>
                  ))}
                </Select>
              </div>
            </FormControl>
            <FormControl isInvalid={!!errors.numberOfPublishesPerDay} marginBottom={2}>
              <FormLabel>{t('_FrequencyPerDay_') + ' *'}</FormLabel>
              <Tooltip label={errors?.numberOfPublishesPerDay?.message}>
                <Input
                  type="number"
                  placeholder={t('_FrequencyPerDay_')}
                  {...register('numberOfPublishesPerDay', {
                    required: t('_ErrorFill_'),
                    pattern: {
                      value: /^[0-9]*$/,
                      message: t('_ErrorNumber_'),
                    }
                  })}
                />
              </Tooltip>
            </FormControl>
            <Divider orientation='horizontal' margin="20px 0" />
            <FormControl isInvalid={!!errors.daily_cost}>
              <Tooltip label={errors?.daily_cost?.message}>
                <InputGroup>
                  <InputLeftAddon children={t('_DailyCost_')} />
                  <Input
                    readOnly
                    value={baseCost + '$'}
                    {...register('daily_cost')}
                  />
                </InputGroup>
              </Tooltip>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={() => handleCloseModal()}>{t('_Cancel_')}</Button>
            <Button variant='primary' ml={3} type='submit' isLoading={isLoading} loadingText="Calculate">
              {t('_Submit_')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ServiceCostCalculator;
