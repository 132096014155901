import { Button, Box, DrawerFooter, Select, FormLabel, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, Stack, Input } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { uuid } from 'utils';
import { useTranslator } from 'utils/translator';

import { FilterProps } from './type';

function Filter({ elements, isOpen, onClose, submitHandler }: FilterProps) {
  const { t } = useTranslator();

  const {
    reset,
    register,
    handleSubmit,
  } = useForm();

  const handleCloseFilter = () => {
    reset();
    onClose();
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <form onSubmit={handleSubmit(submitHandler)}>
          <DrawerHeader borderBottomWidth='1px'>
            {t('_FilterBy_')}
          </DrawerHeader>
          <DrawerBody h='77vh'>
            <Stack spacing='14px'>
              <Box>
                {elements?.map(({ type, placeholder, label, registerProp, options }) => (
                  <Box mb={6} key={uuid()}>
                    <FormLabel htmlFor={registerProp[0] || 'select'}>{label}</FormLabel>
                    {type === 'select' && (
                      <Select variant='primary' placeholder={placeholder} size='lg'
                        {...register(...registerProp)}>
                        {options?.map(option => (
                          <option style={{ fontSize: '14px', color: 'green.800' }}
                            key={option?.key || uuid()}
                            value={option?.key || option}>
                            {option?.label || option}
                          </option>
                        ))}
                      </Select>
                    )}
                    {type === 'input' && (
                      <Input variant='primary' placeholder={placeholder} size='lg' {...register(...registerProp)} />
                    )}
                  </Box>))}
              </Box>
            </Stack>
          </DrawerBody>
          <DrawerFooter borderTopWidth='1px'>
            <Button variant='outline' mr={3} onClick={handleCloseFilter}>
              {t('_Cancel_')}
            </Button>
            <Button variant='primary' type='submit'>{t('_Submit_')}</Button>
          </DrawerFooter>
        </form>
      </DrawerContent>
    </Drawer>
  );
};

export default Filter;