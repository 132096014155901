import { useNavigate } from 'react-router-dom';

import { DeleteIcon } from '@chakra-ui/icons';

import { MdOutlineCalculate } from 'react-icons/md';
import { CgDetailsMore } from 'react-icons/cg'

import Section from 'components/Section';
import ActionBarWrap from 'components/ActionBar';
import ComboTable from 'components/Table/ComboTable';

import { CUSTOMER_TIER_ASSOCIATION } from 'constants/api';
import { DELETE } from 'constants/method';
import { useTranslator } from 'utils/translator';
import useFetchData from 'utils/hooks/useFetchData';
import { useConfirmationDialog, useFetchOnce } from 'utils/hooks';

import { costumerListColumns } from './configs';
import { RowType } from './types';

const CustomerList = () => {
  const { t } = useTranslator();
  const { getConfirmation } = useConfirmationDialog();
  const navigate = useNavigate()

  const { fetchData: fetchOnce } = useFetchOnce({}, DELETE);
  const { data, fetchData, isLoading } = useFetchData({ content: [] }, `${CUSTOMER_TIER_ASSOCIATION}`);

  const handleOnDelete = async (id: string) => {
    const onDelete = async () => {
      const response = await fetchOnce(`${CUSTOMER_TIER_ASSOCIATION}/${id}`);
      !response?.hasError && fetchData();
    };
    await getConfirmation(onDelete);
  };

  const getActions = (row: RowType) => {
    const { original: { id, customer_name } } = row;
    return (
      <ActionBarWrap options={[
        {
          name: t('_Delete_'),
          icon: <DeleteIcon />,
          onClick: () => handleOnDelete(id),
        },
        {
          name: t('_Calculate_'),
          icon: <MdOutlineCalculate />,
          onClick: () => navigate(`/dynamo/customer-list/calculate?orgName=${customer_name}`),
        },
        {
          name: t('_Details_'),
          icon: <CgDetailsMore />,
          onClick: () => navigate(`/dynamo/customer-list/details?orgName=${customer_name}`),
        },
      ]}
      />
    );
  };

  return (
    <Section title={t('_CustomerList_')}>
      <ComboTable
        columns={costumerListColumns}
        data={data?.content}
        isLoading={isLoading}
        getActions={getActions}
      />
    </Section>
  );
};

export default CustomerList;
