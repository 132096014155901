import { useMemo } from 'react';
import { SubmitHandler } from 'react-hook-form';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';

import Form from 'components/Form';
import { FormElementType } from 'components/Form/type';

import { COMPONENT } from 'constants/api';
import { PUT, POST } from 'constants/method';
import { useTranslator } from 'utils/translator';
import { ERROR, SUCCESS } from 'constants/toaster';
import useFetchOnce from 'utils/hooks/useFetchOnce';

import { AddComponentPropsType, AddComponentType } from './types';

const AddComponent = ({
  visible = false,
  closeModal,
  componentInfo,
}: AddComponentPropsType) => {
  const { t } = useTranslator();
  const toast = useToast();

  const { fetchData } = useFetchOnce({}, componentInfo ? PUT : POST);

  const handleCloseModal = (update?: boolean) => {
    closeModal(update);
  };

  const addComponent = async (data: AddComponentType) => {
    const customData = componentInfo ? { ...data, id: componentInfo.id } : { ...data };
    const response = await fetchData(COMPONENT, customData);
    if (response?.ok) {
      handleCloseModal(true);
      toast(SUCCESS);
    } else {
      toast(ERROR);
    }
  };

  const handleAddComponent: SubmitHandler<AddComponentType> = async (
    data: AddComponentType,
  ) => addComponent(data);

  const formElements: FormElementType[] = useMemo(() => ([
    {
      placeholder: t('_Manufacturer_'),
      label: t('_Manufacturer_'),
      registerProp: ['manufacturer', { required: t('_ErrorFill_') }],
      type: 'input',
      typeInput: 'text',
      disabled: !!componentInfo,
      defaultValue: componentInfo?.manufacturer,
    },
    {
      placeholder: t('_ManufacturerPartNumber_'),
      label: t('_ManufacturerPartNumber_'),
      registerProp: ['manufacturer_part_number', { required: t('_ErrorFill_') }],
      type: 'input',
      typeInput: 'text',
      disabled: !!componentInfo,
      defaultValue: componentInfo?.manufacturer_part_number,
    },
    {
      placeholder: t('_SupplierPartNumber_'),
      label: t('_SupplierPartNumber_'),
      registerProp: ['supplier_part_number', { required: t('_ErrorFill_') }],
      type: 'input',
      typeInput: 'text',
      defaultValue: componentInfo?.supplier_part_number,
    },
    {
      placeholder: t('_Value_'),
      label: t('_Value_'),
      registerProp: ['value', { required: t('_ErrorFill_') }],
      type: 'input',
      typeInput: 'text',
      defaultValue: componentInfo?.value,
    },
    {
      placeholder: t('_Description_'),
      label: t('_Description_'),
      registerProp: ['description', { required: t('_ErrorFill_') }],
      type: 'input',
      typeInput: 'text',
      defaultValue: componentInfo?.description,
    },
    {
      placeholder: t('_Comment_'),
      label: t('_Comment_'),
      registerProp: ['comment', { required: t('_ErrorFill_') }],
      type: 'input',
      typeInput: 'text',
      defaultValue: componentInfo?.comment,
    },
    {
      placeholder: t('_Note_'),
      label: t('_Note_'),
      registerProp: ['note', { required: t('_ErrorFill_') }],
      type: 'input',
      typeInput: 'text',
      defaultValue: componentInfo?.note,
    },
  ]), [componentInfo, t]);

  return (
    <Modal isOpen={visible} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('_AddComponent_')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Form
            elements={formElements}
            onClose={handleCloseModal}
            submitHandler={handleAddComponent}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddComponent;
