import { usePagination, useSortBy, useTable } from 'react-table';

import { useColumns } from 'utils/hooks';

import CoreTable from './CoreTable';
import Pagination from './Pagination';

import { initialState } from './utils';

import Styles from './style';

function ComboTable({ columns, data, isSortable, isLoading, ...restProps }: any) {
  const tableColumns = useColumns(columns);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    ...restTableProps
  }: any = useTable<any>(
    {
      data: data || [],
      initialState,
      columns: tableColumns,
    },
    useSortBy,
    usePagination,
  );

  return (
    <Styles>
      <CoreTable
        h="full"
        page={page}
        isLoading={isLoading}
        prepareRow={prepareRow}
        headerGroups={headerGroups}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        {...restProps}
      />
      {(!!page?.length && !isLoading) && <Pagination {...restTableProps} />}
    </Styles>
  );
}

export default ComboTable;
