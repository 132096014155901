import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Center, Button, useDisclosure, Text } from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';

import { FiRefreshCcw } from 'react-icons/fi';
import { HiOutlineDuplicate } from 'react-icons/hi';
import { RiMoneyDollarBoxLine } from 'react-icons/ri';
import {CgUserList} from 'react-icons/cg';

import Section from 'components/Section';
import ActionBarWrap from 'components/ActionBar';
import SelectableTable from 'components/Table/SelectableTable';

import { RootState } from 'store';
import { DELETE, GET } from 'constants/method';
import { useTranslator } from 'utils/translator';
import useFetchData from 'utils/hooks/useFetchData';
import { DEVICE_ESTIMATE, SERVICE_TIER } from 'constants/api';
import { useConfirmationDialog, useFetchOnce } from 'utils/hooks';
import { setSelectedTiersValue } from 'store/features/selectedTiers';

import AddTier from './AddTier';
import ServiceCostCalculator from './ServiceCostCalculator';

import { RowType } from './types';
import { tierListColumns } from './configs';

const TierList = () => {
  const { t } = useTranslator();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getConfirmation } = useConfirmationDialog();

  const { selectedTiers } = useSelector((state: RootState) => ({
    selectedTiers: state.selectedTiers.value,
  }));

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isPriceOpen, onOpen: onPriceOpen, onClose: onPriceClose } = useDisclosure();

  const [isDuplicate, setDuplicate] = useState(false);

  const { fetchData: fetchOnce } = useFetchOnce({}, DELETE);
  const { data, fetchData, isLoading } = useFetchData({ content: [] }, `${SERVICE_TIER}`);
  const { data: deviceList } = useFetchData({ content: [] }, `${DEVICE_ESTIMATE}`);
  const { data: tierInfo, setData, fetchData: fetchTierInfo } = useFetchOnce(null, GET);

  const handleOnDelete = async (id: string) => {
    const onDelete = async () => {
      const response = await fetchOnce(`${SERVICE_TIER}/${id}`);
      !response?.hasError && fetchData();
    };
    await getConfirmation(onDelete);
  };

  const handleOnEdit = async (id: string, duplicate?: boolean) => {
    duplicate && setDuplicate(true);
    const response = await fetchTierInfo(`${SERVICE_TIER}/${id}`);
    if (!response?.hasError) {
      onOpen();
    }
  };

  const handleCloseModal = (isUpdate: boolean) => {
    if (isUpdate) fetchData();
    setData(null);
    isDuplicate && setDuplicate(false);
    onClose();
  };

  const getActions = (row: RowType) => {
    const { original: { id, tier_name } } = row;
    const navigateUrl = `/dynamo/customer-tier-association/list-associations-for-service-tier?deviceServiceTierId=${id}&tiersForService=${tier_name}`;
    return (
      <ActionBarWrap options={[
        {
          name: t('_Edit_'),
          icon: <EditIcon />,
          onClick: () => handleOnEdit(id),
        },
        {
          name: t('_DuplicateTier_'),
          icon: <HiOutlineDuplicate />,
          onClick: () => handleOnEdit(id, true),
        },
        {
          name: t('_Delete_'),
          icon: <DeleteIcon />,
          onClick: () => handleOnDelete(id),
        },
        {
          name: t('_CustomerList_'),
          icon: <CgUserList />,
          onClick: () => navigate(navigateUrl),
        },
      ]}
      />
    );
  };

  const getSelectableRow = useCallback((data: any) => {
    dispatch(setSelectedTiersValue(data?.map((item: { original: any; }) => item.original)));
  }, [dispatch]);

  return (
    <Section
      title={t('_TiersForService_')}
      actionBar={(
        <>
          <Button
            marginRight="10px"
            onClick={onPriceOpen}
            leftIcon={<RiMoneyDollarBoxLine />}
          >
            {t('_PriceGenerate_')}
          </Button>
          <Button
            marginRight="10px"
            disabled={!(selectedTiers?.length && selectedTiers?.length > 1)}
            onClick={() => navigate('/dynamo/comparison-list')}
            rightIcon={<Text>{selectedTiers?.length || ''}</Text>}
          >
            {t('_Compare_')}
          </Button>
          <Button size="md" variant="primary" onClick={onOpen}>
            {t('_AddTier_')}
          </Button>
          <Center height="40px" marginLeft="5px">
            <Divider orientation="vertical" />
          </Center>
          <Button
            size="sm"
            variant="secondary"
            onClick={fetchData}
          >
            <FiRefreshCcw />
          </Button>
        </>
      )}
    >
      <SelectableTable
        columns={tierListColumns}
        data={data?.content}
        isLoading={isLoading}
        getActions={getActions}
        getSelectableRow={getSelectableRow}
      />
      {isOpen && <AddTier
        isDuplicate={isDuplicate}
        tierInfo={tierInfo}
        closeModal={handleCloseModal}
      />}
      {isPriceOpen && <ServiceCostCalculator
        tierList={data?.content}
        deviceList={deviceList?.content}
        closeModal={onPriceClose}
      />}
    </Section>
  );
};

export default TierList;
