import { useForm, SubmitHandler } from 'react-hook-form';
import {
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';

import { useTranslator } from 'utils/translator';
import { ERROR, SUCCESS } from 'constants/toaster';
import useFetchOnce from 'utils/hooks/useFetchOnce';
import { THING_CERTIFICATE } from 'constants/api';

import { AddCertificatePropsType, AddCertificateType } from './types';

const AddCertificate = ({ visible = false, closeModal, updateData }: AddCertificatePropsType) => {
  const { t } = useTranslator();
  const toast = useToast();

  const {
    reset,
    handleSubmit,
  } = useForm<AddCertificateType>();

  const { fetchData: fetchCreateThing } = useFetchOnce([]);

  const handleCloseModal = () => {
    reset();
    closeModal();
  };

  const handleAddThing: SubmitHandler<AddCertificateType> = async ({ email }: AddCertificateType) => {
    const data = await fetchCreateThing(THING_CERTIFICATE, { email });

    if (!data?.hasError) {
      updateData();
      handleCloseModal();
      toast(SUCCESS);
    } else {
      toast(ERROR);
    }
  };

  return (
    <Modal
      isOpen={visible}
      onClose={handleCloseModal}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('_AddCertificate_')}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handleAddThing)}>
          <ModalBody pb={6} m={1}>
            {t('_AddCertificateIf_')}
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleCloseModal} mr={3}>{t('_Cancel_')}</Button>
            <Button variant="primary" type='submit'>
              {t('_Add_')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default AddCertificate;
