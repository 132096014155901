import { LinkIcon, DownloadIcon } from '@chakra-ui/icons';
import { saveAs } from 'file-saver';

import Section from 'components/Section';
import ActionBarWrap from 'components/ActionBar';
import ComboTable from 'components/Table/ComboTable';

import { WAVE_APP } from 'constants/api';
import { useTranslator } from 'utils/translator';
import useFetchData from 'utils/hooks/useFetchData';

import { columns } from './configs';
import { RowType } from './types';

const WaveApp = () => {
  const { t } = useTranslator();

  const { data, isLoading } = useFetchData(
    null,
    `${WAVE_APP}/admin/billing/invoices?page=1&pageSize=10`,
  );

  const getActions = (row: RowType) => {
    const {
      original: {
        node: { pdfUrl, viewUrl },
      },
    } = row;

    return (
      <ActionBarWrap
        options={[
          {
            name: t('_Pdf_'),
            icon: <DownloadIcon />,
            onClick: () => saveAs(pdfUrl),
          },
          {
            name: t('_Pay_'),
            icon: <LinkIcon />,
            onClick: () => window.open(viewUrl),
          },
        ]}
      />
    );
  };

  return (
    <Section title={t('Waveapp')}>
      <ComboTable
        columns={columns}
        isLoading={isLoading}
        getActions={getActions}
        data={data ? data?.data?.business?.invoices?.edges : []}
      />
    </Section>
  );
};

export default WaveApp;
