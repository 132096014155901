import { Box, Flex, Heading, Image, Stack, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'

//? Images
//* Landing Images
import facebook from 'assets/redesignImages/home/facebook.png'
import linkedin from 'assets/redesignImages/home/linkedin.png'
import twitter from 'assets/redesignImages/home/twitter.png'
//* What we provide images
import macBookPro from 'assets/redesignImages/whatWeProvide/MacBook-Pro-mockup.png'
//* Solutions images
import background from 'assets/redesignImages/solutions/Group 1613.png'
//* How it works images
import curve from 'assets/redesignImages/howItWorks/curve.svg'
import iphone from 'assets/redesignImages/howItWorks/iphone.png'
import affiliate from 'assets/redesignImages/howItWorks/affiliate.svg'
import buy from 'assets/redesignImages/howItWorks/buy.svg'
import land from 'assets/redesignImages/howItWorks/land.svg'
import lease from 'assets/redesignImages/howItWorks/lease.svg'
import manage from 'assets/redesignImages/howItWorks/manage.svg'
import own from 'assets/redesignImages/howItWorks/own.svg'
import use from 'assets/redesignImages/howItWorks/use.svg'
//* Key points images
import first from 'assets/redesignImages/keypoints/first.png'
import second from 'assets/redesignImages/keypoints/second.png'
import third from 'assets/redesignImages/keypoints/third.png'
import fourth from 'assets/redesignImages/keypoints/fourth.png'
import fifth from 'assets/redesignImages/keypoints/fifth.png'
import sixth from 'assets/redesignImages/keypoints/sixth.png'
//* Why us section images
import one from 'assets/redesignImages/whyUs/1.svg'
import two from 'assets/redesignImages/whyUs/2.svg'
import three from 'assets/redesignImages/whyUs/3.svg'
import four from 'assets/redesignImages/whyUs/4.svg'
import five from 'assets/redesignImages/whyUs/5.svg'
import letter from 'assets/redesignImages/whyUs/letter.png'
//* Contact us images
import globe from 'assets/redesignImages/contactUs/globe.png'
import marker from 'assets/redesignImages/contactUs/marker.svg'

import { HomePageStyle } from './style'
import { green } from 'styles/variables'
import AGPSTracker from 'components/Home/A_GPSTracker'
import ACMD from 'components/Home/A_CMD'
import ALed from 'components/Home/A_Led'
import AAgroD from 'components/Home/A_AgroD'
import AGlobe from 'components/Home/A_Globe'

const Hero = () => (
  <HomePageStyle>
    <Stack height='100%' justifyContent='flex-start'>
      <AGlobe />
      <Heading
        as={motion.h2}
        initial={{ x: '-100vw' }}
        animate={{ x: 0, transition: { duration: 1 } }}
        color='white'
        letterSpacing='9.22px'
        fontSize='96px'
        fontWeight='normal'
      >
        TURN
        <br />
        TECHNOLOGY
      </Heading>
      <Heading
        as={motion.h2}
        initial={{ x: '-100vw' }}
        animate={{ x: 0, transition: { duration: 1, delay: 0.5 } }}
        color={green}
        letterSpacing='9.22px'
        fontSize='96px'
        fontWeight='normal'
      >
        GREEN
      </Heading>
    </Stack>
    <Stack
      as={motion.div}
      initial={{ x: '100vw' }}
      animate={{ x: 0, transition: { duration: 1 } }}
      position='absolute'
      top='40%'
      left='auto'
      right='2em'
      gap='1em'
    >
      {/* TODO: Add links here for socials */}
      <a href='#'>
        <img
          src={facebook}
          alt='facebook'
          loading='lazy'
          width='45px'
          height='45px'
        />
      </a>
      <a href='#'>
        <img
          src={twitter}
          alt='twitter'
          loading='lazy'
          width='45px'
          height='45px'
        />
      </a>
      <a href='#'>
        <img
          src={linkedin}
          alt='linkedIn'
          loading='lazy'
          width='45px'
          height='45px'
        />
      </a>
    </Stack>
  </HomePageStyle>
)

const WhatWeProvide = () => {
  return (
    <Stack
      as='section'
      justifyContent='flex-start'
      alignItems='center'
      backgroundColor='#06083e'
      color='white'
      paddingTop='3em'
      maxHeight='86vh'
      position='relative'
    >
      <Heading textAlign='center' fontSize='35px' zIndex={100}>
        WE OFFER SOLUTIONS <br />
        TO EVERYDAY CHALLENGES
      </Heading>
      <Flex
        paddingTop='2em'
        alignItems='center'
        justifyContent='center'
        gap='20em'
        zIndex={100}
      >
        <Stack>
          <Heading fontSize='30px' letterSpacing='4px'>
            Hardware
          </Heading>
          <Text fontSize='30px'>as a service</Text>
        </Stack>
        <Stack>
          <Heading fontSize='30px' letterSpacing='5px'>
            Software
          </Heading>
          <Text fontSize='30px'>as a service</Text>
        </Stack>
      </Flex>
      <Image
        marginTop={0}
        src={macBookPro}
        alt='mac book pro'
        loading='lazy'
        transform='translateY(-3em)'
      />
      <Box
        position='absolute'
        width='324px'
        height='201px'
        zIndex={10}
        top={0}
        backgroundColor='#0011FF'
        borderRadius={100}
        filter='blur(20px)'
        boxShadow='0px 3px 99px #0011FF'
        opacity={0.24}
      />
    </Stack>
  )
}

const Solutions = () => {
  return (
    <Stack paddingY='3em' zIndex={-10} backgroundColor='#fcfff2'>
      <Heading textAlign='center'>SOLUTIONS</Heading>
      <Stack
        textAlign='center'
        position='relative'
        alignItems='center'
        paddingTop='20em'
      >
        <Flex gap='4em' zIndex={50}>
          <Stack>
            <Text>CMD</Text>
            <ACMD />
          </Stack>
          <Stack>
            <Text>GPS TRACKER</Text>
            <AGPSTracker />
          </Stack>
        </Flex>
        <Flex gap='4em' zIndex={50}>
          <Stack>
            <Text>AGRO D</Text>
            <AAgroD />
          </Stack>
          <Stack>
            <Text>LED</Text>
            <ALed />
          </Stack>
        </Flex>
        <Image src={background} position='absolute' top={0} zIndex={5} />
        <Box
          borderRadius='100%'
          width='800px'
          height='800px'
          position='absolute'
          top={40}
          zIndex={10}
          backgroundColor='rgba(255, 255, 255, 0.5)'
        />
        <Box
          borderRadius='100%'
          width='850px'
          height='850px'
          position='absolute'
          top='135px'
          zIndex={20}
          backgroundColor='transparent'
          border='2px dashed white'
        />
      </Stack>
    </Stack>
  )
}

const HowItWorks = () => {
  const makeCard = (src: string, shadowColor: string, title: string) => (
    <Stack maxWidth='133px'>
      <Flex
        margin='25em 0 1.5em 0'
        padding='1em'
        boxShadow={`0px 3px 15px ${shadowColor}`}
        width='133px'
        height='138px'
        justifyContent='center'
        alignItems='center'
      >
        <Image src={src} alt='card' />
      </Flex>
      <Text color='white' textAlign='center'>
        {title}
      </Text>
    </Stack>
  )

  return (
    <Stack position='relative' paddingTop='15em' backgroundColor='#fcfff2'>
      <Heading textAlign='center' zIndex={20}>
        HOW IT WORKS
      </Heading>
      <Image
        src={curve}
        alt='background curve'
        zIndex={10}
        position='absolute'
        transform='scale(1.1)'
      />
      <Image
        src={buy}
        alt='buy'
        position='absolute'
        top='25em'
        left='auto'
        right='35em'
        zIndex={20}
      />
      <Image
        src={lease}
        alt='lease'
        position='absolute'
        top='20em'
        left='auto'
        right='27em'
        zIndex={20}
      />
      <Image
        src={land}
        alt='land'
        position='absolute'
        top='17em'
        left='auto'
        right='15em'
        zIndex={20}
      />
      <Flex
        paddingX='5em'
        alignItems='center'
        width='100%'
        gap='3em'
        zIndex={10}
      >
        <Image src={iphone} maxHeight='75rem' />
        <Flex
          alignItems='flex-start'
          justifyContent='center'
          flex={1}
          gap='3em'
        >
          {makeCard(own, '#00FFF7', 'OWN')}
          {makeCard(use, '#B4F300EB', 'USE')}
          {makeCard(manage, '#DD00FFF0', 'MANAGE')}
          {makeCard(affiliate, '#7A7A7A', 'AFFILIATE PROGRAM')}
        </Flex>
      </Flex>
    </Stack>
  )
}

const KeyPoints = () => {
  const makeCard = (src: string, text: string) => (
    <Flex gap='1em' flex={1} maxHeight='15rem'>
      <Image src={src} alt='card' />
      <Text padding='1em 3em 1em 1em'>{text}</Text>
    </Flex>
  )

  return (
    <Stack paddingTop='2em' paddingBottom='4em' backgroundColor='white'>
      <Heading textAlign='center' fontSize='2.25rem'>
        USE YOUR{' '}
        <span style={{ fontSize: '2.25rem', color: '#00a7e6' }}>
          MONITORING
        </span>{' '}
        DATA TO MAKE
        <br />
        INFORMED DECISIONS FOR YOUR BUSINESS
      </Heading>
      <Flex paddingTop='5em' paddingX='7em'>
        {makeCard(
          first,
          'Data driven decision making is the key to your business prosperity'
        )}
        {makeCard(
          second,
          'Rigorous and focused data will inform your most efficient business decisions to make necessary adjustments to your business model...'
        )}
        {makeCard(
          third,
          'Build your customized monitoring data flow to enhance the exponential growth potential for your business'
        )}
      </Flex>
      <Flex paddingTop='2em' paddingX='7em'>
        {makeCard(
          fourth,
          'Let the smart technology handle your business growth with accurate data monitoring'
        )}
        {makeCard(
          fifth,
          'Become a Leader of Customer Market and capitalize on consumer market!'
        )}
        {makeCard(
          sixth,
          'Let’s analyze data to shape bright future for your business'
        )}
      </Flex>
    </Stack>
  )
}

const WhyUs = () => {
  const makeListItem = (src: string, text: string) => (
    <Flex alignItems='center'>
      <Image src={src} alt='list item' />
      <Text>{text}</Text>
    </Flex>
  )

  return (
    <Stack
      position='relative'
      paddingTop='2em'
      paddingBottom='10em'
      backgroundColor='white'
    >
      <Heading textAlign='center'>WHY GREENWAVEIOT</Heading>
      <Flex
        justifyContent='flex-end'
        paddingY='5em'
        zIndex={10}
        paddingRight='20em'
      >
        <Stack
          padding='2em'
          boxShadow='0 0 10px rgba(0,0,0,0.5)'
          borderRadius={15}
          maxWidth='500px'
          backgroundColor='white'
          gap='1em'
        >
          {makeListItem(one, 'MAXIMUM FLEXIBILITY AND NON-BINDING MECHANISMS')}
          {makeListItem(
            two,
            'GENERATE REVENUE FROM OUR INTELLECTUAL INVESTMENT!'
          )}
          {makeListItem(
            three,
            'JUST BUY A SOFTWARE / HARDWARE AS A SERVICE TO TAKE YOUR BUSINESS TO THE NEXT LEVEL'
          )}
          {makeListItem(
            four,
            'SELECT COMMITMENT TO THE SOFTWARE PURELY FOCUSED ON YOUR SPECIFIC BUSINESS NEEDS'
          )}
          {makeListItem(
            five,
            'PAY ONLY FOR THE SERVICE YOUR BUSINESS NEEDS, SPEND YOUR FUNDS SMART!'
          )}
        </Stack>
      </Flex>
      <Image
        src={letter}
        alt='letter'
        position='absolute'
        zIndex={0}
        top={-250}
        transform='scale(0.6)'
      />
    </Stack>
  )
}

const ContactUs = () => {
  return (
    <Stack
      backgroundColor='#06083E'
      alignItems='center'
      paddingTop='3em'
      paddingBottom='1em'
      paddingX='3em'
      color='white'
      width='100vw'
      boxShadow='0 -5px 6px #0011FF'
      position='relative'
    >
      <Heading textAlign='center'>CONTACT US</Heading>
      <Image
        src={globe}
        alt='globe'
        width='100%'
        paddingTop='3em'
        maxWidth='75%'
      />
      <Image
        src={marker}
        alt='marker'
        position='absolute'
        top='44%'
        left='24%'
      />
      <Flex justifyContent='space-between' width='100%' paddingY='1em'>
        <Text fontSize='1.0rem'>
          address: 500 N. Brand Blvd Suite 2000 Glendale CA 91203
        </Text>
        <Text fontSize='1.0rem'>phone number: 818 934 0010</Text>
        <Text fontSize='1.0rem'>email: info@greenwaveiot.com</Text>
      </Flex>
      <Box
        width='100vw'
        height='1px'
        backgroundColor='#0011FF'
        boxShadow='0 -5px 6px #0011FF'
      />
      <Text fontSize='1.0rem' paddingTop='1em'>
        © 2022 www.greenwaveiot.com All Rights Reserved.
      </Text>
    </Stack>
  )
}

const Home = () => {
  return (
    <>
      <Hero />
      <WhatWeProvide />
      <Solutions />
      <HowItWorks />
      <KeyPoints />
      <WhyUs />
      <ContactUs />
    </>
  )
}

export default Home
