import * as yup from 'yup';

  export const SignUpSchema = yup.object().shape({
    firstName: yup.string()
      .required('Field is required')
      .min(3, "Firstname length should be at least 3 characters")
      .max(30, "Firstname length should be less than 30 characters")
      .matches(/^[a-zA-Z ]{2,30}$/, 'Only letters allowed'),
    lastName: yup.string()
      .required('Field is required')
      .min(2, "Lastname length should be at least 2 characters")
      .max(30, "Lastname length should be less than 30 characters")
      .matches(/^[a-zA-Z ]{2,30}$/, 'Only letters allowed'),
    phone: yup.string()
      .required('Field is required')
      .matches(/^[0-9]*$/, 'Please provide a valid phone number'),
    userName: yup.string()
      .required('Field is required')
      .min(3, "Username length should be at least 3 characters"),
    password: yup.string()
      .required("Password is required")
      .min(8, "Password length should be at least 8 characters")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&,^*?])[A-Za-z\d#$@!%&,^*?]{8,30}$/, 'at least one Uppercase, lowercase, number and special character'),
    matchingPassword: yup.string()
      .required("Confirm Password is required")
      .min(8, "Password length should be at least 8 characters")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&,^*?])[A-Za-z\d#$@!%&,^*?]{8,30}$/, 'at least one Uppercase, lowercase, number and special character')
      .oneOf([yup.ref("password")], "Passwords do not match")
  });

  export const SignUpExistingUserSchema = yup.object().shape({
    userName: yup.string()
      .required('Field is required')
      .min(3, "Username length should be at least 3 characters"),
    password: yup.string()
      .required("Password is required")
      .min(8, "Password length should be at least 8 characters")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&,^*?])[A-Za-z\d#$@!%&,^*?]{8,30}$/, 'at least one Uppercase, lowercase, number and special character'),
    matchingPassword: yup.string()
      .required("Confirm Password is required")
      .min(8, "Password length should be at least 8 characters")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&,^*?])[A-Za-z\d#$@!%&,^*?]{8,30}$/, 'at least one Uppercase, lowercase, number and special character')
      .oneOf([yup.ref("password")], "Passwords do not match")
  });

  export const SignUpOrgSchema = yup.object().shape({
    firstName: yup.string()
    .required('Field is required')
    .min(3, "Firstname length should be at least 3 characters")
    .max(30, "Firstname length should be less than 30 characters")
    .matches(/^[a-zA-Z ]{2,30}$/, 'Only letters allowed'),
    lastName: yup.string()
      .required('Field is required')
      .min(2, "Lastname length should be at least 2 characters")
      .max(30, "Lastname length should be less than 30 characters")
      .matches(/^[a-zA-Z ]{2,30}$/, 'Only letters allowed'),
    phone: yup.string()
      .required('Field is required')
      .matches(/^[0-9]*$/, 'Please provide a valid phone number'),
    organizationName: yup.string()
      .required('Field is required')
      .min(2, "Organization length should be at least 2 characters")
      .max(30, "Organization length should be less than 30 characters"),
    userName: yup.string()
      .required('Field is required')
      .min(3, "Username length should be at least 3 characters"),
    password: yup.string()
      .required("Password is required")
      .min(8, "Password length should be at least 8 characters")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&,^*?])[A-Za-z\d#$@!%&,^*?]{8,30}$/, 'at least one Uppercase, lowercase, number and special character'),
    matchingPassword: yup.string()
      .required("Confirm Password is required")
      .min(8, "Password length should be at least 8 characters")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&,^*?])[A-Za-z\d#$@!%&,^*?]{8,30}$/, 'at least one Uppercase, lowercase, number and special character')
      .oneOf([yup.ref("password")], "Passwords do not match")
  });

  export const SignUpOrgExistingUserSchema = yup.object().shape({
    organizationName: yup.string()
      .required('Field is required')
      .min(2, "Organization length should be at least 2 characters")
      .max(30, "Organization length should be less than 30 characters"),
    userName: yup.string()
      .required('Field is required')
      .min(3, "Username length should be at least 3 characters"),
    password: yup.string()
      .required("Password is required")
      .min(8, "Password length should be at least 8 characters")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&,^*?])[A-Za-z\d#$@!%&,^*?]{8,30}$/, 'at least one Uppercase, lowercase, number and special character'),
    matchingPassword: yup.string()
      .required("Confirm Password is required")
      .min(8, "Password length should be at least 8 characters")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&,^*?])[A-Za-z\d#$@!%&,^*?]{8,30}$/, 'at least one Uppercase, lowercase, number and special character')
      .oneOf([yup.ref("password")], "Passwords do not match")
  });

  export const SignInSchema = yup.object().shape({
    username: yup.string()
      .required('Field is required')
      .min(3, "Username length should be at least 3 characters"),
    password: yup.string()
      .required("Password is required")
      .min(8, "Password length should be at least 8 characters")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&,^*?])[A-Za-z\d#$@!%&,^*?]{8,30}$/, 'at least one Uppercase, lowercase, number and special character'),
  });