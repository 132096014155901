import { Button, MenuItem, MenuList, useToast } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { FiRefreshCcw } from 'react-icons/fi';

import Section from 'components/Section';
import ComboTable from 'components/Table/ComboTable';

import { DELETE } from 'constants/method';
import { THING_POLICY } from 'constants/api';
import { useTranslator } from 'utils/translator';
import { ERROR, SUCCESS } from 'constants/toaster';
import useFetchData from 'utils/hooks/useFetchData';
import { useConfirmationDialog, useFetchOnce } from 'utils/hooks';

import { columns } from './configs';
import { RowType } from './types';

const PolicyList = () => {
  const { t } = useTranslator();
  const toast = useToast();
  const { getConfirmation } = useConfirmationDialog();

  const { data, fetchData, isLoading } = useFetchData([], `${THING_POLICY}/list-all-policies`);
  const { fetchData: fetchDeletePolicy } = useFetchOnce([], DELETE);

  const handleOnDelete = async (name: string) => {
    const fetchDelete = async () => {
      const response = await fetchDeletePolicy(`${THING_POLICY}/${name}`);
      if (!response?.hasError) {
        toast(SUCCESS);
        fetchData();
      } else {
        toast(ERROR);
      }
    };

    await getConfirmation(fetchDelete);
  };

  const getActions = (row: RowType) => {
    const { original: { policyName } } = row;
    return (
      <MenuList>
        <MenuItem icon={<DeleteIcon />} onClick={() => handleOnDelete(policyName)}>
          Delete
        </MenuItem>
      </MenuList>
    );
  };

  return (
    <Section
      title={t('_Policy_')}
      actionBar={(
        <>
          <Button
            size="sm"
            variant="secondary"
            onClick={fetchData}
          >
            <FiRefreshCcw />
          </Button>
        </>
      )}
    >
      <ComboTable isLoading={isLoading} columns={columns} data={data} getActions={getActions} />
    </Section>
  );
};

export default PolicyList;
