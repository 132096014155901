import { isMobile } from 'utils';

interface IResponsive {
  children: JSX.Element,
}

export const Web = ({ children }: IResponsive): JSX.Element | null => {
  return !isMobile() ? children : null;
};

export const Mobile = ({ children }: IResponsive): JSX.Element | null => {
  return isMobile() ? children : null;
};
