import styled from '@emotion/styled';
import { adminBackgroundColor } from 'styles/variables';

export const LayoutStyle = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;

  .G-content {
    width: 100%;
    display: flex;

    .content {
      width: 100%;
      padding: 20px;
      background-color: ${adminBackgroundColor};
    }
  }

  /* Sidebar styles - start */
  .pro-sidebar-header {
    padding: 10px;

    img {
      cursor: pointer;
    }
  }

  .pro-sidebar .pro-menu a {
    &.active {
      color: #d8d8d8;
    }
  }

  .sidebar-logo {
    border-bottom: 1px solid #707070;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* Sidebar styles - end */
`;