import {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from 'react';

import Fetch from 'utils/fetch';
import { defaultLanguage } from 'configs';

interface ITranslatorContext {
  translate: Function,
  setLanguage: Function,
  language: string,
}

interface LooseObject {
  [key: string]: any
}

const getLocalePath = (lang: string) => `/translations/resources-locale_${lang}.json`;

export const TranslatorContext = createContext<ITranslatorContext>({} as ITranslatorContext);

const TranslatorProvider = ({ children }: any) => {
  const [language, setLanguage] = useState<string>(defaultLanguage);
  const [translations, setTranslations] = useState<LooseObject>({});

  const changeLanguage = useCallback(async lang => {
    const path = getLocalePath(lang);
    const { data } = await Fetch.get(path);

    setLanguage(lang);
    setTranslations(prev => ({ ...prev, [lang]: data || {} }));
  }, []);

  useEffect(() => {
    changeLanguage(defaultLanguage);
  }, [changeLanguage]);

  const translate = useCallback((str, ...args) => {
    const currentTranslations = translations[language];
    const translation = (currentTranslations && currentTranslations[str]) || str;

    return !args.length
      ? translation
      : translation.replace(/__param__/g, () => args.shift());
  }, [translations, language]);

  const value = useMemo(
    () => ({ translate, setLanguage: changeLanguage, language: translations[language] }),
    [translate, changeLanguage, translations, language],
  );

  return (
    <TranslatorContext.Provider value={value}>
      {children}
    </TranslatorContext.Provider>
  );
};

const useTranslator = () => {
  const { translate: t, setLanguage, language }: ITranslatorContext = useContext(TranslatorContext);
  return { t, setLanguage, language };
};

export {
  useTranslator,
  TranslatorProvider,
};
