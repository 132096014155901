import { appKey } from 'configs';
import { ResponseType } from 'models';

export const getToken = () => {
  const data: string | null = localStorage.getItem(`${appKey}`);
  return data ? JSON.parse(data) : null;
};

class Fetch {
  static createHeader = () => {
    const token = getToken();
    const herder = new Headers();
    herder.append('Content-Type', 'application/json');
    herder.append('Authorization', token);
    return herder;
  };

  static responseRestructure = async (response: Response): Promise<ResponseType> => {
    if (response.ok) {
      try {
        const data = await response.json();
        return { data, status: response.status, ok: response.ok, hasError: false };
      } catch (error) {
        return { data: null, status: response.status, ok: response.ok, hasError: false };
      }
    }
    return { data: null, status: response.status, ok: response.ok, hasError: true };
  };

  static get = async (url: string, newHeaders?: Headers) => {
    const headers = newHeaders || Fetch.createHeader();
    const results = await fetch(url, {
      headers,
      method: 'GET',
    });
    return Fetch.responseRestructure(results);
  };

  static post = async (url: string, body?: Object) => {
    const headers = Fetch.createHeader();
    const results = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
    });
    return Fetch.responseRestructure(results);
  };

  static put = async (url: string, body: Object) => {
    const headers = Fetch.createHeader();
    const results = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(body),
    });
    return Fetch.responseRestructure(results);
  };

  static delete = async (url: string, newHeaders?: Headers) => {
    const headers = newHeaders || Fetch.createHeader();
    const results = await fetch(url, {
      headers,
      method: 'DELETE',
    });
    return Fetch.responseRestructure(results);
  };

  static upload = async (url: string, body: any, pureBody: boolean = true, contentType?: string) => {
    const headers = new Headers();
    const token = getToken();
    headers.append('Authorization', token);
    if (contentType) headers.append('Content-Type', contentType);
    const results = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: pureBody ? body : JSON.stringify(body),
    });
    return Fetch.responseRestructure(results);
  };
}

export default Fetch;
