import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { useForm, SubmitHandler } from 'react-hook-form';

import {
  Flex,
  Modal,
  Input,
  Stack,
  Button,
  Heading,
  ModalBody,
  FormControl,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  useColorModeValue,
  useToast,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';

import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";

import { useTranslator } from 'utils/translator';
import { useFetchOnce } from 'utils/hooks';
import { RESET } from 'constants/api';
import { SUCCESS, ERROR } from 'constants/toaster';

import { IResetRequest, ChangePassValues } from './types';
import { ViewIcon } from '@chakra-ui/icons';

const ChangePass = ({ setOpenModal, openModal }: ChangePassValues) => {
  const { t } = useTranslator();
  const toast = useToast();
  const [show, setShow] = useState(false);

  const formSchema = yup.object().shape({
    password: yup.string()
      .required("Password is required")
      .min(8, "Password length should be at least 8 characters")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&,^*?])[A-Za-z\d#$@!%&,^*?]{8,30}$/, 'at least one Uppercase, lowercase, number and special character'),
    oldPassword: yup.string()
    .required("Password is required")
    .min(8, "Password length should be at least 8 characters")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&,^*?])[A-Za-z\d#$@!%&,^*?]{8,30}$/, 'at least one Uppercase, lowercase, number and special character'),
    matchingPassword: yup.string()
      .required("Repeat Password is required")
      .min(8, "Password length should be at least 8 characters")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&,^*?])[A-Za-z\d#$@!%&,^*?]{8,30}$/, 'at least one Uppercase, lowercase, number and special character')
      .oneOf([yup.ref("password")], "Passwords do not match")
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IResetRequest>(
      {
      resolver: yupResolver(formSchema)
    });

    const initState = {
      password:'',
      oldPasswod:'',
      matchingPassword:''
    }
    const { fetchData } = useFetchOnce();
    const handleClick = () => setShow(!show);

    const {user}:any = useSelector((state: RootState) => ({
      user: state?.user?.value,
    }));

  const handleReset: SubmitHandler<IResetRequest> = async (
      data: IResetRequest,
    ) => {
      if (errors && !Object.values(errors).length) {
        const resetData = {
            accountId: user?.id,
            oldPassword: data?.oldPassword,
            matchingPassword: data?.matchingPassword,
            password: data?.password,
            type: "PASSWORD"
        }
        const response = await fetchData(RESET, resetData);
        if(response){
          toast(SUCCESS);
          setOpenModal();
        } else toast(ERROR);
      }
      reset(initState);
    };
  
    const handleCancelModal = () => {
      reset(initState);
      setOpenModal()
    }
  return (
    <Modal
      isOpen={openModal}
      onClose={setOpenModal}
      closeOnOverlayClick={false}
    >
      <form onSubmit={handleSubmit(handleReset)}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody pb={6}>
          <Flex
            align='center'
            justify='center'
            bg={useColorModeValue('gray.50', 'gray.800')}
          >
            <Stack
              spacing={10}
              w='full'
              maxW='md'
              bg={useColorModeValue('white', 'gray.700')}
              rounded='xl'
            >
              <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }}>
                {t('_ChangePassword_')}
              </Heading>
              <FormControl margin={10}>
                {/* <FormControl isRequired>
                  <Input
                    marginBottom={5}
                    placeholder={t('_OldPassword_')}
                    _placeholder={{ color: 'gray.500' }}
                    type='password'
                  />
                </FormControl>
                <FormControl isRequired>
                  <Input
                    placeholder={t('_NewPassword_')}
                    _placeholder={{ color: 'gray.500' }}
                    type='password'
                  />
                </FormControl>
                <FormControl isRequired>
                  <Input
                    marginTop={5}
                    placeholder={t('_RepeatPassword_')}
                    _placeholder={{ color: 'gray.500' }}
                    type='password'
                  />
                </FormControl> */}
          <FormControl marginTop='10px' isInvalid={errors?.oldPassword?.message ? true : false}>
            <InputGroup size='lg'>
              <Input
                pr='4.5rem'
                type='text'
                placeholder='Password'
                {...register('oldPassword', {
                  required: t('_ErrorFill_'),
                })}
              />
            </InputGroup>
            <FormErrorMessage>{errors?.oldPassword?.message || ''}</FormErrorMessage>
          </FormControl>
          <FormControl marginTop='10px' isInvalid={errors?.password?.message ? true : false}>
            <InputGroup size='lg'>
              <Input
                pr='4.5rem'
                type={show ? 'text' : 'password'}
                placeholder='New password'
                {...register('password')}
              />
              <InputRightElement width='4.5rem'>
                <Button h='1.75rem' size='sm' onClick={handleClick}>
                  <ViewIcon />
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors?.password?.message || ''}</FormErrorMessage>
          </FormControl>
          <FormControl marginTop='10px' isInvalid={errors?.matchingPassword?.message ? true : false}>
            <InputGroup size='lg'>
              <Input
                pr='4.5rem'
                type={show ? 'text' : 'password'}
                placeholder='Repeat password'
                {...register('matchingPassword')}
              />
              <InputRightElement width='4.5rem'>
                <Button h='1.75rem' size='sm' onClick={handleClick}>
                  <ViewIcon />
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors?.matchingPassword?.message || ''}</FormErrorMessage>
          </FormControl>
              </FormControl>
            </Stack>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button type='submit' variant='primary' colorScheme='blue' mr={3}>
            {t('_Submit_')}
          </Button>
          <Button onClick={handleCancelModal}>{t('_Cancel_')}</Button>
        </ModalFooter>
      </ModalContent>
    </form>
    </Modal>
  );
};

export default ChangePass;
