export const columns = [
  {
    Header: 'Certificate Arn',
    accessor: 'certificateArn',
    type: 'COPYABLE_TEXT',
  },
  {
    Header: 'Certificate Id',
    accessor: 'certificateId',
    type: 'COPYABLE_TEXT',
  },
  {
    Header: 'Creation Date',
    accessor: 'creationDate',
    type: 'DATE'
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'STATUS_TEXT',
  },
];
