import { Link, NavLink, useNavigate } from 'react-router-dom'

import { Web } from 'components/Responsive'
import LogoComponent from 'components/Logo'

import { HeaderStyle } from './style'
import { Flex } from '@chakra-ui/react'

const Header = () => {
  const navigate = useNavigate()

  return (
    <HeaderStyle>
      <LogoComponent onClick={() => navigate('/home')} />
      <Flex as='nav' alignItems='center' gap='1em'>
        <Web>
          <>
            <NavLink to={'/home'}>Home</NavLink>
            <NavLink to={'/who-we-are'}>Who We Are</NavLink>
            <NavLink to={'/solutions'}>Solutions</NavLink>
            <NavLink to={'/partners'}>Partner</NavLink>
            <NavLink to={'/careers'}>Careers</NavLink>
            <Link className='get-in-touch' to={'/sign-in'}>
              Sign In
            </Link>
          </>
        </Web>
      </Flex>
    </HeaderStyle>
  )
}

export default Header
