import { useCallback, useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const AGPSTracker = () => {
  const { ref, inView } = useInView({ threshold: 0.2, triggerOnce: true })

  const road = useAnimation()
  const car = useAnimation()
  const bolt = useAnimation()
  const droplet = useAnimation()
  const target = useAnimation()
  const temperature = useAnimation()

  const animateIn = useCallback(() => {
    //* Animation
    road.start({ opacity: 1, transition: { duration: 1, delay: 0 } })
    car.start({ opacity: 1, transition: { duration: 1, delay: 1 } })
    bolt.start({ opacity: 1, transition: { duration: 1, delay: 2 } })
    droplet.start({ opacity: 1, transition: { duration: 1, delay: 3 } })
    target.start({ opacity: 1, transition: { duration: 1, delay: 4 } })
    temperature.start({ opacity: 1, transition: { duration: 1, delay: 5 } })
  }, [bolt, car, droplet, road, target, temperature])

  const animateOut = useCallback(() => {
    //* Setting Initials
    road.set({ opacity: 0 })
    car.set({ opacity: 0 })
    bolt.set({ opacity: 0 })
    droplet.set({ opacity: 0 })
    target.set({ opacity: 0 })
    temperature.set({ opacity: 0 })
  }, [bolt, car, droplet, road, target, temperature])

  useEffect(() => {
    if (inView) animateIn()
    else animateOut()
  }, [inView, animateIn, animateOut])

  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='250'
      height='250'
      viewBox='0 0 527 428.255'
    >
      <defs>
        <clipPath id='clip-path'>
          <path
            id='Path_20316'
            data-name='Path 20316'
            d='M25.584,30.642a9.526,9.526,0,0,0,.047,12.568,7.577,7.577,0,0,0,11.428.22,9.52,9.52,0,0,0-.044-12.568,7.877,7.877,0,0,0-5.867-2.714,7.531,7.531,0,0,0-5.563,2.494'
            transform='translate(-23.241 -28.148)'
            fill='#3f3f3f'
          />
        </clipPath>
        <filter
          id='Rectangle_3236'
          x='0'
          y='380.099'
          width='527'
          height='48.155'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' result='blur' />
          <feFlood flood-opacity='0.89' />
          <feComposite operator='in' in2='blur' />
          <feComposite in='SourceGraphic' />
        </filter>
      </defs>
      <g
        id='Group_1743'
        data-name='Group 1743'
        transform='translate(-212.646 -2311.868)'
      >
        {/* Car */}
        <motion.path
          animate={car}
          id='Subtraction_11'
          data-name='Subtraction 11'
          d='M357.124,0a30.393,30.393,0,1,0,30.394,30.393A30.427,30.427,0,0,0,357.124,0Zm0,45.59a15.2,15.2,0,1,1,15.2-15.2A15.214,15.214,0,0,1,357.124,45.59ZM75.984,0a30.393,30.393,0,1,0,30.394,30.393A30.427,30.427,0,0,0,75.984,0Zm0,45.59a15.2,15.2,0,1,1,15.2-15.2A15.214,15.214,0,0,1,75.984,45.59ZM425.5,15.2H402.076a7.6,7.6,0,0,0-7.492,8.86,37.992,37.992,0,1,1-74.929,0,7.6,7.6,0,0,0-7.494-8.86H120.941a7.6,7.6,0,0,0-7.494,8.86,37.992,37.992,0,1,1-74.929,0,7.6,7.6,0,0,0-7.494-8.86H7.6A7.595,7.595,0,0,0,.031,23.486L3.851,65.522c2.427,26.718,26.248,48.454,53.1,48.454h5.705L76.273,161.65a7.607,7.607,0,0,0,7.31,5.514h75.983a7.607,7.607,0,0,0,7.6-7.6v-45.59H177.5v-.14H426.967a7.589,7.589,0,0,0,6.132-7.459V22.8A7.607,7.607,0,0,0,425.5,15.2ZM357.124,83.582a53.249,53.249,0,0,0,53.189-53.189h7.6V98.779H167.165V30.393H303.936A53.249,53.249,0,0,0,357.124,83.582Zm-281.14,0A52.721,52.721,0,0,0,96.666,79.4a53.186,53.186,0,0,0,32.5-49h22.8V98.779H56.949c-19.195,0-36.224-15.538-37.961-34.637L15.916,30.393H22.8A53.249,53.249,0,0,0,75.984,83.582Zm13.325,68.386L78.461,113.976h73.5v37.992Z'
          transform='translate(692.696 2697.967) rotate(180)'
          fill='#3f3f3f'
        />
        {/* Bolt */}
        <motion.path
          animate={bolt}
          id='Path_20349'
          data-name='Path 20349'
          d='M164.715,80.939H15.234a5.8,5.8,0,0,1-1.743-4.1V46.381C13.52,32.907,25.45,22,40.156,21.993h99.637c14.706,0,26.624,10.914,26.659,24.388V76.839A5.814,5.814,0,0,1,164.715,80.939Z'
          transform='translate(309.695 2508.811)'
          fill='#3f3f3f'
        />
        {/* Droplet */}
        <motion.path
          animate={droplet}
          id='Path_20350'
          data-name='Path 20350'
          d='M164.715,80.939H15.234a5.8,5.8,0,0,1-1.743-4.1V46.381C13.52,32.907,25.45,22,40.156,21.993h99.637c14.706,0,26.624,10.914,26.659,24.388V76.839A5.814,5.814,0,0,1,164.715,80.939Z'
          transform='translate(309.695 2438.811)'
          fill='#3f3f3f'
        />
        {/* Target */}
        <motion.path
          animate={target}
          id='Path_20351'
          data-name='Path 20351'
          d='M164.715,80.939H15.234a5.8,5.8,0,0,1-1.743-4.1V46.381C13.52,32.907,25.45,22,40.156,21.993h99.637c14.706,0,26.624,10.914,26.659,24.388V76.839A5.814,5.814,0,0,1,164.715,80.939Z'
          transform='translate(309.695 2362.843)'
          fill='#3f3f3f'
        />
        {/* Temperature */}
        <motion.path
          animate={temperature}
          id='Path_20352'
          data-name='Path 20352'
          d='M164.715,80.939H15.234a5.8,5.8,0,0,1-1.743-4.1V46.381C13.52,32.907,25.45,22,40.156,21.993h99.637c14.706,0,26.624,10.914,26.659,24.388V76.839A5.814,5.814,0,0,1,164.715,80.939Z'
          transform='translate(309.695 2289.875)'
          fill='#3f3f3f'
        />
        {/* Droplet inner */}
        <motion.path
          animate={droplet}
          id='Path_20353'
          data-name='Path 20353'
          d='M47.465,57.9,35.8,44.356,24.145,57.9a21.488,21.488,0,0,0,0,27.122A15.371,15.371,0,0,0,35.8,90.661a15.372,15.372,0,0,0,11.661-5.636,21.488,21.488,0,0,0,0-27.122M23.446,72.292c.021-4.8,1.278-7.841,3.625-10.552L35.8,51.356l8.736,10.5c2.347,2.687,3.6,5.636,3.625,10.432Z'
          transform='translate(363.146 2424.768)'
          fill='#fff'
        />
        {/* Target inner */}
        <motion.g
          animate={target}
          id='Group_1254'
          data-name='Group 1254'
          transform='translate(380.472 2396.956)'
        >
          <path
            id='Path_20315'
            data-name='Path 20315'
            d='M51.831,57.066a19.166,19.166,0,0,0-.044-26.942,18.629,18.629,0,0,0-26.657-.532A19.317,19.317,0,0,0,25.3,56.657a18.485,18.485,0,0,0,26.536.41'
            transform='translate(-19.035 -24.236)'
            fill='#fff'
          />
          <g
            id='Group_1226'
            data-name='Group 1226'
            transform='translate(11.394 10.235)'
          >
            <g
              id='Group_1225'
              data-name='Group 1225'
              clip-path='url(#clip-path)'
            >
              <rect
                id='Rectangle_313'
                data-name='Rectangle 313'
                width='17.763'
                height='18.691'
                transform='translate(-0.8 0)'
                fill='#3f3f3f'
              />
            </g>
          </g>
          <line
            id='Line_1841'
            data-name='Line 1841'
            y1='6.474'
            x2='5.971'
            transform='translate(0.645 32)'
            fill='#00a1e1'
            stroke='#fff'
            stroke-width='3'
          />
          <line
            id='Line_1842'
            data-name='Line 1842'
            y1='6.474'
            x2='5.971'
            transform='translate(32.336 0.461)'
            fill='#00a1e1'
            stroke='#fff'
            stroke-width='3'
          />
          <line
            id='Line_1843'
            data-name='Line 1843'
            x2='6.016'
            y2='6.705'
            transform='translate(0 0)'
            fill='#00a1e1'
            stroke='#fff'
            stroke-width='3'
          />
          <line
            id='Line_1844'
            data-name='Line 1844'
            x2='6.016'
            y2='6.705'
            transform='translate(32.336 32)'
            fill='#00a1e1'
            stroke='#fff'
            stroke-width='3'
          />
        </motion.g>
        {/*  */}
        {/* Temperature inner */}
        <motion.path
          animate={temperature}
          id='Path_20354'
          data-name='Path 20354'
          d='M39.087,22.725V9.3c0-3.712-3.411-6.711-7.638-6.711s-7.641,3-7.641,6.711V22.725a10.823,10.823,0,0,0-5.095,8.951,10.207,10.207,0,0,0,2.549,6.646v.065h.049a13.307,13.307,0,0,0,10.138,4.477,13.339,13.339,0,0,0,10.135-4.477h.052v-.065a10.219,10.219,0,0,0,2.546-6.646,10.823,10.823,0,0,0-5.095-8.951M23.808,31.676A6.5,6.5,0,0,1,26.864,26.3L28.9,24.962V9.3a2.569,2.569,0,0,1,5.095,0V24.962L36.031,26.3a6.533,6.533,0,0,1,3.056,5.371ZM58.493,4.824h-4.1L45.787,24.962h3.972l2.063-5.146h9.243L63.1,24.962h4ZM53.019,16.885,56.354,8.56h.206l3.335,8.324Z'
          transform='translate(356.759 2316.83)'
          fill='#fff'
        />
        {/* Bolt inner */}
        <motion.path
          animate={bolt}
          id='Path_20355'
          data-name='Path 20355'
          d='M56.171,85.726a17.3,17.3,0,0,0,.92-2.2l6.195-3.186a12.863,12.863,0,0,0,.264-4.613l-6.1,1.746a6.115,6.115,0,0,0-1.824-2.8l3.87-5.169a11.787,11.787,0,0,0-4.24-1.488l-5.2,4.713a16.055,16.055,0,0,0-2.322.05,20.919,20.919,0,0,0-2.508.425l-.712-4.135a39.992,39.992,0,0,0-6.276,2.522L37,76.5a36.1,36.1,0,0,0-5.01,3.473l-4.877-.667a41.358,41.358,0,0,0-4.632,5.041l3.442,2.241a22.426,22.426,0,0,0-1.329,2.238,17.033,17.033,0,0,0-.92,2.2l-6.195,3.189a12.857,12.857,0,0,0-.264,4.613l6.1-1.749a6.084,6.084,0,0,0,1.827,2.8l-3.873,5.172a11.894,11.894,0,0,0,4.24,1.488l5.2-4.716a16.051,16.051,0,0,0,2.322-.05,20.51,20.51,0,0,0,2.511-.425l.712,4.135a40.072,40.072,0,0,0,6.273-2.522l1.243-4.91a36.5,36.5,0,0,0,5.008-3.476l4.877.67A41.587,41.587,0,0,0,58.284,90.2l-3.442-2.241a22.085,22.085,0,0,0,1.329-2.236M37.428,93.1c-3.5.345-5.019-1.983-3.389-5.2a13.091,13.091,0,0,1,9.3-6.448c3.5-.342,5.022,1.985,3.389,5.2a13.1,13.1,0,0,1-9.3,6.445'
          transform='translate(358.363 2474.558)'
          fill='#fff'
        />
        {/* Road */}
        <motion.g
          animate={road}
          id='Group_1255'
          data-name='Group 1255'
          transform='translate(-500.397 553.162)'
        >
          <g
            transform='matrix(1, 0, 0, 1, 713.04, 1758.71)'
            filter='url(#Rectangle_3236)'
          >
            <path
              id='Rectangle_323'
              data-name='Rectangle 323'
              d='M22.04,36.155H504.96c1.8,0,3.359-.056,4.633-.158a17.119,17.119,0,0,0,2.924-.428c.662-.18,1-.394.979-.63s-.4-.493-1.174-.763L434.828,7.168a13,13,0,0,0-2.1-.458c-.9-.14-1.989-.266-3.185-.371s-2.5-.19-3.848-.248S422.964,6,421.6,6H55.832c-1.364,0-2.7.032-3.95.091s-2.418.143-3.439.248-1.9.231-2.576.371a3.667,3.667,0,0,0-1.343.458l-33.1,27.008c-.33.27-.286.527.08.763a6.257,6.257,0,0,0,2.015.63A32.752,32.752,0,0,0,17.148,36C18.589,36.1,20.24,36.155,22.04,36.155Z'
              transform='translate(0 380.1)'
              fill='#434343'
            />
          </g>
          <path
            id='Rectangle_324'
            data-name='Rectangle 324'
            d='M1.186,9.128H70.247c.164,0,.323,0,.471-.014s.284-.022.4-.038a2.2,2.2,0,0,0,.292-.056.271.271,0,0,0,.139-.068L77.482.15c.014-.021,0-.04-.047-.058a.946.946,0,0,0-.2-.048c-.09-.014-.2-.024-.326-.032S76.639,0,76.488,0H12.713c-.151,0-.3,0-.441.012S12,.03,11.883.044a2.85,2.85,0,0,0-.3.048.46.46,0,0,0-.16.058l-11.2,8.8C.192,8.977.2,9,.229,9.021a.577.577,0,0,0,.183.056,3.054,3.054,0,0,0,.329.038C.872,9.123,1.022,9.128,1.186,9.128Z'
            transform='translate(759.021 2153.415)'
            fill='#fff'
          />
          <path
            id='Rectangle_325'
            data-name='Rectangle 325'
            d='M1.185,9.128H70.247c.164,0,.323,0,.471-.014s.284-.022.4-.038a2.2,2.2,0,0,0,.292-.056.271.271,0,0,0,.139-.068L77.482.15c.014-.021,0-.04-.047-.058a.946.946,0,0,0-.2-.048c-.09-.014-.2-.024-.326-.032S76.639,0,76.488,0H12.713c-.151,0-.3,0-.441.012S12,.03,11.883.044a2.85,2.85,0,0,0-.3.048.46.46,0,0,0-.16.058l-11.2,8.8C.192,8.977.2,9,.229,9.021a.577.577,0,0,0,.183.056,3.054,3.054,0,0,0,.329.038C.872,9.123,1.022,9.128,1.185,9.128Z'
            transform='translate(878.34 2153.415)'
            fill='#fff'
          />
          <path
            id='Rectangle_326'
            data-name='Rectangle 326'
            d='M1.186,9.128H70.247c.164,0,.323,0,.471-.014s.284-.022.4-.038a2.2,2.2,0,0,0,.292-.056.271.271,0,0,0,.139-.068L77.482.15c.014-.021,0-.04-.047-.058a.946.946,0,0,0-.2-.048c-.09-.014-.2-.024-.326-.032S76.639,0,76.488,0H12.713c-.151,0-.3,0-.441.012S12,.03,11.883.044a2.85,2.85,0,0,0-.3.048.46.46,0,0,0-.16.058l-11.2,8.8C.192,8.977.2,9,.229,9.021a.577.577,0,0,0,.183.056,3.054,3.054,0,0,0,.329.038C.872,9.123,1.022,9.128,1.186,9.128Z'
            transform='translate(997.66 2153.415)'
            fill='#fff'
          />
          <path
            id='Rectangle_327'
            data-name='Rectangle 327'
            d='M1.185,9.128H70.247c.164,0,.323,0,.471-.014s.284-.022.4-.038a2.2,2.2,0,0,0,.292-.056.271.271,0,0,0,.139-.068L77.482.15c.014-.021,0-.04-.047-.058a.946.946,0,0,0-.2-.048c-.09-.014-.2-.024-.326-.032S76.639,0,76.488,0H12.713c-.151,0-.3,0-.441.012S12,.03,11.883.044a2.85,2.85,0,0,0-.3.048.46.46,0,0,0-.16.058l-11.2,8.8C.192,8.977.2,9,.229,9.021a.577.577,0,0,0,.183.056,3.054,3.054,0,0,0,.329.038C.872,9.123,1.022,9.128,1.185,9.128Z'
            transform='translate(1116.979 2153.415)'
            fill='#fff'
          />
        </motion.g>
        {/*  */}
        {/* <g
          id='alert-svgrepo-com_3_'
          data-name='alert-svgrepo-com (3)'
          transform='translate(528 2401.776)'
        >
          <g
            id='Group_1280'
            data-name='Group 1280'
            transform='translate(-0.001 42.006)'
          >
            <path
              id='Path_20392'
              data-name='Path 20392'
              d='M85.2,101.96,51,46.942c-1.981-3.185-4.651-4.936-7.53-4.936s-5.553,1.752-7.533,4.939L1.812,101.877C-.168,105.062-.539,108.1.763,110.45s4.124,3.659,7.92,3.664l69.664.053h-.059v.007c3.4,0,6.631-1.292,7.941-3.646S87.176,105.147,85.2,101.96Zm-39.833,1.232a2.7,2.7,0,0,1-1.905.792,2.7,2.7,0,0,1-2.708-2.724,2.619,2.619,0,0,1,.674-1.917,2.181,2.181,0,0,1,1.635-.8h.127a3.1,3.1,0,0,1,2.073.806,2.79,2.79,0,0,1,.869,1.92A2.688,2.688,0,0,1,45.363,103.192Zm-1.9-10.1a2.711,2.711,0,0,1-2.707-2.724L40.474,65.82A2.566,2.566,0,0,1,42.889,63.1H43.2a3.074,3.074,0,0,1,2.081.8,2.777,2.777,0,0,1,.87,1.927l.018,24.551A2.71,2.71,0,0,1,43.466,93.1Z'
              transform='translate(0.001 -42.006)'
              fill='#00d93a'
            />
          </g>
        </g> */}
      </g>
    </svg>
  )
}

export default AGPSTracker
