import { useForm, SubmitHandler } from 'react-hook-form';
import { useLoadScript } from '@react-google-maps/api';
import {
  Box,
  Flex,
  Stack,
  Radio,
  Modal,
  Input,
  Select,
  Spacer,
  Button,
  Tooltip,
  useToast,
  ModalBody,
  FormLabel,
  RadioGroup,
  ModalFooter,
  ModalHeader,
  FormControl,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from '@chakra-ui/react';

import GoogleMap from 'components/GoogleMap';

import { mapApiKey } from 'configs';
import { LOCATION } from 'constants/api';
import { PUT, POST } from 'constants/method';
import { ERROR, SUCCESS } from 'constants/toaster';
import { useTranslator } from 'utils/translator';
import useFetchOnce from 'utils/hooks/useFetchOnce';

import { AddLocationPropsType, AddLocationType } from './types';
import SearchLocation from './SearchLocation';

const locationTypes = [
  { category: 'HOUSE' },
  { category: 'SCHOOL' },
  { category: 'WORK' },
  { category: 'SHOPPING' },
  { category: 'RESTAURANT' },
  { category: 'GYM' },
  { category: 'OTHER' },
];

const librariesList = ['places'];

enum selectedTab {
  createdByMe = 1
}

const AddLocation = ({ visible = false, closeModal, setTabIndex, selectedLocation }: AddLocationPropsType) => {
  const toast = useToast();
  const { t } = useTranslator();

  const apiKey = mapApiKey;

  useLoadScript({
    googleMapsApiKey: apiKey,
    libraries: librariesList as any,
  });

  const {
    reset,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<AddLocationType>();

  const { fetchData: fetchAddLocation, hasError } = useFetchOnce({}, selectedLocation ? PUT : POST);

  const handleCloseModal = (isUpdate?: boolean) => {
    reset({ latitude: undefined, longitude: undefined, postalCode: undefined });
    closeModal(isUpdate);
  };

  const watchCoordinateFields = watch(['latitude', 'longitude']);

  const handleAddLocation: SubmitHandler<AddLocationType> = async (data: AddLocationType) => {
    await fetchAddLocation(`${LOCATION}${selectedLocation ? `?id=${selectedLocation.id}` : ''}`, data);
    if (!hasError) {
      handleCloseModal(true);
      toast(SUCCESS);
      setTabIndex && setTabIndex(selectedTab.createdByMe);
    } else {
      toast(ERROR);
    }
  };

  return (
    <Modal
      size={"3xl"}
      isOpen={visible}
      onClose={handleCloseModal}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('_AddLocation_')}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handleAddLocation)}>
          <ModalBody pb={6}>
            <Flex>
              <Box flex='1' mr={4}>
                <FormControl isInvalid={!!errors.name}>
                  <RadioGroup defaultValue='PUBLIC'>
                    <Stack spacing={4} direction='row'>
                      <Radio colorScheme='green' value='PUBLIC' checked={selectedLocation?.accessibility === "PUBLIC"} defaultChecked={!selectedLocation} {...register('accessibility')}>
                        Public
                      </Radio>
                      <Radio colorScheme='green' value='PRIVATE' checked={selectedLocation?.accessibility === "PRIVATE"} {...register('accessibility')}>
                        Private
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  <Box mt={5}>
                    <SearchLocation {...register('address')} panTo={reset} />
                  </Box>
                  <Tooltip label={errors?.name?.message}>
                    <Input
                      placeholder={t('_LocationName_')}
                      mt={5}
                      {...register('name', {
                        required: true,
                      })}
                      defaultValue={selectedLocation?.name}
                    />
                  </Tooltip>
                  <Tooltip label={errors?.postalCode?.message}>
                    <Input
                      placeholder={t('_PostalCode_')}
                      mt={5}
                      {...register('postalCode', {
                        required: true,
                        pattern: {
                          value: /^\d+$/,
                          message: t('_InvalidPostalCode_'),
                        },
                      })}
                      defaultValue={selectedLocation?.postalCode}
                    />
                  </Tooltip>
                  <Tooltip label={errors?.category?.message}>
                    <Select variant="primary" mt={5} placeholder={t('_LocationCategory_')} size="lg"
                      {...register('category', { required: true })} defaultValue={selectedLocation?.category}>
                      {locationTypes?.map(({ category }: any) => (
                        <option style={{ fontSize: '14px', color: 'green.800' }} key={category} value={`${category}`}>{category}</option>
                      ))}
                    </Select>
                  </Tooltip>
                  <FormLabel mt={6} htmlFor="location">{t('_LocationCoordinates_')}</FormLabel>
                  <Flex mt={2}>
                    <Tooltip label={errors?.latitude?.message}>
                      <Input
                        placeholder={t('_LocationLatitude_')}
                        {...register('latitude')}
                        defaultValue={selectedLocation?.latitude}
                      />
                    </Tooltip>
                    <Spacer ml={2} />
                    <Tooltip label={errors?.longitude?.message}>
                      <Input
                        placeholder={t('_LocationLongitude_')}
                        {...register('longitude')}
                        defaultValue={selectedLocation?.longitude}
                      />
                    </Tooltip>
                  </Flex>
                </FormControl>
              </Box>
              <Box flex='1'>
                <GoogleMap setNewLocation={reset} newLocation={{ latitude: String(watchCoordinateFields[0]), longitude: String(watchCoordinateFields[1]) }} height='63vh' />
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => handleCloseModal(false)} mr={3}>{t('_Cancel_')}</Button>
            <Button variant="primary" type="submit">
              {t('_Submit_')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default AddLocation;
