import { useMemo } from 'react';
import { SubmitHandler } from 'react-hook-form';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';

import Form from 'components/Form';
import { FormElementType } from 'components/Form/type';

import { CUSTOMER_ASSIGN_DEVICE_TIER, CUSTOMER_ASSIGN_SERVICE_TIER } from 'constants/api';
import { POST } from 'constants/method';
import { useTranslator } from 'utils/translator';
import { ERROR, SUCCESS } from 'constants/toaster';
import useFetchOnce from 'utils/hooks/useFetchOnce';

const AssignTier = ({
  closeModal,
  deviceTier,
  serviceTier,
  tierInfo,
  organizationName,
}: any) => {
  const { t } = useTranslator();
  const toast = useToast();

  const { fetchData } = useFetchOnce({}, POST);

  const handleCloseModal = (update?: boolean) => {
    closeModal(update);
  };

  const addTier = async (newData: any) => {
    const data: any = { ...newData, organizationName: tierInfo?.customer_name || organizationName };
    const params = new URLSearchParams(data);

    const [deviceResponse, serviceResponse] = await Promise.all([
      fetchData(`${CUSTOMER_ASSIGN_DEVICE_TIER}?${params.toString()}`, {}),
      fetchData(`${CUSTOMER_ASSIGN_SERVICE_TIER}?${params.toString()}`, {}),
    ]);

    if (deviceResponse?.ok && serviceResponse?.ok) {
      handleCloseModal(true);
      toast(SUCCESS);
    } else {
      toast(ERROR);
    }
  };

  const handleAddTier: SubmitHandler<any> = async (
    data: any,
  ) => addTier(data);

  const formElements: FormElementType[] = useMemo(() => ([
    {
      placeholder: t('_AssignDeviceCostTier_'),
      registerProp: ['deviceCostTierId'],
      options: deviceTier
        ? deviceTier.map((item: { id: string; tier_name: string; }) => ({ label: item.tier_name, value: item.id }))
        : [],
      label: t('_DeviceCostTier_'),
      type: 'select',
      defaultValue: tierInfo?.cost_tier_id,
    },
    {
      placeholder: t('_AssignServiceCostTier_'),
      registerProp: ['deviceServiceTierId'],
      options: serviceTier
        ? serviceTier.map((item: { id: string; tier_name: string; }) => ({ label: item.tier_name, value: item.id }))
        : [],
      label: t('_ServiceCostTier_'),
      type: 'select',
      defaultValue: tierInfo?.service_tier_id,
    },
  ]), [deviceTier, serviceTier, t, tierInfo]);

  return (
    <Modal isOpen onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('_Tiers_')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Form
            elements={formElements}
            onClose={handleCloseModal}
            submitHandler={handleAddTier}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AssignTier;
