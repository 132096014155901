import { Image, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import NoDataImage from 'assets/images/no_data.png';

const Style = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  img {
    margin-bottom: 1rem;
  }
`;

const NoData = () => {
  return (
    <Style>
      <Image src={NoDataImage} alt="No Data" />
      <Text>No Data</Text>
    </Style>
  );
};

export default NoData;
