import { CopyIcon } from '@chakra-ui/icons';
import { Center, Flex, Text } from '@chakra-ui/react';

import Style from './style';

const CopyText = ({ text, maxLength = 20 }: any) => {
  if (!text) return null;

  const textLength = typeof text === 'string'
    ? text.length
    : typeof text === 'boolean' ? null : text?.toString().length;

  return (
    <Style>
      <Flex color="black" maxHeight="20px" overflow="hidden">
        <Text w="150px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {text}
        </Text>
        {(textLength && textLength > maxLength) && <Center className="copy" w="18px" display="none">
          <CopyIcon cursor="pointer" onClick={() => navigator.clipboard.writeText(text)} />
        </Center>}
      </Flex>
    </Style>
  );
};

export default CopyText;
