import { useCallback, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  InputGroup,
  Input,
  InputRightElement,
  Button,
  Flex,
  useToast,
  FormControl,
  FormErrorMessage,
  Stack,
} from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';

import { yupResolver } from "@hookform/resolvers/yup";

import { useTranslator } from 'utils/translator';
import { ERROR, SUCCESS } from 'constants/toaster';

import { setToken } from 'store/features/token';

import { login, verificationAccount } from './utils';
import { ILoginRequest, ILoginResponse } from './types';
import { SignInSchema } from './FormScheams';

import { SignInStyle } from './styles';
import { setUserInfo } from 'store/features/user';
import parseJwt from './parser';

const SignIn = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { t } = useTranslator();

  const [searchParams] = useSearchParams();

  const [show, setShow] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginRequest>({resolver: yupResolver(SignInSchema)});

  const handleClick = () => setShow(!show);

  const handleSingIn: SubmitHandler<ILoginRequest> = async (
    data: ILoginRequest,
  ) => {
    if (!errors.username && !errors.password) {
      const response: ILoginResponse = await login(data);
      if (response.accessToken && response.tokenType) {
        const userInfo = parseJwt(response.accessToken);
        dispatch(setUserInfo({user: userInfo, roles: userInfo.authorities}));
        dispatch(setToken(`${response.tokenType} ${response.accessToken}`));
        toast(SUCCESS);
      } else {
        toast(ERROR);
      }
    } else {
      toast(ERROR);
    }
  };

  const handleVerify = useCallback(async (token: string | null, accountId: string | null) => {
    const response = await verificationAccount(token, accountId);
    if (response?.ok) {
      toast(SUCCESS);
    } else {
      toast(ERROR);
    }
  }, [toast]);

  useEffect(() => {
    const token: string | null = searchParams.get('verificationTokenId');
    const accountId: string | null = searchParams.get('accountId');
    (accountId && token) && handleVerify(token, accountId);
  }, [handleVerify, searchParams]);

  return (
    <SignInStyle>
      <form onSubmit={handleSubmit(handleSingIn)}>
        <Stack spacing={6} maxWidth='450px'>
          <FormControl isInvalid={errors?.username?.message ? true : false}>
            <InputGroup size='lg'>
              <Input
                pr='4.5rem'
                type='text'
                placeholder='Login'
                {...register('username', {
                  required: t('_ErrorFill_'),
                })}
              />
            </InputGroup>
            <FormErrorMessage>{errors?.username?.message || ''}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors?.password?.message ? true : false}>
            <InputGroup size='lg'>
              <Input
                pr='4.5rem'
                type={show ? 'text' : 'password'}
                placeholder='Password'
                {...register('password', {
                  required: t('_ErrorFill_'),
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&,^*?])[A-Za-z\d#$@!%&,^*?]{8,30}$/,
                    message: t('_ErrorPassword_')
                  }
                })}
              />
              <InputRightElement width='4.5rem'>
                <Button h='1.75rem' size='sm' onClick={handleClick}>
                  <ViewIcon />
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors?.password?.message || ''}</FormErrorMessage>
          </FormControl>
          <Button
            type='submit'
            size='md'
            variant='primary'
            height='48px'
            width='100%'
            border='2px'
            color='#fff'
            background='#00AB39'
            borderColor='green.500'
            margin='30px 0'
            _hover={{ bg: 'brand.600', scale: 0.98 }}
          >
            Login
          </Button>
          <Flex color='#00AB39' flexDirection='row' justifyContent='flex-end'>
            <Link to='/forgot-password'>Forgot password?</Link>
          </Flex>
        </Stack>
      </form>
      {/* <Flex justifyContent='center' mt={5}>
        <Box mr='1'>New to pearson?</Box>
        <Box color='#00AB39'>
          <Link to='/sign-up'>Create an account</Link>
        </Box>
      </Flex> */}
    </SignInStyle>
  );
};

export default SignIn;
