import { MouseEventHandler } from 'react'
import { Image, Flex, ResponsiveValue } from '@chakra-ui/react'

import Logo from 'assets/logo.svg'
import Dark from 'assets/redesignImages/solutions/logo_dark.svg'

interface ILogoComponent {
  display?: ResponsiveValue<any> | undefined
  light?: boolean
  size?: string
  onClick?: MouseEventHandler<HTMLDivElement> | undefined
}

const LogoComponent = ({
  display,
  light = true,
  size,
  onClick,
}: ILogoComponent) => {
  return (
    <Flex align='center' onClick={onClick} className='logo_'>
      <Image
        src={light ? Logo : Dark}
        display={display}
        objectFit='cover'
        alt='GreenWaveIot'
        marginRight='0.5rem'
        marginLeft='0.5rem'
      />
    </Flex>
  )
}

export default LogoComponent
