import { useDisclosure, Divider, Center, MenuList, MenuItem, Button, useToast } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { FiRefreshCcw } from 'react-icons/fi';

import Section from 'components/Section';
import ComboTable from 'components/Table/ComboTable';

import AddThing from './AddThing';

import { useTranslator } from 'utils/translator';
import useFetchData from 'utils/hooks/useFetchData';
import useFetchOnce from 'utils/hooks/useFetchOnce';
import useConfirmationDialog from 'utils/hooks/useConfirmationDialog';

import { THING_CONTROLLER } from 'constants/api';
import { DELETE } from 'constants/method';
import { SUCCESS, ERROR } from 'constants/toaster';

import { columns } from './configs';
import { RowType } from './types';

const ThingsController = () => {
  const { t } = useTranslator();
  const { isOpen,onOpen,onClose } = useDisclosure();
  const { getConfirmation } = useConfirmationDialog();
  const toast = useToast();
  
  const { data, fetchData, isLoading } = useFetchData( [],`${ THING_CONTROLLER }/list-all-registered-things` );
  const { fetchData: fetchDeleteThing } = useFetchOnce([], DELETE);

  const handleDelete = async ( thingName: string ) => {
    const fetchDelete = async () => {
      const response = await fetchDeleteThing( `${ THING_CONTROLLER }/${ thingName }` );
      if( !response?.hasError ) {
        toast(SUCCESS);
        fetchData();
      } else {
        toast(ERROR);
      }
    };
    
    await getConfirmation(fetchDelete);
  };

  const getActions = (row: RowType) => {
    const { original: { thingName } } = row;
    return (
      <MenuList>
        <MenuItem icon={<DeleteIcon />} onClick={() => handleDelete(thingName)}>
          Delete
        </MenuItem>
      </MenuList>
    );
  };

  return (
    <Section
      title={t('_Thing_')}
      actionBar={(
        <>
          <Button size="md" variant="primary" onClick={onOpen}>
            {t('_AddThing_')}
          </Button>
          <Center height="40px" marginLeft="5px">
            <Divider orientation="vertical" />
          </Center>
          <Button
            size="sm"
            variant="secondary"
            onClick={fetchData}
          >
            <FiRefreshCcw />
          </Button>
        </>
      )}
    >
      <ComboTable isLoading={isLoading} columns={columns} getActions={getActions} data={data} />
      <AddThing
        visible={isOpen}
        closeModal={onClose}
        updateData={fetchData}
      />
    </Section>
  );
};

export default ThingsController;
