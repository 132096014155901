import { useCallback, useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const AAgroD = () => {
  const { ref, inView } = useInView({ threshold: 0.2, triggerOnce: true })

  const floor = useAnimation()
  const container = useAnimation()
  const decoration = useAnimation()
  const droplet = useAnimation()
  const waves = useAnimation()
  const sun = useAnimation()
  const app = useAnimation()

  const animateIn = useCallback(() => {
    //* Animation
    floor.start({ opacity: 1, transition: { duration: 1, delay: 0 } })
    container.start({ opacity: 1, transition: { duration: 1, delay: 1 } })
    app.start({ opacity: 1, transition: { duration: 1, delay: 1 } })
    decoration.start({ opacity: 1, transition: { duration: 1, delay: 2 } })
    droplet.start({ opacity: 1, transition: { duration: 1, delay: 3 } })
    waves.start({ opacity: 1, transition: { duration: 1, delay: 4 } })
    sun.start({ opacity: 1, transition: { duration: 1, delay: 5 } })
  }, [floor, container, app, sun, droplet, decoration, waves])

  const animateOut = useCallback(() => {
    //* Setting Initials
    floor.set({ opacity: 0 })
    container.set({ opacity: 0 })
    app.set({ opacity: 0 })
    waves.set({ opacity: 0 })
    decoration.set({ opacity: 0 })
    droplet.set({ opacity: 0 })
    sun.set({ opacity: 0 })
  }, [floor, container, app, decoration, droplet, sun, waves])

  useEffect(() => {
    if (inView) animateIn()
    else animateOut()
  }, [inView, animateIn, animateOut])

  return (
    <svg
      ref={ref}
      id='Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      width='200'
      height='200'
      viewBox='0 0 439.15 460.6'
    >
      <defs>
        <style>
          {`.cls-1,.cls-2,.cls-4{fill:none;}.cls-1,.cls-2,.cls-5{stroke:#434343;}.cls-1,.cls-2,.cls-4,.cls-5{stroke-linecap:round;stroke-linejoin:round;}.cls-1,.cls-5{stroke-width:15px;}.less-stroke{stroke-width:10px !important;}.cls-2,.cls-4{stroke-width:8px;}.cls-3{fill:#434343;}.cls-4{stroke:#00af40;}.cls-5{fill:#e6f600;}`}
        </style>
      </defs>
      <title>agroD</title>
      {/* Container */}
      <motion.g animate={container} id='container'>
        <path
          className='cls-1'
          d='M62.18,187.69,72,134.94a17.46,17.46,0,0,1,2-5.49c5.54-9.65,26.41-40.94,63.84-40.94,35.27,0,55.4,27.89,62.13,39.22a29.14,29.14,0,0,1,3.43,8.67l10.91,49.28s7.28,43.37-45.15,54.66H109.62S74.36,241.16,63,203A32.7,32.7,0,0,1,62.18,187.69Z'
        />
        <line
          className='cls-1'
          x1='295.15'
          y1='268.26'
          x2='199.85'
          y2='225.72'
        />
        <polyline
          className='cls-1'
          points='138.15 182.47 138.15 277.11 74.21 277.11'
        />
      </motion.g>
      {/* Droplet */}
      <motion.path
        animate={droplet}
        id='droplet'
        className='cls-1'
        d='M228.09,340.09l-15,23.13a16.59,16.59,0,0,0-2.32,5.6c-1.08,5.37-1.45,15.56,9.05,21.55a17.74,17.74,0,0,0,17.56.23c5-2.83,9.93-8.39,9.28-19.25a18.8,18.8,0,0,0-3.41-9.69Z'
      />
      {/* Waves */}
      <motion.g animate={waves} id='waves'>
        <path
          className='cls-1'
          d='M170.55,436.43s20.77-30.3,33.71-13.28c9,11.85,18.18,4.68,22.92-.77a13.85,13.85,0,0,1,17.36-3.11,30,30,0,0,1,7.15,6,12.11,12.11,0,0,0,10.64,4c3.56-.5,8.17-2.3,13.83-6.78a10,10,0,0,1,11.27-.74c4.86,2.92,11.34,7.77,15.55,14.67'
        />
        <path
          className='cls-1'
          d='M8,436.43s20.77-30.3,33.7-13.28c9,11.85,18.18,4.68,22.92-.77A13.87,13.87,0,0,1,82,419.27a30,30,0,0,1,7.15,6,12.1,12.1,0,0,0,10.64,4c3.56-.5,8.17-2.3,13.83-6.78a10,10,0,0,1,11.27-.74c4.86,2.92,11.33,7.77,15.55,14.67'
        />
      </motion.g>
      {/* Floor */}
      <motion.g animate={floor} id='floor'>
        <line
          className='cls-1'
          x1='25.87'
          y1='450.72'
          x2='129.02'
          y2='450.72'
        />
        <line
          className='cls-1'
          x1='152.51'
          y1='450.72'
          x2='388.43'
          y2='450.72'
        />
      </motion.g>
      {/* App */}
      <motion.g animate={app} id='app'>
        <polyline
          className='cls-2'
          points='319.66 352.68 319.66 283.92 430.3 283.92 430.3 388.08 343.49 388.08'
        />
        <line className='cls-2' x1='319.66' y1='316.6' x2='430.3' y2='316.6' />
        <line
          className='cls-2'
          x1='355.74'
          y1='299.91'
          x2='412.6'
          y2='299.91'
        />
        <ellipse
          className='cls-3'
          cx='338.04'
          cy='299.91'
          rx='5.45'
          ry='3.74'
        />
        <polyline
          className='cls-4'
          points='319.66 382.3 353.02 349.28 371.4 366.45 394.21 344.85 405.79 356.08'
        />
      </motion.g>
      {/* Sun */}
      <motion.g animate={sun} id='sun'>
        <circle
          className='cls-5 less-stroke'
          cx='303.37'
          cy='106.33'
          r='17.82'
        />
        <line
          className='cls-1 less-stroke'
          x1='303.37'
          y1='56'
          x2='303.37'
          y2='66.38'
        />
        <line
          className='cls-1 less-stroke'
          x1='266.63'
          y1='69.35'
          x2='273.97'
          y2='76.69'
        />
        <line
          className='cls-1 less-stroke'
          x1='254.21'
          y1='106.33'
          x2='264.6'
          y2='106.33'
        />
        <line
          className='cls-1 less-stroke'
          x1='266.63'
          y1='142.05'
          x2='273.97'
          y2='134.71'
        />
        <line
          className='cls-1 less-stroke'
          x1='303.37'
          y1='155.15'
          x2='303.37'
          y2='144.76'
        />
        <line
          className='cls-1 less-stroke'
          x1='339.67'
          y1='142.05'
          x2='332.33'
          y2='134.71'
        />
        <line
          className='cls-1 less-stroke'
          x1='352.6'
          y1='106.33'
          x2='342.21'
          y2='106.33'
        />
        <line
          className='cls-1 less-stroke'
          x1='335.29'
          y1='69.35'
          x2='327.95'
          y2='76.69'
        />
      </motion.g>
      {/* Decoration clouds and extra lines etc */}
      <motion.g animate={decoration} id='decoration'>
        <path
          className='cls-1'
          d='M25.87,380.26V191a18.12,18.12,0,0,1,2.41-9.06c3.06-5.29,8.72-13.29,15.29-13.74'
        />
        <line className='cls-1' x1='82.04' y1='316.6' x2='106.18' y2='316.6' />
        <line className='cls-1' x1='57.19' y1='356.09' x2='82.04' y2='356.09' />
        <line
          className='cls-1'
          x1='159.32'
          y1='309.11'
          x2='185.53'
          y2='309.11'
        />
        <line
          className='cls-1'
          x1='199.85'
          y1='269.96'
          x2='223.66'
          y2='269.96'
        />
        <path
          className='cls-1 less-stroke'
          d='M244.43,186.89h25.19s10.08-.51,10.08-10.21-12.89-8.94-12.89-8.94,2.55-11.1-8.81-12.76-10.5,13-10.5,13-10.69-3.45-11.84,8.55C235.66,176.55,234.81,185.11,244.43,186.89Z'
        />
        <path
          className='cls-1 less-stroke'
          d='M386.44,47.8h25.19s10.08-.51,10.08-10.21-12.89-8.94-12.89-8.94,2.55-11.1-8.81-12.76-10.5,13-10.5,13-10.69-3.45-11.84,8.55C377.67,37.46,376.82,46,386.44,47.8Z'
        />
        <path
          className='cls-1 less-stroke'
          d='M200.34,64h36.6s15.74-1.7,17.27-20.43c0,0-1.1-20.08-25.27-17.19,0,0-2.39-18-22.47-19.4s-21.62,19.91-21.62,19.91-24.17-5.44-25.53,18.39c0,0-.16,13.71,14.48,18.29a9.18,9.18,0,0,0,2.75.43h10.68'
        />
      </motion.g>
    </svg>
  )
}

export default AAgroD
