export type ModalWrapperPropsType = {
  isOpen: boolean;
  children: any;
}

const ModalWrapper = ({ isOpen, children }: ModalWrapperPropsType) => {
  return isOpen ? children : null;
};

export default ModalWrapper;
