import { useState, useCallback } from 'react';

import Fetch from 'utils/fetch';
import { ResponseType } from 'models';

import { POST } from 'constants/method';

type methodType = 'get' | 'post' | 'put' | 'delete' | 'upload';

const useFetchOnce = (
  initialData: any = {},
  method: methodType = POST,
  disableNotification = true,
) => {
  const [data, setData] = useState(initialData);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async (url: string, data?: any): Promise<ResponseType | undefined> => {
    if (!url) return;
    setIsLoading(true);
    hasError && setHasError(false);

    const response: ResponseType = await Fetch[method](url, data);
    if (response.ok && !response.hasError) {
      setData(response.data);
    } else {
      setData(initialData);
      !hasError && setHasError(true);
    }

    setIsLoading(false);
    return response;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableNotification, method]);

  return { data, fetchData, isLoading, setData, hasError };
};

export default useFetchOnce;
