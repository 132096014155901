export const costumerListColumns = [
  {
    Header: 'Type',
    accessor: 'customer_type',
  },
  {
    Header: 'Name',
    accessor: 'customer_name'
  },
  {
    Header: 'Email',
    accessor: 'customer_email',
  },
  // {
  //   Header: 'Green wave organization id',
  //   accessor: 'green_wave_organization_id',
  //   type: 'COPYABLE_TEXT',
  // },
  // {
  //   Header: 'Wave system account id',
  //   accessor: 'wave_system_account_id',
  //   type: 'COPYABLE_TEXT',
  // },
];
