import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { appKey } from 'configs';

export interface ITokenState {
  value: string | null,
}

const initialState: ITokenState = {
  value: localStorage.getItem(appKey) || null,
};

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      localStorage.setItem(appKey, JSON.stringify(action.payload));
      state.value = action.payload;
    },
    clearToken: (state) => {
      localStorage.clear();
      state.value = null;
    },
  },
});

export const { setToken, clearToken } = tokenSlice.actions;

export default tokenSlice.reducer;
