import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ISearchState {
  value: string | null;
  locations: any;
}

const initialState: ISearchState = {
  value: null,
  locations: null,
};

export const searchSlice = createSlice({
  name: "locationSearch",
  initialState,
  reducers: {
    setSearchInfoValue: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
    setLocationsValues: (state, action: PayloadAction<any>) => {
      state.locations = action.payload;
    },
  },
});

export const { setSearchInfoValue, setLocationsValues } = searchSlice.actions;

export default searchSlice.reducer;
