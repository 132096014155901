import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';

import Layout from 'layout';
import { ConfirmDialogProvider } from 'components/ConfirmDialog';

import { store } from 'store';

import 'styles/index.css';

import theme from 'styles/theme';

const App = () => (
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <ConfirmDialogProvider>
        <Layout />
      </ConfirmDialogProvider>
    </ChakraProvider>
  </Provider>
);

export default App;
