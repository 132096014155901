import LogoComponent from 'components/Logo';

import ForgotPass from './ForgotPass';
import SignIn from './SignIn';
import SignUp from './SignUp';
import SignUpOrganizations from './SignUpOrganizations';

import { LoginComponentStyle, SplashScreenStyle } from './styles';

const SplashScreen = ({ children, classname }: any): JSX.Element => {
  return (
    <SplashScreenStyle>
      <div className={classname}>
        <div className='splash-content'>
          <div className='splash-content-body'>
            <LogoComponent size='100px' light />
            <div className='splash-content-title'>
              <h4>Take your business to the next level</h4>
              <h4>with continuous condition</h4>
              <h4>monitoring data</h4>
            </div>
          </div>
        </div>
        <LoginComponentStyle>{children}</LoginComponentStyle>
      </div>
    </SplashScreenStyle>
  );
};

export const SignInPage = () => (
  <SplashScreen classname={'splash-screen-holder'}>
    <SignIn />
  </SplashScreen>
);

export const ForgotPassPage = () => (
  <SplashScreen classname={'splash-screen-holder'}>
    <ForgotPass />
  </SplashScreen>
);

export const SignUpPage = () => (
  <SplashScreen classname={'splash-screen-holder sign-up-content'}>
    <SignUp />
  </SplashScreen>
);

export const SignUpOrganizationPage = () => (
  <SplashScreen classname={'splash-screen-holder sign-up-content'}>
    <SignUpOrganizations />
  </SplashScreen>
);
