import { Route, Routes, Navigate, Outlet } from 'react-router-dom'

import { Users } from 'pages/Admin'
import WaveApp from 'pages/WaveApp'
import Dashboard from 'pages/Dashboard'
import Organizations from 'pages/Admin/Organizations'
import {
  SignInPage,
  SignUpOrganizationPage,
  SignUpPage,
  ForgotPassPage,
} from 'pages/Authorize'
import {
  HomePage,
  SolutionsPage,
  CareersPage,
  PartnersPage,
  WhoWeArePage,
} from 'pages/Home'
import { LocationsList, LocationsMap, Devices, Rooms, Details } from 'pages/Cmd'
import {
  DeviceTier,
  DeviceTierAssociations,
  ComponentList,
  DeviceEstimate,
  LineItems,
  ComparisonList,
  ServiceTier,
  ServiceTierAssociations,
  CustomerList,
  CalculateDetails,
  CustomerDetails,
} from 'pages/Dynamo'
import {
  Certificate,
  Policy,
  Group,
  Types,
  Billing,
  ThingsController,
} from 'pages/Thing'
import NotFound from 'pages/NotFound'

import Page from './Page'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

const PrivateRoute = ({ auth }: any) => {
  return <Page path={auth ? <Outlet /> : <Navigate to='/home' />} />
}

const AuthorizeRoute = ({ auth, isAdmin }: any) => {
  const homePage = isAdmin ? '/admin/users' : '/cmd/home'
  return auth ? <Navigate to={homePage} /> : <Outlet />
}

const MainRoutes = ({ auth }: any) => {
  const { roles } = useSelector((state: RootState) => ({
    user: state.user.value,
    userDetails: state.user.userDetails,
    roles: state.user.roles,
  }))

  const hasAccessToDynamo = roles.some(role =>
    ['ADMIN', 'SUPER_ADMIN'].includes(role)
  )

  const hasAccessToAdmin = roles.some(role =>
    [
      'ADMIN',
      'SUPER_ADMIN',
      'ORGANIZATION_ADMIN',
      'ORGANIZATION_OWNER',
    ].includes(role)
  )

  return (
    <Routes>
      <Route
        element={<AuthorizeRoute auth={auth} isAdmin={hasAccessToAdmin} />}
      >
        <Route path='/home' element={<HomePage />} />
        <Route path='/home/*' element={<HomePage />} />
        <Route path='/who-we-are' element={<WhoWeArePage />} />
        <Route path='/sign-in' element={<SignInPage />} />
        <Route path='/accounts/verify' element={<SignInPage />} />
        <Route path='/forgot-password' element={<ForgotPassPage />} />
        <Route path='/accounts/password/reset' element={<ForgotPassPage />} />
        {/* TODO:: Change redirect url */}
        <Route
          path='/organizations/accounts/register'
          element={<SignUpPage />}
        />
        <Route
          path='organizations/register'
          element={<SignUpOrganizationPage />}
        />
        <Route path='/sign-up' element={<SignUpPage />} />
        <Route path='/partners' element={<PartnersPage />} />
        <Route path='/careers' element={<CareersPage />} />
      </Route>
      <Route path='/solutions' element={<AuthorizeRoute auth={auth} />}>
        <Route path='/solutions' element={<SolutionsPage />} />
        <Route path='/solutions/*' element={<SolutionsPage />} />
      </Route>
      <Route element={<PrivateRoute auth={auth} />}>
        <Route path='/dashboard' element={<Dashboard />} />
        {hasAccessToAdmin && (
          <>
            <Route path='/admin/users' element={<Users />} />
            <Route path='/admin/organizations' element={<Organizations />} />
          </>
        )}
      </Route>
      <Route element={<PrivateRoute auth={auth} />}>
        <Route path='/cmd/home' element={<LocationsMap />} />
        <Route path='/cmd/locations' element={<LocationsList />} />
        <Route path='/cmd/rooms' element={<Rooms />} />
        <Route path='/cmd/devices' element={<Devices />} />
        <Route path='/cmd/device' element={<Details />} />
      </Route>
      <Route element={<PrivateRoute auth={auth} />}>
        <Route path='/thing/policy' element={<Policy />} />
        <Route path='/thing/group' element={<Group />} />
        <Route path='/thing/type' element={<Types />} />
        <Route path='/thing/certificate' element={<Certificate />} />
        <Route path='/thing/controller' element={<ThingsController />} />
        <Route path='/thing/billing' element={<Billing />} />
      </Route>
      <Route element={<PrivateRoute auth={auth} />}>
        <Route path='/waveapp' element={<WaveApp />} />
      </Route>
      {hasAccessToDynamo && (
        <Route element={<PrivateRoute auth={auth} />}>
          <Route path='/dynamo/tier/device-tier' element={<DeviceTier />} />
          <Route
            path='/dynamo/customer-tier-association/list-associations-for-cost-tier'
            element={<DeviceTierAssociations />}
          />
          <Route path='/dynamo/tier/service-tier' element={<ServiceTier />} />
          <Route
            path='/dynamo/customer-tier-association/list-associations-for-service-tier'
            element={<ServiceTierAssociations />}
          />
          <Route path='/dynamo/component-list' element={<ComponentList />} />
          <Route path='/dynamo/customer-list' element={<CustomerList />} />
          <Route
            path='/dynamo/customer-list/calculate'
            element={<CalculateDetails />}
          />
          <Route
            path='/dynamo/customer-list/details'
            element={<CustomerDetails />}
          />
          <Route path='/dynamo/comparison-list' element={<ComparisonList />} />
          <Route
            path='/dynamo/devices/device-estimate'
            element={<DeviceEstimate />}
          />
          <Route
            path='/dynamo/devices/device-estimate/line-items'
            element={<LineItems />}
          />
        </Route>
      )}
      <Route path='/' element={<Navigate to='/home' />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}

export default MainRoutes
