import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Center, Button, useDisclosure } from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { FiRefreshCcw, FiEye } from 'react-icons/fi';

import Section from 'components/Section';
import ActionBarWrap from 'components/ActionBar';
import ComboTable from 'components/Table/ComboTable';
import BreadcrumbWrapper from 'components/Breadcrumb';
import ModalWrapper from 'components/Modal';

import { DELETE, GET } from 'constants/method';
import { useTranslator } from 'utils/translator';
import { DEVICES, DEVICES_LIST } from 'constants/api';
import { useConfirmationDialog, useFetchOnce, useQuery } from 'utils/hooks';

import AddDevice from './AddDevice';

import { RowType } from '../../types';
import { devicesListColumns } from '../configs';

const Devices = () => {
  const { t } = useTranslator();
  const navigate = useNavigate();
  const {
    roomId,
    roomName,
    locationId,
    locationName,
  } = useQuery(['roomId', 'roomName', 'locationName', 'locationId']);
  const { getConfirmation } = useConfirmationDialog();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { fetchData: fetchOnce } = useFetchOnce({}, DELETE);
  const { data, fetchData, isLoading } = useFetchOnce({ items: [], totalCount: 0 }, GET);
  const { data: deviceInfo, setData, fetchData: fetchDeviceInfo } = useFetchOnce({}, GET);

  const getDevices = useCallback(() => {
    fetchData(`${DEVICES_LIST}/${roomId}?page=0&size=20`);
  }, [fetchData, roomId]);

  const handleOnDelete = async (id: string) => {
    const onDelete = async () => {
      const response = await fetchOnce(`${DEVICES}/${id}`);
      !response?.hasError && getDevices();
    };
    await getConfirmation(onDelete);
  };

  const handleOnEdit =  async (id: string) => {
    const response = await fetchDeviceInfo(`${DEVICES}/${id}`);
    !response?.hasError && onOpen();
  };

  useEffect(() => {
    if (roomId) {
      getDevices();
    }
  }, [getDevices, roomId]);

  const TitleComponent = useMemo(() => (
    <BreadcrumbWrapper items={[
      { name: t('_Locations_'), path: '/cmd/locations' },
      { name: locationName, path: '/cmd/locations' },
      { name: roomName, path: `/cmd/rooms?locationName=${locationName}&locationId=${locationId}` },
      { name: t('_Devices_') }
    ]}
    />
  ), [locationId, locationName, roomName, t]);

  const getActions = (row: RowType) => {
    const { original: { id, name } } = row;
    const navigateUrl = `/cmd/device?deviceId=${id}&deviceName=${name}&roomName=${roomName}&roomId=${roomId}&locationName=${locationName}&locationId=${locationId}&unitType=DEVICE&historical=TODAY`;
    
    return (
      <ActionBarWrap options={[
        {
          name: t('_Details_'),
          icon: <FiEye />,
          onClick: () => navigate(navigateUrl),
        },
        {
          name: t('_Edit_'),
          icon: <EditIcon />,
          onClick: () => handleOnEdit(id),
        },
        {
          name: t('_Delete_'),
          icon: <DeleteIcon />,
          onClick: () => handleOnDelete(id),
        },
      ]}
      />
    );
  };

  const handleCloseModal = (isUpdate: boolean) => {
    if (isUpdate) getDevices();
    setData(null);
    onClose();
  };

  return (
    <Section
      title={TitleComponent}
      actionBar={(
        <>
          <Button size="md" variant="primary" onClick={onOpen}>
            {t('_AddDevice_')}
          </Button>
          <Center height="40px" marginLeft="5px">
            <Divider orientation="vertical" />
          </Center>
          <Button
            size="sm"
            variant="secondary"
            onClick={getDevices}
          >
            <FiRefreshCcw />
          </Button>
        </>
      )}
    >
      <ComboTable
        columns={devicesListColumns}
        data={data?.items}
        isLoading={isLoading}
        getActions={getActions}
      />
      <ModalWrapper isOpen={isOpen}>
        <AddDevice selectedDevice={deviceInfo} visible={isOpen} closeModal={handleCloseModal} roomId={roomId} />
      </ModalWrapper>
      
    </Section>
  );
};

export default Devices;
