export const columns = [
  {
    Header: 'Company name',
    accessor: 'name',
  },
  {
    Header: 'First name',
    accessor: 'owner.user.firstName',
  },
  {
    Header: 'Last name',
    accessor: 'owner.user.lastName',
  },
  {
    Header: 'Phone',
    accessor: 'owner.user.phone',
  },
  {
    Header: 'Email',
    accessor: 'owner.user.email',
  },
];