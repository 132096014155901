export const columns = [
  {
    Header: 'Policy name',
    accessor: 'policyName',
    type: 'COPYABLE_TEXT'
  },
  {
    Header: 'Arn',
    accessor: 'policyArn',
    type: 'COPYABLE_TEXT'
  },
];