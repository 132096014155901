import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store';
import { LOCATIONS_LIST, ROOMS_LIST, DEVICES_LIST } from 'constants/api';

import MapList from './MapList';

type TabsProps = {
  request: string;
};

const config = {
  api: LOCATIONS_LIST,
  options: {
    api: ROOMS_LIST,
    options: {
      api: DEVICES_LIST,
    },
  },
};

const LocationList = ({ request }: TabsProps) => {
  
  const { search } = useSelector((state: RootState) => ({
    search: state.locationSearch.value,
  }));
  
  const searchQuery = useMemo(() => {
    const isNum = /^\d+$/.test(String(search));
    const sign = request ? '&' : '?';
    if (isNum && search) return `${sign}postalCode=${search}`;
    return search ? `${sign}name=${search}` : '';
  }, [request, search]);

  return (
    <>
      <MapList
        api={`${LOCATIONS_LIST}${`${request ? `?participantType=${request}` : ''}${searchQuery}`}`}
        options={config.options}
      />
    </>
  );
};

export default LocationList;