import { useForm, SubmitHandler } from 'react-hook-form';
import {
  Modal,
  Input,
  Button,
  Tooltip,
  ModalBody,
  FormLabel,
  ModalFooter,
  ModalHeader,
  FormControl,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';

import { ROOMS } from 'constants/api';
import { PUT, POST } from 'constants/method';
import { ERROR, SUCCESS } from 'constants/toaster';
import { useTranslator } from 'utils/translator';
import useFetchOnce from 'utils/hooks/useFetchOnce';

import { AddRoomPropsType, AddRoomType } from './types';

const AddRoom = ({ closeModal, locationId, defaultRoom }: AddRoomPropsType) => {
  const { t } = useTranslator();
  const toast = useToast();

  const { fetchData } = useFetchOnce({}, defaultRoom ? PUT : POST );

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddRoomType>();

  const handleCloseModal = (isUpdate?: boolean) => {
    reset();
    closeModal(isUpdate);
  };

  const addRoom = async (data: AddRoomType) => {
    if (errors && !Object.values(errors).length) {
      const response = await fetchData(`${ROOMS}${defaultRoom ? `?id=${defaultRoom.id}` : ''}`, { ...data, locationId });
      if (response?.hasError) {
        toast(ERROR);
      } else {
        toast(SUCCESS);
        handleCloseModal(true);
      }
    } else {
      toast({ ...ERROR, description: errors?.name?.message });
    }
  };

  const handleAddRoom: SubmitHandler<AddRoomType> = async (data: AddRoomType) => addRoom(data);

  return (
    <Modal
      isOpen
      onClose={handleCloseModal}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('_AddRoom_')}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handleAddRoom)}>
          <ModalBody pb={6}>
            <FormControl isInvalid={!!errors.name}>
              <FormLabel>{t('_Name_')}</FormLabel>
              <Tooltip label={errors?.name?.message}>
                <Input
                  placeholder={t('_Name_')}
                  {...register('name', {
                    required: true,
                  })}
                  defaultValue={defaultRoom?.name}
                />
              </Tooltip>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="primary" mr={3} type="submit">
              {t('_Submit_')}
            </Button>
            <Button onClick={() => handleCloseModal(false)}>{t('_Cancel_')}</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default AddRoom;
