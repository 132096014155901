import { useState } from 'react';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  InputGroup,
  Input,
  InputRightElement,
  Button,
  Flex,
  useToast,
  FormControl,
  FormErrorMessage,
  Stack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';

import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";

import { useTranslator } from 'utils/translator';
import { useFetchOnce } from 'utils/hooks';
import { ERROR, SUCCESS } from 'constants/toaster';

import { ILoginRequest } from './types';

import { SignInStyle } from './styles';
import { FORGOT, RESET } from 'constants/api';

const ForgotPass = () => {
  const toast = useToast();
  const { t } = useTranslator();

  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get('accountId');
  const verificationTokenId = searchParams.get('verificationTokenId')

  const [show, setShow] = useState(false);

  const formSchemaPass = yup.object().shape({
    password: yup.string()
      .required("Password is required")
      .min(8, "Password length should be at least 8 characters")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&,^*?])[A-Za-z\d#$@!%&,^*?]{8,30}$/, 'at least one Uppercase, lowercase, number and special character'),
    reppassword: yup.string()
      .required("Repeat Password is required")
      .min(8, "Password length should be at least 8 characters")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&,^*?])[A-Za-z\d#$@!%&,^*?]{8,30}$/, 'at least one Uppercase, lowercase, number and special character')
      .oneOf([yup.ref("password")], "Passwords do not match")
  });
  const formSchemaUser = yup.object().shape({
    username: yup.string()
      .required("Username is required"),
  });
  const usedSchema = accountId ? formSchemaPass : formSchemaUser
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginRequest>(
      {
      resolver: yupResolver(usedSchema)
    });

  const { fetchData } = useFetchOnce();
  const handleClick = () => setShow(!show);

  const handleForgot: SubmitHandler<ILoginRequest> = async (
    data: ILoginRequest,
  ) => {
    if (errors && !Object.values(errors).length) {
      if(!accountId){
        const response = await fetchData(`${FORGOT}?userName=${data.username}`);
        if(response?.ok){
          toast(SUCCESS);
          setTimeout(()=>{
            navigate('/home');
          }, 3000
          )
        } else toast(ERROR);
      }else{
        const resetData = {
            accountId,
            matchingPassword: data?.reppassword,
            password: data?.password,
            accessToken: verificationTokenId,
            type: "ACCESS_TOKEN"
        }
        const response = await fetchData(RESET, resetData);
        if(response){
          toast(SUCCESS);
          navigate('/sign-in');
        } else toast(ERROR);
      }
    }
  };

  return (
    <SignInStyle>
      <form onSubmit={handleSubmit(handleForgot)}>
        <Stack spacing={6} maxWidth='450px'>
          {!accountId && <FormControl isInvalid={errors?.username?.message ? true : false}>
            <InputGroup size='lg'>
              <Input
                pr='4.5rem'
                type='text'
                placeholder='Username'
                {...register('username', {
                  required: t('_ErrorFill_'),
                })}
              />
            </InputGroup>
            <FormErrorMessage>{errors?.username?.message || ''}</FormErrorMessage>
          </FormControl>}
          {accountId && <FormControl isInvalid={errors?.password?.message ? true : false}>
            <InputGroup size='lg'>
              <Input
                pr='4.5rem'
                type={show ? 'text' : 'password'}
                placeholder='New password'
                {...register('password')}
              />
              <InputRightElement width='4.5rem'>
                <Button h='1.75rem' size='sm' onClick={handleClick}>
                  <ViewIcon />
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors?.password?.message || ''}</FormErrorMessage>
          </FormControl>}
          {accountId && <FormControl isInvalid={errors?.reppassword?.message ? true : false}>
            <InputGroup size='lg'>
              <Input
                pr='4.5rem'
                type={show ? 'text' : 'password'}
                placeholder='Repeat password'
                {...register('reppassword')}
              />
              <InputRightElement width='4.5rem'>
                <Button h='1.75rem' size='sm' onClick={handleClick}>
                  <ViewIcon />
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors?.reppassword?.message || ''}</FormErrorMessage>
          </FormControl>}
          <Popover placement='right'>
            <PopoverTrigger>
            <Button
              type='submit'
              size='md'
              variant='primary'
              height='48px'
              width='100%'
              border='2px'
              color='#fff'
              background='#00AB39'
              borderColor='green.500'
              margin='30px 0'
              _hover={{ bg: 'brand.600', scale: 0.98 }}
            >
              {accountId ? 'Reset Password': 'Continue'}
            </Button>
            </PopoverTrigger>
            {!accountId && <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Reset Password!</PopoverHeader>
              <PopoverBody>Please check your email for password reset</PopoverBody>
            </PopoverContent>}
          </Popover>
          <Flex color='#00AB39' flexDirection='row' justifyContent='center'>
              <Link to='/sign-in'>back to Login</Link>
          </Flex>
        </Stack>
      </form>
    </SignInStyle>
  );
};

export default ForgotPass;

