import Section from 'components/Section';
import ComboTable from 'components/Table/ComboTable';

import { useTranslator } from 'utils/translator';
import { useFetchOnce, useQuery } from 'utils/hooks';

import { costumerListColumns } from './associationsConfigs';
import { useCallback, useEffect, useMemo } from 'react';
import { CUSTOMER_TIER_ASSOCIATION } from 'constants/api';
import BreadcrumbWrapper from 'components/Breadcrumb';

const CustomerList = () => {
  const { t } = useTranslator();
  const { deviceCostTierId, tiersForDevice } = useQuery(['deviceCostTierId', 'tiersForDevice']);

  const { data, fetchData, isLoading } = useFetchOnce([]);

  const getCustomers = useCallback(() => {
    fetchData(`${CUSTOMER_TIER_ASSOCIATION}/list-associations-for-cost-tier?deviceCostTierId=${deviceCostTierId}`);
  }, [fetchData, deviceCostTierId]);

  useEffect(() => {
    if (deviceCostTierId) {
      getCustomers();
    }
  }, [getCustomers, deviceCostTierId]);

  const TitleComponent = useMemo(() => (
    <BreadcrumbWrapper items={[
      { name: t('_TiersForDevice_'), path: '/dynamo/tier/device-tier' },
      { name: tiersForDevice },
      { name: t('_CustomerList_') }]}
    />
  ), [tiersForDevice, t]);
  
  return (
    <Section  title={TitleComponent}>
      <ComboTable
        columns={costumerListColumns}
        data={data}
        isLoading={isLoading}
      />
    </Section>
  );
};

export default CustomerList;
