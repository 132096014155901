export const tabsInfo = [
  {
    name: 'Public',
    request: '',
  },
  {
    name: 'Created by me',
    request: 'OWNER',
  },
  {
    name: 'My favorites',
    request: 'FOLLOWER',
  },
];

export const scrollbarCss = {
  '&::-webkit-scrollbar': {
    height: '4px',
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    height: '4px',
    width: '2px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'gray',
    borderRadius: '3px',
  },
}
