import { ColumnType } from 'models';

export const columns: ColumnType[] = [
  {
    Header: 'First name',
    accessor: 'user.firstName',
    type: 'TEXT',
  },
  {
    Header: 'Last name',
    accessor: 'user.lastName',
    type: 'TEXT',
  },
  {
    Header: 'Phone',
    accessor: 'user.phone',
    type: 'TEXT',
  },
  {
    Header: 'Email',
    accessor: 'user.email',
    type: 'TEXT',
  },
  {
    Header: 'Status',
    accessor: 'suspended',
    type: 'SUSPEND_STATUS',
  },
];