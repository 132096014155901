export const backgroundColor = '#040D2F'
export const green = '#00AB39'
export const greenHover = '#16b54b'

export const adminBackgroundColor = '#f5f5f5'

export const borderRadius = '20%/50%'
export const borderColor = 'rgba(0, 0, 0, 0.1)'

export const hoverColor = '#22C35E'
