import {
  LOGIN,
  // REGISTER, // TODO:: check API
  CHECK_EMAIL,
  VERIFICATION_TOKEN,
  ORGANIZATIONS_ACCOUNT_REGISTER,
  ORGANIZATIONS_REGISTER,
  VERIFICATION_ACCOUNT,
} from 'constants/api';

import { ResponseType } from 'models';

import {
  ILoginRequest,
  ILoginResponse,
  ISignUpRequest,
  ICheckEmailResponse,
  IVerificationTokeResponse,
  ISignUpOrganizationRequest,
} from './types';


const responseRestructure = async (response: Response): Promise<ResponseType> => {
  if (response.ok) {
    try {
      const data = await response.json();
      return { data, status: response.status, ok: response.ok, hasError: false };
    } catch (error) {
      return { data: null, status: response.status, ok: response.ok, hasError: false };
    }
  }
  return { data: null, status: response.status, ok: response.ok, hasError: true };
};

export const login = async (body: ILoginRequest): Promise<ILoginResponse> => {
  const options: Object = {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authentication': null,
    },
    body: JSON.stringify(body),
  };

  const response = await fetch(LOGIN, options);
  const data: ILoginResponse = await response.json();

  return data;
};

export const registerAccount = async (verificationTokenId: string | null, organizationId: string | null, body: ISignUpRequest): Promise<ResponseType> => {
  const options: Object = {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      verificationTokenId,
    },
    body: JSON.stringify(body),
  };

  const response = await fetch(ORGANIZATIONS_ACCOUNT_REGISTER(organizationId), options);
  return responseRestructure(response);
};

export const registerOrganization = async (verificationTokenId: string | null, body: ISignUpOrganizationRequest): Promise<ResponseType> => {
  const options: Object = {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      verificationTokenId,
    },
    body: JSON.stringify(body),
  };

  const response = await fetch(ORGANIZATIONS_REGISTER, options);
  return responseRestructure(response);
};

export const verificationToken = async (token: string): Promise<IVerificationTokeResponse> => {
  const options: Object = {
    mode: 'cors',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authentication': null,
      verificationTokenId: token,
    },
  };

  const response = await fetch(VERIFICATION_TOKEN, options);
  const data: IVerificationTokeResponse = await response.json();

  return data;
};

export const verificationAccount = async (token: string | null, accountId: string | null): Promise<ResponseType> => {
  const options: Object = {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authentication': null,
      verificationTokenId: token,
    },
    body: {},
  };

  const response = await fetch(VERIFICATION_ACCOUNT(accountId), options);
  return responseRestructure(response);;
};

export const checkEmail = async (email: string): Promise<ICheckEmailResponse> => {
  const options: Object = {
    mode: 'cors',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authentication': null,
    },
  };

  const response = await fetch(`${CHECK_EMAIL}?email=${email}`, options);
  const data: ICheckEmailResponse = await response.json();

  return data;
};
