import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import Search from 'components/Search';

import LocationList from 'pages/Cmd/LocationsMap/LocationList';

import { scrollbarCss } from './configs';

type TabsProps = {
  tabsInfo: Array<object>;
  tabIndex: number;
  setTabIndex: Function;
};

const TabsContent = ({ tabsInfo, tabIndex, setTabIndex }: TabsProps) => {

  return (
    <Tabs
      index={tabIndex}
      onChange={index => setTabIndex(index)}
      background='#F7F9FA 0% 0% no-repeat padding-box'
      padding='2'
      borderRadius='10px'
      colorScheme='green'
      overflowY='scroll'
      maxHeight='64vh'
      className='table-wrapper'
      pos="relative"
      css={scrollbarCss}
    >
      <TabList w='100%' padding='2'>
        {tabsInfo.map((tab: any, index) => (
          <Tab key={index} _focus={{ ringColor: 'none' }}>{tab.name}</Tab>
        ))}
      </TabList>
      <Search />
      <TabPanels paddingTop='2'>
        {tabsInfo.map((tab: any, index) => (
          <TabPanel padding='0' key={index}>
            {index === tabIndex && <LocationList request={tab.request} />}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default TabsContent;
