import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
} from '@chakra-ui/react';

import { RootState } from 'store';

import Header from './Header';
import SidebarContent from './Sidebar';

interface IPage {
  path: ReactNode;
}

const Page = ({ path }: IPage) => {
  const { userDetails } = useSelector((state: RootState) => ({
    user: state.user.value,
    userDetails: state.user.userDetails,
  }));

  const { isOpen,onOpen,onClose } = useDisclosure();
  
  return (
    <Box
      width={"100%"}
      minH="100vh"
      bg={useColorModeValue("gray.100", "gray.900")}
    >
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <Header onOpened={onOpen} user={userDetails} />
      <Box
        style={{ overflowX: 'auto', height: '90%' }}
        ml={{ base: 0, md: 60 }}
        p="4"
      >
        {path}
      </Box>
    </Box>
  );
};

export default Page;
