import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Section from 'components/Section';
import ComboTable from 'components/Table/ComboTable';

import { RootState } from 'store';
import { useTranslator } from 'utils/translator';

import { tierListColumns } from './DeviceTier/configs';

const ComparisonList = () => {
  const { t } = useTranslator();
  const navigate = useNavigate();

  const { selectedTiers } = useSelector((state: RootState) => ({
    selectedTiers: state.selectedTiers.value,
  }));

  const { data, columns } = useMemo(() => {
    const [firstColum, ...restColumns] = tierListColumns;
    const columns = restColumns.map(item => ({ [firstColum.accessor]: item.Header }));
    const data = columns.map(item => {
      const currentColum = tierListColumns.find(y => y.Header === item[firstColum.accessor]);
      const row = selectedTiers?.reduce((acc, cur, curIndex) => {
        return { ...acc, [String(curIndex)]: currentColum && cur[currentColum?.accessor] };
      }, {});
      return { ...item, ...row };
    });

    const currentColumns = selectedTiers
      ? selectedTiers.map((item, index) => ({ Header: item[firstColum.accessor], accessor: String(index) }))
      : [];

    return {
      data,
      columns: [firstColum, ...currentColumns],
    };
  }, [selectedTiers]);

  useEffect(() => {
    if (!selectedTiers?.length) {
      navigate('/dynamo/tier/device-tier');
    }
  }, [navigate, selectedTiers]);

  return (
    <Section
      title={t('_ComparisonList_')}
    >
      <ComboTable
        data={data}
        columns={columns}
      />
    </Section>
  );
};

export default ComparisonList;
