import { uuid } from 'utils';

// import { ImStack } from 'react-icons/im';
// import { BiQrScan } from 'react-icons/bi';
// import { BsCashCoin } from 'react-icons/bs';
// import { FiTrendingUp } from 'react-icons/fi';
import { SiSmartthings } from 'react-icons/si';
// import { IoSettingsOutline } from 'react-icons/io5';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { VscGroupByRefType, VscUngroupByRefType } from 'react-icons/vsc'; // VscTypeHierarchy
import { RiDashboardLine, RiAdminLine, RiOrganizationChart, RiBillLine, RiTeamLine } from 'react-icons/ri';
import { AiOutlineUserSwitch, AiOutlineSafetyCertificate } from 'react-icons/ai'; // AiOutlineHome
import { MdOutlineControlCamera, MdOutlinePolicy, MdOutlineChecklist } from 'react-icons/md'; // MdDeviceHub



// ADMIN("ADMIN"),
// ORGANIZATION_ADMIN("ORGANIZATION_ADMIN"),
// ORGANIZATION_OWNER("ORGANIZATION_OWNER"),
// SUPER_ADMIN("SUPER_ADMIN"),
// USER("USER");



export const MenuData = (roles: string[]) => {

   const menuList = {
    user: [
      // {
      //   name: 'Home',
      //   href: '/home',
      //   id: uuid(),
      //   icon: AiOutlineHome,
      // },
      {
        name: 'Dashboards',
        id: uuid(),
        icon: RiDashboardLine,
        subNavigation: [
          // {
          //   name: 'LED dashboard',
          //   href: '/led',
          //   id: uuid(),
          //   icon: '',
          // },
          // {
          //   name: 'GPS dashboard',
          //   href: '/gps',
          //   id: uuid(),
          //   icon: '',
          // },
          {
            name: 'CMD dashboard',
            href: '/cmd',
            id: uuid(),
            icon: '',
            subNavigation: [
              {
                href: '/cmd/home',
                name: 'CMD Home',
                id: uuid(),
                icon: RiOrganizationChart,
              },
              {
                href: '/cmd/locations',
                name: 'Locations',
                id: uuid(),
                icon: MdOutlineChecklist,
              },
            ],
          },
        ],
      },
      {
        name: 'Admin',
        id: uuid(),
        icon: RiAdminLine,
        isHidden: !roles.some((role) => ['ADMIN', 'SUPER_ADMIN', 'ORGANIZATION_ADMIN', 'ORGANIZATION_OWNER'].includes(role)),
        subNavigation: [
          {
            href: '/admin/organizations',
            name: 'Organizations',
            id: uuid(),
            icon: RiOrganizationChart,
          },
          {
            href: '/admin/users',
            name: 'Users',
            id: uuid(),
            icon: AiOutlineUserSwitch,
          },
        ],
      },
      // {
      //   name: 'Device',
      //   href: '/device',
      //   id: uuid(),
      //   icon: MdDeviceHub,
      // },
      // {
      //   name: 'Billing',
      //   href: '/billing',
      //   id: uuid(),
      //   icon: BsCashCoin,
      // },
      {
        name: 'WaveApp',
        href: '/waveapp',
        id: uuid(),
        icon: FaFileInvoiceDollar,
      },
      {
        name: 'Thing services',
        href: '/services',
        id: uuid(),
        icon: SiSmartthings,
        subNavigation: [
          {
            href: '/thing/controller',
            name: 'Thing Controller',
            id: uuid(),
            icon: MdOutlineControlCamera,
          },
          {
            href: '/thing/certificate',
            name: 'Certificate',
            id: uuid(),
            icon: AiOutlineSafetyCertificate,
          },
          {
            href: '/thing/billing',
            name: 'Billing Group',
            id: uuid(),
            icon: RiBillLine,
          },
          {
            href: '/thing/group',
            name: 'Thing Group',
            id: uuid(),
            icon: VscGroupByRefType,
          },
          {
            href: '/thing/policy',
            name: 'Policy',
            id: uuid(),
            icon: MdOutlinePolicy,
          },
          {
            href: '/thing/type',
            name: 'Type',
            id: uuid(),
            icon: VscUngroupByRefType,
          },
        ],
      },
      // {
      //   name: 'RGU',
      //   href: '/rgu',
      //   id: uuid(),
      //   icon: VscTypeHierarchy,
      // },
      // {
      //   name: 'QR codes',
      //   href: '/qr',
      //   id: uuid(),
      //   icon: BiQrScan,
      // },
      // {
      //   name: 'Coast estimate',
      //   href: '/estimate',
      //   id: uuid(),
      //   icon: ImStack,
      // },
      // {
      //   name: 'Reports',
      //   href: '/reports',
      //   id: uuid(),
      //   icon: FiTrendingUp,
      // },
      // {
      //   name: 'Settings',
      //   href: '/settings',
      //   id: uuid(),
      //   icon: IoSettingsOutline,
      // },
      {
        name: 'Dynamo',
        href: '/dynamo',
        id: uuid(),
        icon: RiOrganizationChart,
        isHidden:!roles.some((role) => ['ADMIN', 'SUPER_ADMIN'].includes(role)),
        subNavigation: [
          {
            href: '/dynamo/tiers',
            name: 'Tiers',
            id: uuid(),
            icon: RiOrganizationChart,
            subNavigation: [
              {
                href: '/dynamo/tier/device-tier',
                name: 'Tiers for Device',
                id: uuid(),
                icon: RiOrganizationChart,
              },
              {
                href: '/dynamo/tier/service-tier',
                name: 'Tiers for Service',
                id: uuid(),
                icon: RiOrganizationChart,
              },
            ],
          },
          {
            href: '/dynamo/devices',
            name: 'Devices',
            id: uuid(),
            icon: RiOrganizationChart,
            subNavigation: [
              {
                href: '/dynamo/devices/device-estimate',
                name: 'Device Estimate',
                id: uuid(),
                icon: RiOrganizationChart,
              },
            ],
          },
          {
            href: '/dynamo/component-list',
            name: 'Component List',
            id: uuid(),
            icon: RiOrganizationChart,
          },
          {
            href: '/dynamo/customer-list',
            name: 'Customer List',
            id: uuid(),
            icon: RiTeamLine,
          },
        ],
      },
    ],
  };


  return menuList || {}
};