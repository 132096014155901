export const columns = [
  {
    Header: 'Thing Arn',
    accessor: 'thingArn',
    type: 'COPYABLE_TEXT',
  },
  {
    Header: 'Thing Name',
    accessor: 'thingName',
    type: 'COPYABLE_TEXT',
  },
  {
    Header: 'Thing Type Name',
    accessor: 'thingTypeName',
    type: 'COPYABLE_TEXT',
  },
  {
    Header: 'Version',
    accessor: 'version',
  },
];