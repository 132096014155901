import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  InputGroup,
  Input,
  Button,
  Flex,
  Stack,
  Text,
  HStack,
  useToast,
  FormControl,
  FormErrorMessage,
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner
} from '@chakra-ui/react';

import { yupResolver } from "@hookform/resolvers/yup";

import { ResponseType } from 'models';
import { SUCCESS, ERROR } from 'constants/toaster';

import {
  ISignUpRequest,
  ICheckEmailResponse,
  IVerificationTokeResponse,
} from './types';
import { checkEmail, registerAccount, verificationToken } from './utils';

import { SignUpSchema, SignUpExistingUserSchema } from './FormScheams';

import { SignInStyle } from './styles';

const existing = 'EXISTING';
const nonExisting = 'NON_EXISTING';

const SignUp = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState<string>('');
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const [type, setType] = useState<string>(nonExisting);
  const [verificationTokenId, setVerificationTokenId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const Schema = type === 'EXISTING' ? SignUpExistingUserSchema :  SignUpSchema;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISignUpRequest>({resolver: yupResolver(Schema)});

  const checkVerificationToken = useCallback(async (token: string) => {
    const response: IVerificationTokeResponse = await verificationToken(token);
    if (response?.email) {
      const data: ICheckEmailResponse = await checkEmail(response.email);
      const type: string = data.status === 'NOT_FOUND' ? nonExisting : existing;
      setEmail(response.email);
      setType(type);
      setLoading(false);
    } else {
      toast({ ...ERROR, description: response.message });
    }
  }, [toast]);

  useEffect(() => {
    const token: string | null = searchParams.get('verificationTokenId');
    checkVerificationToken(token || '');
    setVerificationTokenId(token);
    setOrganizationId(searchParams.get('organizationId'));
  }, [checkVerificationToken, searchParams]);

  const handleSingUp: SubmitHandler<ISignUpRequest> = async (
    data: ISignUpRequest,
  ) => {
    if (errors && !Object.values(errors).length) {
      const response: ResponseType = await registerAccount(
        verificationTokenId,
        organizationId,
        {
          ...data,
          ...(type !=='EXISTING' && {user: {
            firstName: data.firstName,
            lastName: data.lastName,
            email,
            phone: data.phone,
          },
        }),
        ...(type === 'EXISTING' && { email }),
        type,
        }
      );
      if (response?.ok) {
        toast(SUCCESS);
        setTimeout(()=>{
          navigate('/home');
        }, 3000);
      } else {
        toast(ERROR);
      }
    } else {
      toast(ERROR);
    }
  };
  if (loading) return <Spinner
  thickness="4px"
  speed="0.65s"
  emptyColor="green.200"
  color="green.500"
  size="xl"
/>;
  
  return (
    <SignInStyle>
      <form onSubmit={handleSubmit(handleSingUp)}>
        <Stack spacing={4} maxWidth='450px'>
          {type !== 'EXISTING' && <HStack className='rp-sign-up'>
            <FormControl isInvalid={!!errors?.firstName?.message}>
              <FormErrorMessage>{errors?.firstName?.message}</FormErrorMessage>
              <InputGroup size='lg'>
                <Input
                  pr='4.5rem'
                  type='text'
                  placeholder='First name *'
                  {...register('firstName')}
                />
              </InputGroup>
            </FormControl>
            <FormControl className='rp-inp' isInvalid={!!errors?.lastName?.message}>
              <FormErrorMessage>{errors?.lastName?.message}</FormErrorMessage>
              <Box>
                <InputGroup size='lg' >
                  <Input
                    pr='4.5rem'
                    type='text'
                    placeholder='Last name *'
                    {...register('lastName')}
                  />
                </InputGroup>
              </Box>
            </FormControl>
          </HStack>}
          {type !== 'EXISTING' && <FormControl isInvalid={!!errors?.phone?.message}>
            <FormErrorMessage>{errors?.phone?.message || ''}</FormErrorMessage>
            <InputGroup size='lg'>
              <Input
                pr='4.5rem'
                type='text'
                placeholder='Phone *'
                {...register('phone')}
              />
            </InputGroup>
          </FormControl>}
          <FormControl isInvalid={!!errors?.userName?.message}>
            <FormErrorMessage>{errors?.userName?.message || ''}</FormErrorMessage>
            <InputGroup size='lg'>
              <Input
                pr='4.5rem'
                type='text'
                placeholder='User name'
                {...register('userName')}
              />
            </InputGroup>
          </FormControl>
          <FormControl isInvalid={!!errors?.password?.message}>
            <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
            <InputGroup size='lg'>
              <Input
                pr='4.5rem'
                type='password'
                placeholder='Password *'
                {...register('password')}
              />
            </InputGroup>
          </FormControl>
          <FormControl isInvalid={!!errors?.matchingPassword?.message}>
            <FormErrorMessage>{errors?.matchingPassword?.message}</FormErrorMessage>
            <InputGroup size='lg'>
              <Input
                pr='4.5rem'
                type='password'
                placeholder='Confirm password *'
                {...register('matchingPassword')}
              />
            </InputGroup>
          </FormControl>
          <Popover placement='right'>
            <PopoverTrigger>
              <Button
                type='submit'
                size='md'
                variant='primary'
                height='48px'
                width='100%'
                border='2px'
                color='#fff'
                background='#00AB39'
                borderColor='green.500'
                margin='30px 0'
                _hover={{ bg: 'brand.600', scale: 0.98 }}
              >
                Sign up
              </Button>
            </PopoverTrigger>
            {!Object.keys(errors).length && <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>VERIFICATION REQUIRED!</PopoverHeader>
              <PopoverBody>Please check your email for verification after pressing Sign up  Button</PopoverBody>
            </PopoverContent>}
          </Popover>
        </Stack>
        <Flex justifyContent='center'>
          <Link to='/sign-in'>
            <Text
              p={2}
              mt={2}
              fontSize='14px'
              color='gray.600'
              _hover={{ color: 'blue.900', fontWeight: 600 }}
            >
              Already have an account?
            </Text>
          </Link>
        </Flex>
      </form>
    </SignInStyle>
  );
};

export default SignUp;
