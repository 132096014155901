import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Center, Button, useDisclosure } from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { FiRefreshCcw } from 'react-icons/fi';
import { MdRoomPreferences } from 'react-icons/md';

import Section from 'components/Section';
import ModalWrapper from 'components/Modal';
import ActionBarWrap from 'components/ActionBar';
import ComboTable from 'components/Table/ComboTable';
import BreadcrumbWrapper from 'components/Breadcrumb';

import { DELETE, GET } from 'constants/method';
import { useTranslator } from 'utils/translator';
import { ROOMS, ROOMS_LIST } from 'constants/api';
import { useConfirmationDialog, useFetchOnce, useQuery } from 'utils/hooks';

import AddRoom from './AddRoom';

import { RowType } from '../../types';
import { roomsListColumns } from '../configs';

const Rooms = () => {
  const { t } = useTranslator();
  const navigate = useNavigate();
  const { locationId, locationName } = useQuery(['locationId', 'locationName']);
  const { getConfirmation } = useConfirmationDialog();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { fetchData: fetchOnce } = useFetchOnce({}, DELETE);
  const { data, fetchData, isLoading } = useFetchOnce({ items: [], totalCount: 0 }, GET);

  const { data: roomInfo, setData, fetchData: fetchRoomInfo } = useFetchOnce({}, GET);
  
  const getRooms = useCallback(() => {
    fetchData(`${ROOMS_LIST}/${locationId}?page=0&size=20`);
  }, [fetchData, locationId]);

  const handleOnDelete = async (id: string) => {
    const onDelete = async () => {
      const response = await fetchOnce(`${ROOMS}?id=${id}`);
      !response?.hasError && getRooms();
    };
    await getConfirmation(onDelete);
  };

  const handleOnEdit =  async (id: string) => {
    const response = await fetchRoomInfo(`${ROOMS}/${id}`);
    !response?.hasError && onOpen();
  };

  useEffect(() => {
    if (locationId) {
      getRooms();
    }
  }, [getRooms, locationId]);

  const getActions = (row: RowType) => {
    const { original: { id, name } } = row;
    const navigateUrl = `/cmd/devices?roomId=${id}&roomName=${name}&locationName=${locationName}&locationId=${locationId}`;
    return (
      <ActionBarWrap options={[
        {
          name: t('_Edit_'),
          icon: <EditIcon />,
          onClick: () => handleOnEdit(id),
        },
        {
          name: t('_Devices_'),
          icon: <MdRoomPreferences />,
          onClick: () => navigate(navigateUrl),
        },
        {
          name: t('_Delete_'),
          icon: <DeleteIcon />,
          onClick: () => handleOnDelete(id),
        },
      ]}
      />
    );
  };

  const handleCloseModal = (isUpdate: boolean) => {
    if (isUpdate) getRooms();
    setData(null);
    onClose();
  };

  const TitleComponent = useMemo(() => (
    <BreadcrumbWrapper items={[
      { name: t('_Locations_'), path: '/cmd/locations' },
      { name: locationName, path: '/cmd/locations' },
      { name: t('_Rooms_') }]}
    />
  ), [locationName, t]);

  return (
    <Section
      title={TitleComponent}
      actionBar={(
        <>
          <Button size="md" variant="primary" onClick={onOpen}>
            {t('_AddRoom_')}
          </Button>
          <Center height="40px" marginLeft="5px">
            <Divider orientation="vertical" />
          </Center>
          <Button
            size="sm"
            variant="secondary"
            onClick={getRooms}
          >
            <FiRefreshCcw />
          </Button>
        </>
      )}
    >
      <ComboTable
        columns={roomsListColumns}
        data={data?.items}
        isLoading={isLoading}
        getActions={getActions}
      />
      <ModalWrapper isOpen={isOpen}>
        <AddRoom defaultRoom={roomInfo} closeModal={handleCloseModal} locationId={locationId} />
      </ModalWrapper>
    </Section>
  );
};

export default Rooms;
