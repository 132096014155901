import { Box, Flex, IconButton, Image, Spacer, Stat, StatLabel, StatNumber } from '@chakra-ui/react';

import descIcons from 'constants/chartIcons';
import { useTranslator } from 'utils/translator';

import { CmdButtonsProps } from './types';

function CmdButtons({ measure, selectedIcon, handleSelectedIcon }: CmdButtonsProps) {
  const { t } = useTranslator();
  return (
    <Flex direction='column'>
      {descIcons?.map(({ icon, key, label, unit }) => (
        <Stat key={key}
          bgColor={selectedIcon === key ? '#CAECFC' : 'brand.100'}
          borderRadius='10px' p='2' mb='3'
          onClick={() => handleSelectedIcon(key)}
        >
          <Flex align='center' justifyContent='space-between'>
            <Box>
              <StatLabel fontWeight='700'>{t(`_${label}_`)}</StatLabel>
              <StatNumber>{measure[key] || 0} {unit}</StatNumber>
            </Box>
            <Spacer />
            <IconButton
              colorScheme='whatsapp'
              variant='outline'
              aria-label='Call Sage'
              borderRadius='full'
            >
              <Image
                boxSize='32px'
                objectFit='contain'
                p='0.5'
                src={icon}
                alt={label}
              />
            </IconButton>
          </Flex>
        </Stat>
      ))}
    </Flex>
  );
}

export default CmdButtons;
