import { configureStore } from '@reduxjs/toolkit'

import userSlice from './features/user';
import tokenSlice from './features/token';
import selectedTiersSlice from './features/selectedTiers';
import locationSearchSlice from './features/locationSearch';

export const store = configureStore({
  reducer: {
    user: userSlice,
    token: tokenSlice,
    selectedTiers: selectedTiersSlice,
    locationSearch: locationSearchSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
