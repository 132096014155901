import { useEffect, useState, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  useToast,
  Button,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Tooltip,
  Select,
  Flex,
  Box,
  Text
} from '@chakra-ui/react';

import BreadcrumbWrapper from 'components/Breadcrumb';
import Section from 'components/Section';

import { uuid } from 'utils';
import { useTranslator } from 'utils/translator';
import { useFetchOnce, useFetchData, useQuery } from 'utils/hooks';
import { POST } from 'constants/method';
import { ERROR, SUCCESS } from 'constants/toaster';
import { CUSTOMER_TIER_ASSOCIATION, DEVICE_ESTIMATE } from 'constants/api';

import { EstimateType } from './types';
import TierTable from './TierTable';
import CalcResultTable from './CalcResultTable'

const CalculateDetails = () => {
  const { t } = useTranslator();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const [hardChecked, setHardChecked] = useState(true)
  const [servChecked, setServChecked] = useState(false)
  const [calcResult, setCalcResult] = useState('')

  const { orgName } = useQuery(['orgName']);
  const { fetchData, isLoading } = useFetchOnce({}, POST);
  const { data: customerDetails, fetchData:fetchDetails } = useFetchOnce({}, POST);
  const { data: deviceList } = useFetchData({ content: [] }, `${DEVICE_ESTIMATE}`);

  useEffect(()=>{
    fetchDetails(`${CUSTOMER_TIER_ASSOCIATION}/customer-details?organizationName=${orgName}`);
  },[fetchDetails, orgName])

  const calculate = async (data: EstimateType) => {
    const bothSelected = data?.qty && data?.freq && hardChecked && servChecked
    const onlyHardware = data?.qty && hardChecked
    const onlyService = data?.freq && servChecked
    const url: string = bothSelected 
      ? `${CUSTOMER_TIER_ASSOCIATION}/estimate-device-and-service-for-customer?deviceEstimateId=${data.device_name}&freq=${data.freq}&organizationName=${orgName}&qty=${data.qty}`
      : onlyHardware ? `${CUSTOMER_TIER_ASSOCIATION}/estimate-device-cost-for-customer?deviceEstimateId=${data.device_name}&organizationName=${orgName}&qty=${data.qty}`
      : onlyService ? `${CUSTOMER_TIER_ASSOCIATION}/estimate-device-service-for-customer?deviceEstimateId=${data.device_name}&organizationName=${orgName}&freq=${data.freq}`:'';
    const response = await fetchData(url);
    if (response?.ok) {
      setCalcResult(response.data)
      toast(SUCCESS);
    } else {
      toast(ERROR);
    }
  };

  const TitleComponent = useMemo(() => (
    <BreadcrumbWrapper items={[
      { name: t('_Dynamo_') },
      { name: t('_Customer_'), path: '/dynamo/customer-list' },
      { name: orgName },
    ]}
    />
  ), [orgName, t]);

  const handleCalculate: SubmitHandler<EstimateType> = async (
    data: EstimateType
  ) => calculate(data);
  const deviceOptions = deviceList ? deviceList?.content?.map(({ device_name, id }:any) => ({ label: device_name, value: id })) : [];

  return (
    <Section title={TitleComponent}>
      <Box padding={4}>
        <form onSubmit={handleSubmit(handleCalculate)}>
            <FormControl isInvalid={!!errors.device_name}>
              <FormLabel>{t('_Device_') + ' *'}</FormLabel>
              <Tooltip label={errors?.device_name?.message}>
                <div style={{
                  border: `${errors?.device_name?.message ? 2 : 1}px solid`,
                  borderColor: errors?.device_name?.message ? 'red' : 'inherit',
                  borderRadius: '8px',
                  marginBottom: '10px',
                  width: '25vw',
                }}>
                  <Select
                    size='lg'
                    w='100%'
                    variant='primary'
                    placeholder={t('_DeviceName_')}
                    {...register('device_name', {
                      required: t('_ErrorFill_'),
                    })}
                  >
                    {deviceOptions?.map((option:any) => (
                      <option style={{ fontSize: '14px', color: 'green.800' }}
                        key={option?.value || uuid()}
                        value={option?.value || option}
                      >
                        {option?.label || option}
                      </option>
                    ))}
                  </Select>
                </div>
              </Tooltip>
            </FormControl>
            <Flex flexDirection='row' justifyContent='space-between'>
              <Box 
              bg='#FFFFFF 0% 0% no-repeat padding-box'
              w='38vw'
              p={4}
              boxShadow='0px 0px 6px #00000033'
              opacity='1'
              border='1px solid #CBCBCB'
              borderRadius='10px'
              >
                <Flex position='relative' flexDirection='column'>
                  <Flex marginBottom='20px' flexDirection='row' justifyContent='flex-end'>
                    <Switch colorScheme='green' size='lg' isChecked={hardChecked} onChange={({target}) => setHardChecked(target.checked)} />
                  </Flex>                                
                  <Flex flexDirection='row' alignItems='center' justifyContent='space-between'>
                    <Text textColor='#048000' paddingLeft='16px'>Device Cost Tier</Text>
                    <FormControl w='40%' isInvalid={!!errors.qty} marginBottom={2}>
                      <Tooltip label={errors?.qty?.message}>
                        <Input
                          isDisabled={!hardChecked}
                          type="number"
                          w='100%'
                          placeholder={t('_Qty_')}
                          {...register('qty', {
                            required: hardChecked && t('_ErrorFill_'),
                            pattern: {
                              value: /^[0-9]*$/,
                              message: t('_ErrorNumber_'),
                            }
                          })}
                        />
                      </Tooltip>
                    </FormControl>
                  </Flex>
                  <TierTable type='DEVICE' tier={customerDetails?.device_cost_tier} />
                </Flex>
              </Box>
              <Box 
              bg='#FFFFFF 0% 0% no-repeat padding-box'
              w='38vw'
              p={4}
              boxShadow='0px 0px 6px #00000033'
              opacity='1'
              border='1px solid #CBCBCB'
              borderRadius='10px'
              >
                <Flex position='relative' flexDirection='column'>
                  <Flex marginBottom='20px' flexDirection='row' justifyContent='flex-end'>
                    <Switch colorScheme='green' size='lg' isChecked={servChecked} onChange={({target}) => setServChecked(target.checked)} />
                  </Flex>                                
                  <Flex flexDirection='row' alignItems='center' justifyContent='space-between'>
                    <Text textColor='#048000' paddingLeft='16px'>Service Cost Tier</Text>
                    <FormControl w='40%' isInvalid={!!errors.qty} marginBottom={2}>
                      <Tooltip label={errors?.qty?.message}>
                        <Input
                          isDisabled={!servChecked}
                          type="number"
                          w='100%'
                          placeholder={t('_Freq_')}
                          {...register('freq', {
                            required: servChecked && t('_ErrorFill_'),
                            pattern: {
                              value: /^[0-9]*$/,
                              message: t('_ErrorNumber_'),
                            }
                          })}
                        />
                      </Tooltip>
                    </FormControl>
                  </Flex>
                  <TierTable type='SERVICE' tier={customerDetails?.device_service_tier} />
                </Flex>
              </Box>
            </Flex>
            <Flex flexDirection='row' justifyContent='flex-end' marginTop='10px' marginBottom='10px' >
            <Button isDisabled={!hardChecked && !servChecked} variant='primary' ml={3} type='submit' isLoading={isLoading} loadingText="Calculate">
              {t('_Calculate_')}
            </Button>
            </Flex>
            {calcResult && <CalcResultTable tier={calcResult} hardChecked={hardChecked} bothChecked={hardChecked && servChecked} />}
        </form>
        </Box>
        </Section>
  );
};

export default CalculateDetails;


