import { ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Flex,
  BoxProps,
  CloseButton,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';

import { IconType } from 'react-icons';

import Logo from 'components/Logo';
import NavItem, { NavText } from 'components/NavItem';

import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { MenuData } from 'constants/pages';

interface ISidebarProps extends BoxProps {
  onClose: () => void;
  display?: { base?: string; md?: string; };
}

type MenuItemWrapperProps = {
  id: string | number;
  name: string;
  href?: string;
  icon: IconType | ReactNode | any;
  subNavigation?: any;
  isHidden?: boolean;
};

const MenuItemWrapper = ({ id, icon, name, href, subNavigation }: MenuItemWrapperProps) => (
  <AccordionButton
    border='none'
    outline='none'
    _expanded={subNavigation && { bg: 'rgba(255, 255, 255, 0.099)', ringColor: 'none', color: 'brand.500', fontSize: '16px', fontWeight: 'extrabold' }}
    _hover={{ fontWeight: 'extrabold' }}
    _focus={{ ringColor: 'none' }}
  >
    <Box flex='1' textAlign='left' border='none' outline='none'>
      {!subNavigation
        ? <NavItem key={id} path={href} icon={icon}>
          {name}
        </NavItem>
        : <NavText icon={icon} name={name} />
      }
    </Box>
    {subNavigation && <AccordionIcon />}
  </AccordionButton>
);

const MenuWrapper = ({ id, icon, name, href, subNavigation, isHidden=false }: MenuItemWrapperProps) => {

  if(isHidden) return null

  if (!subNavigation) {
    return (
      <AccordionItem
        border='none'
        outline='none'
        isFocusable={false}
      >
        <MenuItemWrapper id={id} icon={icon} name={name} href={href} />
      </AccordionItem>
    );
  }

  return (
    <AccordionItem
      border='none'
      outline='none'
      isFocusable={false}
    >
      <MenuItemWrapper id={id} icon={icon} name={name} href={href} subNavigation={subNavigation} />
      <AccordionPanel pb={4}>
        <Accordion allowToggle>
          {subNavigation?.map((subLink: MenuItemWrapperProps) => (
            <MenuWrapper {...subLink} key={subLink.id} />
          ))}
        </Accordion>
      </AccordionPanel>
    </AccordionItem>
  );
};

const SidebarContent = ({ onClose, display, ...rest }: ISidebarProps) => {
  const location = useLocation();
  const { pathname } = location;


  const { roles } = useSelector((state: RootState) => ({
    user: state.user.value,
    userDetails: state.user.userDetails,
    roles: state.user.roles,
  }));

  const defaultOpenItem = useMemo(()=> {
    const test = MenuData(roles)
    return test?.user?.findIndex(({ href, name }) => href === pathname || name.toLowerCase().split(' ')[0] === pathname.split('/')[1])
  },[pathname, roles])

  return (
    <Box
      top={0}
      left={0}
      h='full'
      pos='fixed'
      color='green.50'
      display={display}
      borderRadius='1px'
      transition='2s ease'
      w={{ base: 'full', md: 60 }}
      boxShadow='0 4px 12px 0 rgba(0, 0, 0, 0.5)'
      bg={useColorModeValue('#040D2F', '#040D2F')}
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      {...rest}
    >
      <Flex h='20' alignItems='center' mx='6' justifyContent='space-between' className='sidebar-logo'>
        <Logo light={true} />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      <Accordion
        allowToggle
        h='85vh'
        paddingTop='4'
        overflowX='auto'
        defaultIndex={[defaultOpenItem]}
      >
        {MenuData(roles)?.user?.map(link => (
          <MenuWrapper {...link} key={link.id} />
        ))}
      </Accordion>
    </Box>
  );
};

export default SidebarContent;


