import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Divider, Center, Button, useDisclosure } from '@chakra-ui/react';

import { FiRefreshCcw } from 'react-icons/fi';
import { BiAlignJustify } from 'react-icons/bi';
import { SiMicrosoftexcel } from 'react-icons/si';
import { RiMoneyDollarBoxLine } from 'react-icons/ri';

import Section from 'components/Section';
import ActionBarWrap from 'components/ActionBar';
import ComboTable from 'components/Table/ComboTable';
import BreadcrumbWrapper from 'components/Breadcrumb';

import { DELETE, GET } from 'constants/method';
import { useTranslator } from 'utils/translator';
import { DEVICE_ESTIMATE, DEVICE_TIER } from 'constants/api';
import { useFetchData, useFetchOnce, useConfirmationDialog } from 'utils/hooks';

import Upload from './Upload';
import AddEstimate from './AddEstimate';
import PriceGenerate from '../PriceGenerate';

import { RowType } from './types';
import { deviceListColumns } from './configs';

const DeviceEstimate = () => {
  const { t } = useTranslator();
  const navigate = useNavigate();

  const { getConfirmation } = useConfirmationDialog();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isPriceOpen, onOpen: onPriceOpen, onClose: onPriceClose } = useDisclosure();
  const { isOpen: isOpenUpload, onOpen: onOpenUpload, onClose: onCloseUpload } = useDisclosure();

  const { fetchData: fetchOnce } = useFetchOnce({}, DELETE);
  const { data: tierData } = useFetchData({ content: [] }, `${DEVICE_TIER}`);
  const { data, fetchData, isLoading } = useFetchData({ content: [] }, `${DEVICE_ESTIMATE}`);
  const { data: estimateInfo, setData, fetchData: fetchComponentInfo } = useFetchOnce({}, GET);

  const TitleComponent = useMemo(() => (
    <BreadcrumbWrapper items={[
      { name: t('_Dynamo_'), path: '/cmd/dynamo' },
      { name: t('_Device_'), path: '/cmd/dynamo/device' },
      { name: t('_DeviceEstimate_'), path: `/cmd/dynamo/device-estimate` },
    ]}
    />
  ), [t]);

  const handleOnDelete = async (id: string) => {
    const onDelete = async () => {
      const response = await fetchOnce(`${DEVICE_ESTIMATE}/${id}`);
      !response?.hasError && fetchData();
    };
    await getConfirmation(onDelete);
  };

  const handleOnEdit = async (id: string) => {
    const response = await fetchComponentInfo(`${DEVICE_ESTIMATE}/${id}`);
    !response?.hasError && onOpen();
  };

  const handleCloseModal = (isUpdate: boolean) => {
    if (isUpdate) fetchData();
    setData(null);
    onClose();
  };

  const getActions = (row: RowType) => {
    const { original: { id, device_name } } = row;
    return (
      <ActionBarWrap options={[
        {
          name: t('_Edit_'),
          icon: <EditIcon />,
          onClick: () => handleOnEdit(id),
        },
        {
          name: t('_SeeItems_'),
          icon: <BiAlignJustify />,
          onClick: () => navigate(`/dynamo/devices/device-estimate/line-items?deviceEstimateId=${id}&deviceName=${device_name}`),
        },
        {
          name: t('_Delete_'),
          icon: <DeleteIcon />,
          onClick: () => handleOnDelete(id),
        },
      ]}
      />
    );
  };

  return (
    <Section
      title={TitleComponent}
      actionBar={(
        <>
          <Button
            leftIcon={<SiMicrosoftexcel />}
            marginRight="10px"
            onClick={onOpenUpload}
          >
            {t('_Upload_')}
          </Button>
          <Button
            marginRight="10px"
            onClick={onPriceOpen}
            leftIcon={<RiMoneyDollarBoxLine />}
          >
            {t('_PriceGenerate_')}
          </Button>
          <Button size="md" variant="primary" onClick={onOpen}>
            {t('_AddEstimate_')}
          </Button>
          <Center height="40px" marginLeft="5px">
            <Divider orientation="vertical" />
          </Center>
          <Button
            size="sm"
            variant="secondary"
            onClick={fetchData}
          >
            <FiRefreshCcw />
          </Button>
        </>
      )}
    >
      <ComboTable
        columns={deviceListColumns}
        data={data?.content}
        isLoading={isLoading}
        getActions={getActions}
      />
      {isOpen && <AddEstimate
        closeModal={handleCloseModal}
        estimateInfo={estimateInfo}
      />}
      {isPriceOpen && <PriceGenerate
        deviceList={data?.content}
        tierList={tierData?.content}
        closeModal={onPriceClose}
      />}
      {isOpenUpload && <Upload
        closeModal={onCloseUpload}
      />}
    </Section>
  );
};

export default DeviceEstimate;