import { baseUrl, cmdUrl, geometryUrl, dynamoUrl } from 'configs';

export const FORGOT = `${baseUrl}/api/accounts/password/forgot`;
export const RESET = `${baseUrl}/api/accounts/password/reset`;
export const LOGIN = `${baseUrl}/api/oauth-token`;
export const USER = `${baseUrl}/api/accounts/info`;
export const USERS = `${baseUrl}/api/organizations/accounts/search`;
export const SUSPENDUSER = (id: string | null) => `${baseUrl}/api/accounts/${id}/suspend`;
export const UNSUSPENDUSER = (id: string | null) => `${baseUrl}/api/accounts/${id}/unsuspend`;
export const ORGANIZATIONS = `${baseUrl}/api/organizations/search`;
export const REGISTER = `${baseUrl}/api/oauth/public/accounts/register`;
export const CHECK_EMAIL = `${baseUrl}/api/oauth/public/accounts/check-email`;
export const VERIFICATION_TOKEN = `${baseUrl}/api/oauth/public/accounts/verification-token/info`;
export const VERIFICATION_ACCOUNT = (id: string | null) => `${baseUrl}/api/oauth/public/accounts/${id}/verify`;
export const USER_INVITE = `${baseUrl}/api/oauth/admin/organizations/participants/invite`;
export const ORGANIZATIONS_INVITE = `${baseUrl}/api/oauth/admin/organizations/invite`;
export const ORGANIZATIONS_REGISTER = `${baseUrl}/api/oauth/public/organizations/register`;
export const ORGANIZATIONS_ACCOUNT_REGISTER = (id: string | null) => `${baseUrl}/api/oauth/public/organizations/${id}/accounts/register`;
export const POLICY = `${geometryUrl}/api/greenwave/thing-policies`;
export const LOCATIONS_LIST = `${cmdUrl}/api/locations/search`;
export const DELETE_LOCATION = `${cmdUrl}/api/locations`;
export const THING_CERTIFICATE = `${geometryUrl}/api/greenwave/thing-certificates`;
export const THING_POLICY = `${geometryUrl}/api/greenwave/thing-policies`;
export const THING_CONTROLLER = `${geometryUrl}/api/greenwave/things`;
export const THING_TYPE = `${geometryUrl}/api/greenwave/thing-types`;
export const THING_BILLING_GROUPS = `${geometryUrl}/api/greenwave/thing-billing-groups`;
export const USER_INFO = `${baseUrl}/api/accounts/user-info`;
export const IMAGE_UPLOAD = `${baseUrl}/api/accounts/upload-image`;
export const THING_GROUP = `${geometryUrl}/api/greenwave/thing-groups`;
export const THING_TYPES = `${geometryUrl}/api/greenwave/thing-types`;
export const THING_BILLING = `${geometryUrl}/api/greenwave/thing-billing-groups`;
export const WAVE_APP = `${baseUrl}/api`;
export const ROOMS_LIST = `${cmdUrl}/api/rooms/locations`;
export const ROOMS = `${cmdUrl}/api/rooms`;
export const DEVICES_LIST = `${cmdUrl}/api/devices/rooms`;
export const DEVICES = `${cmdUrl}/api/devices`;
export const LOCATION = `${ cmdUrl }/api/locations`;
export const DATADETAILS = `${cmdUrl}/api`;
export const EXCELL_ESTIMATE = `${dynamoUrl}/dynamo/excel/add-excel-estimate`;
export const COMPONENT = `${ dynamoUrl }/dynamo/component`;
export const DEVICE_ESTIMATE = `${dynamoUrl}/dynamo/device-estimate`;
export const DEVICE_ESTIMATE_FOR_QTY = `${dynamoUrl}/dynamo/device-estimate/for-qty`;
export const DEVICE_ESTIMATE_FOR_QTY_TIER = `${dynamoUrl}/dynamo/device-estimate/for-qty-tier`;
export const DEVICE_TIER = `${dynamoUrl}/dynamo/device-tier`;
export const SERVICE_TIER = `${dynamoUrl}/dynamo/service-tier`;
export const PRICE = `${dynamoUrl}/dynamo/price`;
export const CUSTOMER_TIER_ASSOCIATION = `${dynamoUrl}/dynamo/customer-tier-association`;
export const CUSTOMER_TIER = `${dynamoUrl}/dynamo/customer-tier-association/customer-tier-by-name`;
export const CUSTOMER_ASSIGN_DEVICE_TIER = `${dynamoUrl}/dynamo/customer-tier-association/associate-device-cost-tier-with-greenwave-organization`;
export const CUSTOMER_ASSIGN_SERVICE_TIER = `${dynamoUrl}/dynamo/customer-tier-association/associate-device-service-tier-with-greenwave-organization`;
export const SERVICE_TIER_CALCULATION = `${dynamoUrl}/dynamo/service-tier/for-device-tier-freq-per-day-by-names`;
export const ESTIMATE_LINE_ITEM = `${dynamoUrl}/dynamo/estimate-line-item`;
export const ESTIMATE_LINE_ITEMS = `${dynamoUrl}/dynamo/estimate-line-item/line-items-for-estimate`;
export const ADD_ESTIMATE_LINE_ITEM = `${dynamoUrl}/dynamo/estimate-line-item/add-estimate-line-item`;
