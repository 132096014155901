import { useState } from 'react';
import { useDisclosure, Divider, Center, Button } from '@chakra-ui/react';
import { FiRefreshCcw } from 'react-icons/fi';
import { BiCustomize } from 'react-icons/bi';

import Section from 'components/Section';
import ActionBarWrap from 'components/ActionBar';
import ComboTable from 'components/Table/ComboTable';

import { POST } from 'constants/method';
import { useFetchOnce } from 'utils/hooks';
import { useTranslator } from 'utils/translator';
import useFetchData from 'utils/hooks/useFetchData';
import { ORGANIZATIONS, CUSTOMER_TIER, SERVICE_TIER, DEVICE_TIER } from 'constants/api';

import AssignTier from './AssignTier';
import AddOrganizations from './AddOrganizations';

import { columns } from './configs';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const Organizations = () => {
  const { t } = useTranslator();

  const [selectedOrganizationName, setOrganizationName] = useState();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isTierOpen, onOpen: onTierOpen, onClose: onTierClose } = useDisclosure();

  const { data: deviceTier } = useFetchData({ content: [] }, `${DEVICE_TIER}`);
  const { data: serviceTier } = useFetchData({ content: [] }, `${SERVICE_TIER}`);

  const { data: tierInfo, fetchData: fetchTierInfo } = useFetchOnce(null, POST);
  const { data, fetchData, isLoading } = useFetchData({ items: [], totalCount: 0 }, ORGANIZATIONS);

  const getCustomerTier = async (name: string) => {
    await fetchTierInfo(`${CUSTOMER_TIER}?customerName=${name}`);
    onTierOpen();
  };

  const { roles } = useSelector((state: RootState) => ({
    user: state.user.value,
    userDetails: state.user.userDetails,
    roles: state.user.roles,
  }));

  const canCreateOrganization = roles.some((role) => ['ADMIN', 'SUPER_ADMIN'].includes(role));
  
  const getActions = (row: any) => {
    const { original: { name } } = row;
    return (
      <ActionBarWrap options={[
        {
          name: t('_Tiers_'),
          icon: <BiCustomize />,
          onClick: () => {
            setOrganizationName(name);
            getCustomerTier(name);
          },
        },
      ]}
      />
    );
  };

  return (
    <Section
      title={t('_Organizations_')}
      actionBar={(
        <>
          <Button size="md" variant="primary" onClick={onOpen} disabled={!canCreateOrganization}>
            {t('_AddOrganizations_')}
          </Button>
          <Center height="40px" marginLeft="5px">
            <Divider orientation="vertical" />
          </Center>
          <Button
            size="sm"
            variant="secondary"
            onClick={fetchData}
          >
            <FiRefreshCcw />
          </Button>
        </>
      )}
    >
      <ComboTable
        columns={columns}
        data={data?.items}
        isLoading={isLoading}
        getActions={getActions}
      />
      <AddOrganizations
        visible={isOpen}
        closeModal={onClose}
      />
      {isTierOpen && <AssignTier
        tierInfo={tierInfo}
        organizationName={selectedOrganizationName}
        serviceTier={serviceTier?.content}
        deviceTier={deviceTier?.content}
        closeModal={onTierClose}
      />}
    </Section>
  );
};

export default Organizations;
