import { ColumnType } from 'models';

export const columns: ColumnType[] = [
  {
    Header: 'Status',
    accessor: 'node.status',
    type: 'TEXT',
  },
  {
    Header: 'Due',
    accessor: 'node.dueDate',
    type: 'DATEDURATION',
  },
  {
    Header: 'Date',
    accessor: 'node.invoiceDate',
    type: 'TEXT',
  },
  {
    Header: 'Number',
    accessor: 'node.invoiceNumber',
    type: 'TEXT',
  },
  {
    Header: 'Customer',
    accessor: 'node.customer.name',
    type: 'TEXT',
  },
  {
    Header: 'Amount Pay',
    accessor: 'node.amountDue.currency.symbol',
    type: 'TEXT',
  },
  {
    Header: 'Amount Due',
    accessor: 'node.amountDue.value',
    type: 'NUMBER_BY_CURRENCY',
    currencyKey: 'node.amountDue.currency.symbol',
  },
];
