import { useForm, SubmitHandler } from 'react-hook-form';
import {
  Modal,
  Input,
  Button,
  Tooltip,
  ModalBody,
  FormLabel,
  ModalFooter,
  ModalHeader,
  FormControl,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Select,
  useToast,
} from '@chakra-ui/react';

import { useTranslator } from 'utils/translator';
import { sendingType } from 'utils/fetch/sendType';
import { ERROR, SUCCESS } from 'constants/toaster';
import useFetchData from 'utils/hooks/useFetchData';
import useFetchOnce from 'utils/hooks/useFetchOnce';
import { THING_CONTROLLER, THING_TYPE, THING_BILLING_GROUPS } from 'constants/api';

import { AddThingPropsType, AddThingType } from './types';

const AddThing = ({ visible = false, closeModal, updateData }: AddThingPropsType) => {
  const { t } = useTranslator();
  const toast = useToast();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddThingType>();

  const { data: dataType } = useFetchData([], `${THING_TYPE}/list-all-thing-types`);
  const { data: dataBillingGroup } = useFetchData([], `${THING_BILLING_GROUPS}/list-all-billing-groups`);
  const { fetchData: fetchCreateThing, hasError } = useFetchOnce([]);

  const handleCloseModal = () => {
    reset();
    closeModal();
  };

  const handleAddThing: SubmitHandler<AddThingType> = async ({ thingName, thingTypeName, billingGroupName }: AddThingType) => {
    const sendTypeData = {
      thingName: sendingType(thingName),
      thingTypeName,
      billingGroupName,
    };

    await fetchCreateThing(THING_CONTROLLER, sendTypeData);

    if (!hasError) {
      updateData?.();
      handleCloseModal();
      toast(SUCCESS);
    } else {
      toast(ERROR);
    }
  };

  return (
    <Modal
      isOpen={visible}
      onClose={handleCloseModal}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('_AddThing_')}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handleAddThing)}>
          <ModalBody pb={6} m={1}>
            <FormControl isInvalid={!!errors.thingName}>
              <FormLabel mb={3}>{t('_ThingName_')}</FormLabel>
              <Tooltip label={errors?.thingName?.message}>
                <Input
                  focusBorderColor='brand.500'
                  placeholder={t('_ThingName_')}
                  {...register('thingName',
                    {
                      required: true,
                      maxLength: 20,
                    })}
                />
                  </Tooltip>
                  <FormLabel mt={3}>{t('_ThingType_')}</FormLabel>
                  <Tooltip label={errors?.thingName?.message}>
                    <Select variant="filled" placeholder='Select Thing Type' size="lg"
                      {...register( 'thingTypeName')}>
                          {dataType?.reverse()?.map(({thingTypeName}: any) => (
                            <option key={thingTypeName} value={`${ thingTypeName }`}>{thingTypeName}</option>
                          ))}
                    </Select>
                  </Tooltip>
                  <FormLabel mt={3}>{t('_ThingBillingGroups_')}</FormLabel>
                  <Tooltip label={errors?.thingName?.message}>
                    <Select focusBorderColor ='brand.500' variant="filled"  placeholder='Select Billing Groups' size="lg"
                      {...register( 'billingGroupName')}>
                      {dataBillingGroup?.reverse()?.map(({thingBillingGroupName}: any) => (
                        <option key={thingBillingGroupName} value={`${ thingBillingGroupName }`}>{thingBillingGroupName}</option>
                      ))}
                    </Select>
                  </Tooltip>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={handleCloseModal} mr={3}>{t('_Cancel_')}</Button>
            <Button variant="primary" type='submit'>
              {t('_Submit_')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default AddThing;
