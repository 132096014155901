import { useNavigate } from 'react-router-dom';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Divider, Center, Button, useDisclosure } from '@chakra-ui/react';
import { FiRefreshCcw } from 'react-icons/fi';
import { AiOutlineQrcode } from 'react-icons/ai';
import { MdRoomPreferences } from 'react-icons/md';

import Section from 'components/Section';
import ActionBarWrap from 'components/ActionBar';
import ComboTable from 'components/Table/ComboTable';
import ModalWrapper from 'components/Modal';

import { DELETE, GET } from 'constants/method';
import { useTranslator } from 'utils/translator';
import useFetchData from 'utils/hooks/useFetchData';
import { useConfirmationDialog, useFetchOnce } from 'utils/hooks';
import { DELETE_LOCATION, LOCATION, LOCATIONS_LIST } from 'constants/api';

import AddLocation from '../AddLocation';
import { RowType } from '../types';
import { locationsListColumns } from './configs';

const Locations = () => {
  const { t } = useTranslator();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getConfirmation } = useConfirmationDialog();

  const { fetchData: fetchOnce } = useFetchOnce({}, DELETE);
  const { data, fetchData, isLoading } = useFetchData({ items: [], totalCount: 0 }, `${LOCATIONS_LIST}?participantType=OWNER`);
  const { data: LocationInfo, setData, fetchData: fetchLocationInfo } = useFetchOnce({}, GET);

  const handleOnDelete = async (id: string) => {
    const onDelete = async () => {
      const response = await fetchOnce(`${DELETE_LOCATION}?id=${id}`);
      !response?.hasError && fetchData();
    };
    await getConfirmation(onDelete);
  };

  const handleOnEdit = async (id: string) => {
    const response = await fetchLocationInfo(`${LOCATION}/${id}`);
    !response?.hasError && onOpen();
  };

  const handleCloseModal = (isUpdate: boolean) => {
    if (isUpdate) fetchData();
    setData(null);
    onClose();
  };

  const getActions = (row: RowType) => {
    const { original: { id, name } } = row;
    return (
      <ActionBarWrap options={[
        {
          name: t('_Edit_'),
          icon: <EditIcon />,
          onClick: () => handleOnEdit(id),
        },
        {
          name: t('_Rooms_'),
          icon: <MdRoomPreferences />,
          onClick: () => navigate(`/cmd/rooms?locationId=${id}&locationName=${name}`),
        },
        {
          name: t('_DownloadQRcode_'),
          icon: <AiOutlineQrcode />,
        },
        {
          name: t('_Delete_'),
          icon: <DeleteIcon />,
          onClick: () => handleOnDelete(id),
        },
      ]}
      />
    );
  };

  return (
    <Section
      title={t('_Locations_')}
      actionBar={(
        <>
          <Button size="md" variant="primary" onClick={onOpen}>
            {t('_AddLocation_')}
          </Button>
          <Center height="40px" marginLeft="5px">
            <Divider orientation="vertical" />
          </Center>
          <Button
            size="sm"
            variant="secondary"
            onClick={fetchData}
          >
            <FiRefreshCcw />
          </Button>
        </>
      )}
    >
      <ComboTable
        columns={locationsListColumns}
        data={data?.items}
        isLoading={isLoading}
        getActions={getActions}
      />
      <ModalWrapper isOpen={isOpen}>
        <AddLocation selectedLocation={LocationInfo} visible={isOpen} closeModal={handleCloseModal} />
      </ModalWrapper>
    </Section>
  );
};

export default Locations;
