import {
  Flex,
  Modal,
  Stack,
  Input,
  Button,
  Heading,
  ModalBody,
  FormLabel,
  ModalFooter,
  FormControl,
  ModalOverlay,
  ModalContent,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useTranslator } from 'utils/translator';

// TODO:: User image upload
// import FileUpload from 'components/FileUpload';
// import useFetchData from 'utils/hooks/useFetchData';

import { PUT } from 'constants/method';
import { USER_INFO } from 'constants/api';
import { ERROR, SUCCESS } from 'constants/toaster';

import useFetchOnce from 'utils/hooks/useFetchOnce';

type FormValues = {
  phone: number;
  file_: FileList;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
};

type ProfileInfo = {
  user: any;
  isOpen: boolean;
  closeModal: () => void;
};

const UserDetails = ({ isOpen, closeModal, user }: ProfileInfo) => {
  const { t } = useTranslator();
  const toast = useToast();

  const { register, handleSubmit } = useForm<FormValues>();
  const { fetchData } = useFetchOnce({}, PUT);

  // TODO:: User image upload
  // const onSubmit = handleSubmit((data) => {
  //   fetchData(IMAGE_UPLOAD, data.file_[0]);
  //   setState(data);
  // });

  // const validateFiles = (value: FileList) => {
  //   if (value.length < 1) {
  //     return 'Files is required';
  //   }
  //   for (const file of Array.from(value)) {
  //     const fsMb = file.size / (1024 * 1024);
  //     const MAX_FILE_SIZE = 10;
  //     if (fsMb > MAX_FILE_SIZE) {
  //       return 'Max file size 10mb';
  //     }
  //   }
  //   return true;
  // };

  const onSubmit = async (data: FormValues) => {
    const response = await fetchData(USER_INFO, data);
    if (response?.hasError) {
      toast(ERROR);
    } else {
      toast(SUCCESS);
      closeModal();
    }
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={4}>
          <Flex
            align='center'
            justify='center'
            bg={useColorModeValue('gray.50', 'gray.800')}
          >
            <Stack
              spacing={4}
              w='full'
              maxW='md'
              bg={useColorModeValue('white', 'gray.700')}
              rounded='xl'
              p={6}
            >
              <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }}>
                {t('_UserProfile_')}
              </Heading>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl isRequired>
                  <FormLabel>{t('_FirstName_')}</FormLabel>
                  <Input
                    placeholder={t('_FirstName_')}
                    _placeholder={{ color: 'gray.500' }}
                    type='text'
                    defaultValue={user?.firstName}
                    {...register('firstName', {
                      required: t('_FirstNameRequired'),
                      minLength: 3,
                      maxLength: 80,
                    })}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>{t('_LastName_')}</FormLabel>
                  <Input
                    placeholder={t('_LastName_')}
                    _placeholder={{ color: 'gray.500' }}
                    type='text'
                    defaultValue={user?.lastName}
                    {...register('lastName', {
                      required: t('_LasttNameRequired'),
                      minLength: 3,
                      maxLength: 100,
                    })}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>{t('_Email_')}</FormLabel>
                  <Input
                    placeholder='your-email@example.com'
                    _placeholder={{ color: 'gray.500' }}
                    type='email'
                    defaultValue={user?.email}
                    disabled
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>{t('_Phone_')}</FormLabel>
                  <Input
                    placeholder={t('_Phone_')}
                    _placeholder={{ color: 'gray.500' }}
                    type='number'
                    defaultValue={user?.phone}
                    {...register('phone', {
                      required: t('_PhoneRequired'),
                      minLength: 3,
                      maxLength: 100,
                    })}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>{t('_DateOfBirth_')}</FormLabel>
                  <Input
                    placeholder={t('_DateOfBirth_')}
                    _placeholder={{ color: 'gray.500' }}
                    type='date'
                    defaultValue={user?.dateOfBirth}
                    {...register('dateOfBirth', {
                      required: t('_DateOfBirthRequired'),
                      minLength: 3,
                      maxLength: 100,
                    })}
                  />
                </FormControl>
                <ModalFooter p='0' mt='5'>
                  <Button variant='primary' mr={3} type='submit'>
                    {t('_Submit_')}
                  </Button>
                  <Button onClick={closeModal}>{t('_Cancel_')}</Button>
                </ModalFooter>
              </form>
            </Stack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UserDetails;
