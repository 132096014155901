import { useDisclosure, Divider, Center, MenuList, MenuItem, Button } from '@chakra-ui/react';
import { LockIcon, UnlockIcon } from '@chakra-ui/icons';
import { FiRefreshCcw } from 'react-icons/fi';

import Section from 'components/Section';
import ComboTable from 'components/Table/ComboTable';

import {
  useFetchOnce,
  useFetchData,
} from 'utils/hooks';
import { useTranslator } from 'utils/translator';
import { SUSPENDUSER, UNSUSPENDUSER, USERS } from 'constants/api';
import useConfirmationDialog from 'utils/hooks/useConfirmationDialog';

import AddUser from './AddUser';

import { columns } from './configs';
import { RowType } from './types';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const Users = () => {
  const { t } = useTranslator();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getConfirmation } = useConfirmationDialog();

  const { roles } = useSelector((state: RootState) => ({
    user: state.user.value,
    userDetails: state.user.userDetails,
    roles: state.user.roles,
  }));

  const canCreateOrganization = roles.some((role) => ['ADMIN', 'SUPER_ADMIN', 'ORGANIZATION_ADMIN', 'ORGANIZATION_OWNER'].includes(role));

  const { fetchData: fetchPostData, isLoading: actionLoading } = useFetchOnce();
  const { data, fetchData, isLoading } = useFetchData({ items: [], totalCount: 0 }, `${USERS}?includeSuspended=true`);

  const handleOnSuspend = async (id: string) => {
    const fetchOnSuspend = async () => {
      const response = await fetchPostData(SUSPENDUSER(id));
      !response?.hasError && fetchData();
    };
    await getConfirmation(fetchOnSuspend);
  };

  const handleOnUnsuspend = async (id: string) => {
    const fetchOnUnSuspend = async () => {
      const response = await fetchPostData(UNSUSPENDUSER(id));
      !response?.hasError && fetchData();
    };
    await getConfirmation(fetchOnUnSuspend);
  };

  const getActions = (row: RowType) => {
    const { original: { id, suspended } } = row;
    return (
      <MenuList>
        {!suspended
          ? <MenuItem icon={<LockIcon />} onClick={() => handleOnSuspend(id)}>
            Suspend
          </MenuItem>
          : <MenuItem icon={<UnlockIcon />} onClick={() => handleOnUnsuspend(id)}>
            Unsuspend
          </MenuItem>
        }
      </MenuList>
    );
  };

  return (
    <Section
      title={t('_User_')}
      actionBar={(
        <>
          <Button size="md" variant="primary" onClick={onOpen} disabled={!canCreateOrganization}>
            {t('_AddUser_')}
          </Button>
          <Center height="40px" marginLeft="5px">
            <Divider orientation="vertical" />
          </Center>
          <Button
            size="sm"
            variant="secondary"
            onClick={fetchData}
          >
            <FiRefreshCcw />
          </Button>
        </>
      )}
    >
      <ComboTable
        data={data?.items}
        columns={columns}
        getActions={getActions}
        isLoading={isLoading || actionLoading}
      />
      <AddUser
        visible={isOpen}
        closeModal={onClose}
      />
    </Section>
  );
};

export default Users;
