export const tierListColumns = [
  {
    Header: 'Tier name',
    accessor: 'tier_name'
  },
  {
    Header: 'Base rate per publish',
    accessor: 'base_rate_per_publish'
  },
  {
    Header: 'Overhead',
    accessor: 'overhead_p'
  },
  {
    Header: 'Margin',
    accessor: 'margine_p'
  },
  {
    Header: 'R & D',
    accessor: 'rnd_p'
  },
];
