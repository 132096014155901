import {
  useDisclosure,
  Button,
  MenuItem,
  MenuList,
  useToast,
} from '@chakra-ui/react';
import { FiRefreshCcw } from 'react-icons/fi';
import { DeleteIcon, EditIcon, InfoOutlineIcon } from '@chakra-ui/icons';

import Section from 'components/Section';
import ComboTable from 'components/Table/ComboTable';

import { DELETE, PUT } from 'constants/method';
import { useTranslator } from 'utils/translator';
import { THING_CERTIFICATE } from 'constants/api';
import { ERROR, SUCCESS } from 'constants/toaster';
import useFetchData from 'utils/hooks/useFetchData';
import { useConfirmationDialog, useFetchOnce } from 'utils/hooks';

import AddCertificate from './AddCertificate';

import { RowType } from './types';
import { columns } from './configs';

const Certificates = () => {
  const toast = useToast();
  const { t } = useTranslator();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getConfirmation } = useConfirmationDialog();

  const { data, fetchData, isLoading } = useFetchData([], `${THING_CERTIFICATE}/list-all-certificates`);
  const { fetchData: fetchDeleteCertificate } = useFetchOnce([], DELETE);
  const { fetchData: fetchEditCertificateStatus } = useFetchOnce({}, PUT);

  const handleDelete = async (certificateId: string) => {
    const fetchDelete = async () => {
      const response = await fetchDeleteCertificate(`${THING_CERTIFICATE}/${certificateId}`);
      if (!response?.hasError) {
        toast(SUCCESS);
        fetchData();
      } else {
        toast(ERROR);
      }
    };

    getConfirmation(fetchDelete);
  };

  const handleEdit = async (id: string, status: string) => {
    const InactiveStatus = async () => {
      const response = await fetchEditCertificateStatus(THING_CERTIFICATE, {
        certificateId: id,
        newStatus: status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE',
      });
      !response?.hasError && fetchData();
    };
    getConfirmation(InactiveStatus);
  };

  const handleDetails = (id: string) => {
    console.log(id);
  };

  const getActions = (row: RowType) => {
    const {
      original: { certificateId, status },
    } = row;

    return (
      <MenuList>
        <MenuItem
          icon={<EditIcon />}
          onClick={() => handleEdit(certificateId, status)}
        >
          {t('_EditCertificate_')}
        </MenuItem>
        {status === 'INACTIVE' && (
          <MenuItem
            icon={<DeleteIcon />}
            onClick={() => handleDelete(certificateId)}
          >
            {t('_DeleteCertificate_')}
          </MenuItem>
        )}
        <MenuItem
          icon={<InfoOutlineIcon />}
          onClick={() => handleDetails(certificateId)}
        >
          {t('_Details_')}
        </MenuItem>
      </MenuList>
    );
  };

  return (
    <Section
      title={t('_Certificate_')}
      actionBar={
        <>
          <Button size='md' variant='primary' onClick={onOpen}>
            {t('_CreateCertificate_')}
          </Button>
          <Button size='sm' variant='secondary' onClick={fetchData}>
            <FiRefreshCcw />
          </Button>
        </>
      }
    >
      <ComboTable
        isLoading={isLoading}
        columns={columns}
        getActions={getActions}
        data={data}
      />
      <AddCertificate
        visible={isOpen}
        closeModal={onClose}
        updateData={fetchData}
      />
    </Section>
  );
};

export default Certificates;
