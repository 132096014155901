import { useContext, useRef, useCallback } from 'react';
import {
  Button,
  Portal,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Flex,
  Box,
  Text,
  Image,
} from '@chakra-ui/react';

import warning from 'assets/images/warning.svg';

import { ConfirmDialogContext, defaultDialogOption } from './Context';

const Dialog = ({ children, ...rest }: any) => {
  const cancelRef = useRef(null);

  const { dialog, setDialog } = useContext(ConfirmDialogContext);
  const { isOpen, text, actionCallback, noBtnText, yesBtnText } = dialog;

  const resetDialog = useCallback(() => {
    return setDialog(defaultDialogOption);
  }, [setDialog]);

  const handleYesClick = () => {
    resetDialog();
    if (actionCallback) {
      actionCallback();
    }
  };

  if (!isOpen) return null;

  return (
    <Portal>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={resetDialog}
        isOpen={isOpen}
        isCentered
        {...rest}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader p="15px 15px">
            <Flex alignItems="center">
              <Box>
                <Image src={warning} />
              </Box>
              <Text
                bgGradient="linear(to-l, gray.800, gray.900)"
                bgClip="text"
                fontSize="30"
                fontWeight="normal"
                pt="10px"
              >
                {text}
                </Text>
            </Flex>
          </AlertDialogHeader>
          <AlertDialogBody>
            {children}
          </AlertDialogBody>
          <AlertDialogFooter p="0 20px 20px 20px" >
            <Button ref={cancelRef} onClick={resetDialog} bg="#CBCBCB">
              {noBtnText}
            </Button>
            <Button variant="primary" ml={3} onClick={handleYesClick}>
              {yesBtnText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Portal>
  );
};

export default Dialog;
