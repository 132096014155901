import { Flex, FormControl, CircularProgress, CircularProgressLabel, FormLabel } from "@chakra-ui/react";


function CircleIndicators({data, type}:any) {

  return (
    <Flex  marginBottom='10px' flexDirection='row' w='99%' justifyContent='space-between'>
      {type ==='risks' ? 
      <>
      <FormControl position='relative' w='15vw' h='15vw' marginRight='10px' background='#F7F9FA 0% 0% no-repeat padding-box' borderRadius='10px' justifyContent='center' alignItems='center'>
        <FormLabel fontSize='1.4vw' margin='0' textAlign='center' htmlFor='alergy'>Allergy</FormLabel>
        <CircularProgress pos='absolute' left='17%' top ='20%'  size='10vw' value={data?.allergyRisk?.riskIndex * 10} color='#FFC533'>
          <CircularProgressLabel fontSize='1.5vw'>{data?.allergyRisk?.riskIndex}</CircularProgressLabel>
        </CircularProgress>
      </FormControl>
      <FormControl position='relative' w='15vw' h='15vw' marginRight='10px' background='#F7F9FA 0% 0% no-repeat padding-box' borderRadius='10px' justifyContent='center' alignItems='center'>
        <FormLabel margin='0' fontSize='1.4vw' textAlign='center' htmlFor='virus'>Virus</FormLabel>
        <CircularProgress pos='absolute' left='17%' top ='20%'  size='10vw' value={data?.virusRisk?.riskIndex * 10} color='#62B58F'>
          <CircularProgressLabel fontSize='1.5vw'>{data?.virusRisk?.riskIndex}</CircularProgressLabel>
        </CircularProgress>
      </FormControl>
      <FormControl position='relative' w='15vw' h='15vw' background='#F7F9FA 0% 0% no-repeat padding-box' borderRadius='10px' justifyContent='center' alignItems='center'>
        <FormLabel margin='0' fontSize='1.4vw' textAlign='center' htmlFor='mold'>Mold</FormLabel>
        <CircularProgress pos='absolute' left='17%' top ='20%' size='10vw' value={data?.moldRisk?.riskIndex * 10} color='#62B58F'>
          <CircularProgressLabel fontSize='1.5vw'>{data?.moldRisk?.riskIndex}</CircularProgressLabel>
        </CircularProgress>
      </FormControl></> 
      : type ==='aqi' ? 
      <>
      <FormControl  position='relative' w='15vw' h='15vw' marginRight='10px'  background='#F7F9FA 0% 0% no-repeat padding-box' borderRadius='10px' alignItems='center'>
        <FormLabel margin='0' fontSize='1.4vw' textAlign='center' marginRight='5%'  htmlFor='aqi'>AQI</FormLabel>
        <CircularProgress pos='absolute' left='17%' top ='20%'  size='10vw' value={data?.totalAQIScore /5} color='#62B58F'>
          <CircularProgressLabel fontSize='1.7vw'>{data?.totalAQIScore}</CircularProgressLabel>
        </CircularProgress>
      </FormControl>
      <FormControl position='relative' w='15vw' h='15vw'  background='#F7F9FA 0% 0% no-repeat padding-box' borderRadius='10px' alignItems='center'>
        <FormLabel margin='0' fontSize='1.4vw' textAlign='center' marginRight='5%'  htmlFor='aqi'>EAQI</FormLabel>
        <CircularProgress  pos='absolute' left='17%' top ='20%'  size='10vw' value={data?.totalEAQIScore /5} color='#FFC533'>
          <CircularProgressLabel fontSize='1.7vw'>{data?.totalEAQIScore}</CircularProgressLabel>
        </CircularProgress>
      </FormControl></> : null}
    </Flex>
  )
}

export default CircleIndicators;
