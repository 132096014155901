import { ReactNode, ReactText } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Flex,
  Icon,
  FlexProps,
  Text,
} from '@chakra-ui/react';

import { hoverColor } from 'styles/variables';

import { IconType } from 'react-icons';
import { SiSmartthings } from 'react-icons/si';

import Styles from './style';

interface INavItemProps extends FlexProps {
  path?: string;
  icon: IconType | ReactNode | any;
  children: ReactText | string;
}

interface INavText {
  icon: IconType | ReactNode | any;
  name: ReactText | string;
}

export const NavText = ({ icon = SiSmartthings, name, ...restProps }: INavText) => (
  <Flex
    p="0.2"
    mx="2"
    role="group"
    align="center"
    cursor="pointer"
    borderRadius="lg"
    _hover={{
      fontWeight: 'bold',
    }}
    {...restProps}  
  >
    {icon && (
      <Icon
        mr="4"
        as={icon}
        fontSize="16"
        _groupHover={{
          fontWeight: 'bold'
        }}
      />
    )}
    <Text fontSize="15"
      fontWeight="medium"
      _hover={{
        color: hoverColor,
      }}
    >
      {name}
    </Text>
  </Flex>
);

const NavItem = ({ icon, path, children, ...rest }: INavItemProps) => (
  <Styles>
    <NavLink to={path || '/'} style={{ textDecoration: 'none' }}>
      <NavText icon={icon} name={children} {...rest} />
    </NavLink>
  </Styles>
);

export default NavItem;
