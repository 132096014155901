export const componentListColumns = [
  {
    Header: 'Value',
    accessor: 'value'
  },
  {
    Header: 'Note',
    accessor: 'note',
  },
  {
    Header: 'Comment',
    accessor: 'comment',
    type: 'COPYABLE_TEXT',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Manufacturer',
    accessor: 'manufacturer',
  },
  {
    Header: 'Manufacturer part number',
    accessor: 'manufacturer_part_number',
  },
  {
    Header: 'Supplier part number',
    accessor: 'supplier_part_number',
  },
];
