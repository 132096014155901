// import Table from 'components/Table';

import { Link } from 'react-router-dom';

// const columns = [
//   {
//     Header: 'Name',
//     accessor: 'firstName'
//   },
//   {
//     Header: 'Info',
//     accessor: 'info'
//   },
// ];

const Dashboard = () => {
  return (
    <>
      <Link to="/sign-in">Sign In</Link>
      Dashboard
      {/* <Table columns={columns} data={[
        { firstName: 'Miqo', info: 'Test 1' },
        { firstName: 'Tiko', info: 'Test 2' },
      ]} /> */}
    </>
  );
};

export default Dashboard;
