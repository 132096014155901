import styled from '@emotion/styled';

const GoogleMapStyledWrapper = styled.div`
  width: '100%';
  height: '100%';

  .gm-style .gm-style-iw-c {
    padding-right: 10px !important;
    padding-bottom: 10px !important;
    width: 100% !important;
    max-width: 250px !important;
    max-height: 200px !important;
    min-width: 0px !important;
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    background-color: #050c23 !important;
    border-radius: 8px;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  }

  .gm-style .gm-style-iw-d {
    overflow: hidden !important;
    max-height: 150px !important;
    background-color: #050c23 !important;

    .poi-info-window .gm-style {
      background-color: #050c23 !important;
    }
  }

  .gm-style .gm-style-iw-t::after {
    background: #050c23 !important;
  }

  .gm-style .gm-style-iw-c button {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 10px 10px;
    border: none;
    width: 16px;
    height: 16px;
    top: 5px;
    right: 5px;
    background: transparent;
    color: #fff;

    &::after {
      content: '';
      width: 9px;
      height: 9px;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 9;
      pointer-events: none;
      transition: 0.5s;
      background: url('/close.svg') no-repeat center;
    }
  }

  button .gm-ui-hover-effect {
    background: transparent;
    img {
      transform: scale(0.98);
      transition: 0.5s;
      background: transparent;
    }
  }
`;

export const MarkerInfoStyledWrapper = styled.div`
  width: 100%;
  height: 100%;

  .dataChart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem auto;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    p {
      margin-left: 5px;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .canvas {
    align-self: end;
    margin: 0 1rem;
  }
`;

export default GoogleMapStyledWrapper;
