import { useMemo } from 'react';
import { SubmitHandler } from 'react-hook-form';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';

import Form from 'components/Form';
import { FormElementType } from 'components/Form/type';

import { PRICE } from 'constants/api';
import { POST } from 'constants/method';
import { useTranslator } from 'utils/translator';
import { ERROR, SUCCESS } from 'constants/toaster';
import useFetchOnce from 'utils/hooks/useFetchOnce';

import { AddComponentPropsType, AddComponentType } from './types';

const AddCost = ({
  visible = false,
  closeModal,
  componentInfo,
}: AddComponentPropsType) => {
  const { t } = useTranslator();
  const toast = useToast();

  const { fetchData } = useFetchOnce({}, POST);

  const handleCloseModal = (update?: boolean) => {
    closeModal(update);
  };

  const addComponent = async (data: AddComponentType) => {
    const customData = { ...componentInfo, ...data };
    const response = await fetchData(PRICE, customData);
    if (response?.ok) {
      handleCloseModal(true);
      toast(SUCCESS);
    } else {
      toast(ERROR);
    }
  };

  const handleAddComponent: SubmitHandler<AddComponentType> = async (
    data: AddComponentType,
  ) => addComponent(data);

  const formElements: FormElementType[] = useMemo(() => ([
    {
      placeholder: t('_CostQtyHundred_'),
      label: t('_CostQtyHundred_'),
      registerProp: ['cost_qty_hundred', { required: t('_ErrorFill_') }],
      type: 'input',
      typeInput: 'number',
      defaultValue: componentInfo?.cost_qty_hundred,
    },
    {
      placeholder: t('_CostQtyHundredK_'),
      label: t('_CostQtyHundredK_'),
      registerProp: ['cost_qty_hundred_k', { required: t('_ErrorFill_') }],
      type: 'input',
      typeInput: 'number',
      defaultValue: componentInfo?.cost_qty_hundred_k,
    },
    {
      placeholder: t('_CostQtyK_'),
      label: t('_CostQtyK_'),
      registerProp: ['cost_qty_k', { required: t('_ErrorFill_') }],
      type: 'input',
      typeInput: 'number',
      defaultValue: componentInfo?.cost_qty_k,
    },
    {
      placeholder: t('_CostQtyOne_'),
      label: t('_CostQtyOne_'),
      registerProp: ['cost_qty_one', { required: t('_ErrorFill_') }],
      type: 'input',
      typeInput: 'number',
      defaultValue: componentInfo?.cost_qty_one,
    },
    {
      placeholder: t('_CostQtyTenK_'),
      label: t('_CostQtyTenK_'),
      registerProp: ['cost_qty_ten_k', { required: t('_ErrorFill_') }],
      type: 'input',
      typeInput: 'number',
      defaultValue: componentInfo?.cost_qty_ten_k,
    },
  ]), [componentInfo, t]);

  return (
    <Modal isOpen={visible} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('_Cost_')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Form
            elements={formElements}
            onClose={handleCloseModal}
            submitHandler={handleAddComponent}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddCost;
