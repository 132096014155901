import { TableContainer, Table, Thead, Tr, Th, Tbody, Td } from "@chakra-ui/react"
import { uuid } from 'utils';

const TierTable = ({tier, type}:any) => {
  const tierEntries = tier && Object.entries(tier);
  const tierKeys = type ==='DEVICE' ? ['id', 'Ownership type', 'Tier name', 'Overhead percentage', 'Margine percentage', 'R&D percentage'] : type === 'SERVICE' ? ['id', 'Tier name', 'Base rate per publish', 'Overhead percentage', 'Margine percentage', 'R&D percentage'] : '';
    return (
      <TableContainer>
      <Table size='sm'>
        <Thead>
          <Tr>
            <Th>Tier Details</Th>
          </Tr>
        </Thead>
        <Tbody>
        {tierEntries?.map((entry:any, index:any) => (
          <>
            {entry[0] !== 'hibernate_lazy_initializer' && entry[0] !== 'id' && <Tr key={uuid()} display='flex' flexDirection='row' justifyContent='space-between'>
              <Td>{tierKeys[index]}</Td>
              <Td>{entry[1]}</Td>
            </Tr>}
          </>
        ))}
        </Tbody>
      </Table>
    </TableContainer>
    )
}

export default TierTable;