import { useState, useEffect, useCallback } from 'react';

import Fetch from 'utils/fetch';
import { ResponseType } from 'models';

const useFetchData = (
  initialData: any,
  url: string,
  disableNotification: boolean = true,
) => {
  const [data, setData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const fetchData = useCallback(async () => {
    if (!url) return;
    setIsLoading(true);
    hasError && setHasError(false);

    const response: ResponseType = await Fetch.get(url);
    if (response.ok && !response.hasError) {
      setData(response.data);
    } else {
      setData(initialData);
      setHasError(true);
    }

    setIsLoading(false);
    return response;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableNotification]);

  useEffect(() => {
    let unmounted = false;

    if( !unmounted ) {
      fetchData();
    }

    return () => {
      unmounted = true
    };
    
  }, [url, fetchData]);

  return { data, fetchData, isLoading, setData, hasError };
};

export default useFetchData;
