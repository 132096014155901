import {
  Flex,
  FlexProps,
  IconButton,
  useColorModeValue,
} from '@chakra-ui/react';

import { FiMenu } from 'react-icons/fi';

import LogoComponent from 'components/Logo';

import Profile from './Profile';

interface IHeaderProps extends FlexProps {
  onOpened: () => void;
  user: any;
}

const Header = ({ onOpened, user, ...rest }: IHeaderProps) => (
  <Flex
    ml={{ base: 0, md: 60 }}
    px={{ base: 4, md: 4 }}
    height='20'
    alignItems='center'
    bg={useColorModeValue('white', 'gray.900')}
    borderBottomWidth='1px'
    borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
    justifyContent={{ base: 'space-between', md: 'flex-end' }}
    {...rest}
  >
    <IconButton
      display={{ base: 'flex', md: 'none' }}
      onClick={onOpened}
      variant='outline'
      aria-label='open menu'
      icon={<FiMenu />}
    />
    <LogoComponent display={{ base: 'flex', md: 'none' }} />
    <Profile user={user} />
  </Flex>
);

export default Header;
