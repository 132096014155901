const {
  REACT_APP_DOMAIN_URL,
  REACT_APP_BASE_URL,
  REACT_APP_CMD_URL,
  REACT_APP_GEOMETRY_ROOT_URL,
  REACT_APP_PUBLIC_MAP_KEY,
  REACT_APP_PUBLIC_MAP_DEV_KEY,
  REACT_APP_DYNAMO_URL,
}: any = process.env;

export const domainUrl = REACT_APP_DOMAIN_URL;
export const baseUrl = REACT_APP_BASE_URL;
export const cmdUrl = REACT_APP_CMD_URL;
export const geometryUrl = REACT_APP_GEOMETRY_ROOT_URL;
export const appKey = 'green_wave_iot';
export const defaultLanguage = 'en';
export const mapApiKey = REACT_APP_PUBLIC_MAP_KEY;
export const mapDevApiKey = REACT_APP_PUBLIC_MAP_DEV_KEY;
export const dynamoUrl = REACT_APP_DYNAMO_URL;
