import React, { useState, useCallback, useEffect, useRef } from 'react';
import { GoogleMap as Map, Marker, useLoadScript } from '@react-google-maps/api';

import { mapApiKey } from 'configs';
import MAP_SETTINGS from 'constants/mapSettings';

import type { GoogleMapProps, CoordinatesType } from './types';

import GoogleMapStyledWrapper from './styles';

const { DEFAULT_ZOOM, DEFAULT_CENTER, DEFAULT_MAP_OPTIONS, CONTAINERSTYLE } = MAP_SETTINGS;
const defaultCoordinates: CoordinatesType = { id: '', name: '', lat: 0, lng: 0 };
const librariesList = ['places'];

function GoogleMap({
  className,
  searchLocation,
  markerIcon,
  height,
  setNewLocation,
  newLocation,
}: GoogleMapProps) {
  // To use the Google Maps JavaScript API, you must register your app project on the Google API Console and get a Google API key which you can add to your app
  const apiKey = mapApiKey;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries: librariesList as any,
  });

  const [, setMap] = useState(null);
  const [, setSelected] = useState<Partial<CoordinatesType>>(defaultCoordinates);
  const [marker, setMarker] = useState<Partial<CoordinatesType>>(newLocation || {});

  const mapRef = useRef(null);
  const onLoad = useCallback(function callback(map) {
    mapRef.current = map;
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const panTo = useCallback((coordinates, mapRef) => {
    setMarker(coordinates);
  }, []);

  useEffect(() => {
    let unmount = false;

    if (mapRef && !unmount) {
      panTo(newLocation, mapRef);
    }

    return () => {
      unmount = true;
    };
  }, [newLocation, panTo]);

  const handleLocation = ({ id, name, lat, lng }: Partial<CoordinatesType>) => {
    setSelected({ id, name, lat, lng });
  };

  const onMapClick = useCallback(event => {
    if (setNewLocation) {
      setMarker({
        latitude: event.latLng.lat(),
        longitude: event.latLng.lng(),
      },
      );
      setNewLocation({ latitude: event.latLng.lat(), longitude: event.latLng.lng() });
    }
  }, [setNewLocation]);


  if (loadError) {
    return <div>Error loading maps.</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps...</div>;
  }

  return (
    <GoogleMapStyledWrapper className={className}>
      {isLoaded ? (
        <Map
          id="map"
          ref={mapRef}
          center={
            (searchLocation && searchLocation[0]?.latitude && searchLocation[0]?.longitude) ?
              {
                lat: parseFloat(searchLocation[0]?.latitude),
                lng: parseFloat(searchLocation[0]?.longitude),
              }
              : DEFAULT_CENTER
          }
          mapContainerStyle={height ? { width: '100%', height } : CONTAINERSTYLE}
          zoom={DEFAULT_ZOOM}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={DEFAULT_MAP_OPTIONS}
          onClick={onMapClick}
        >
          {!!searchLocation?.length && searchLocation?.map(({ id, name, latitude, longitude }) => (
            <Marker
              key={id || latitude}
              position={{ lat: latitude ? parseFloat(latitude) : 0, lng: longitude ? parseFloat(longitude) : 0 }}
              onClick={() => handleLocation({ id, name, lat: latitude, lng: longitude })}
              icon={
                {
                  url: markerIcon || '/location.svg',
                  scale: 2,
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(8, 10),
                } as any
              }
            />
          ))}
          {setNewLocation && (marker?.latitude && marker?.longitude) && (
            <Marker
              position={{
                lat: marker?.latitude ? parseFloat(marker?.latitude) : 0,
                lng: marker?.longitude ? parseFloat(marker?.longitude) : 0,
              }}
              onClick={() => handleLocation({ lat: marker?.latitude, lng: marker?.longitude })}
              icon={
                {
                  url: markerIcon || '/location.svg',
                  scale: 2,
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(8, 10),
                } as any
              }
            />
          )}
          {/* {destinations &&
            destination?.map(destined => (
              <Marker
                key={destined?.id || destined?.latitude}
                position={{ lat: destined?.latitude, lng: destined?.longitude }}
                onClick={() => {
                  setSelected(destined);
                }}
                icon={
                  {
                    url: '/location.svg',
                    scale: 2,
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(8, 10),
                  } as any
                }
              />
            ))} */}
          {/* {selected ? (
            <InfoWindow
              position={{ lat: parseFloat(selected?.lat), lng: parseFloat(selected?.lng) }}
              onCloseClick={() => {
                setSelected(null);
              }}
              options={{ maxWidth: 250 }}
            >
              {markerIcon ? (
                <h3 className="m-2">Welcome to TenMileZone</h3>
              ) : (
                <MarkerInfo quality={data} location={selected} />
              )}
            </InfoWindow>
          ) : null} */}
        </Map>) : <></>}
    </GoogleMapStyledWrapper>
  );
}

export default React.memo(GoogleMap);
