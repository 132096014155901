import { extendTheme, theme as base, withDefaultColorScheme, withDefaultProps, withDefaultVariant } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const brandRing = {
  _focus: {
    ring: 2,
    ringColor: 'brand.500',
  }
};

const theme = extendTheme({
  colors: {
    error: 'red.500',
    success: 'green.500',
    text: {
      default: 'gray.900',
      _light: 'gray.200',
      _dark: 'black',
    },
    brand: {
      50: '#f5fee5',
      100: '#F5F5F5',
      200: '#cdf781',
      300: '#b8ee56',
      400: '#a2e032',
      500: '#22C35E',
      600: '#00AB39',
      700: '#16b54b',
      800: '#153e75',
      900: '#1a365d',
    }
  },
  fonts: {
    heading: `Montserrat, ${base.fonts?.heading}`,
    body: `Montserrat, ${base.fonts?.body}`,
  },
  components: {
    Button: {
      variants: {
        primary: (props: any) => ({
          ...brandRing,
          color: mode('white', 'gray.700')(props),
          backgroundColor: mode('brand.500', 'brand.200')(props),
          _hover: {
            backgroundColor: mode('brand.600', 'brand.300')(props),
            transform: 'scale(0.98)',
            transition: '0.5s ease-out',
          },
          _active: {
            backgroundColor: mode('brand.600', 'brand.400')(props),
            transform: 'scale(0.98)',
            transition: '0.5s ease-out',
          }
        }),
        secondary: (props: any) => ({
          _focus: {
            ring: 2,
            ringColor: 'none',
          },
          color: mode('brand.800', 'brand.600')(props),
          backgroundColor: mode('none', 'none')(props),
          _hover: {
            backgroundColor: mode('grey.50', 'gray.100')(props),
            color: mode('brand.700', 'brand.700')(props),
            transition: '0.4s ease-out',
          },
          _active: {
            backgroundColor: mode('gray.50', 'gray.100')(props),
            color: mode('brand.700', 'brand.700')(props),
            transition: '0.4s ease-out',
          }
        }),
      },
    },
    Input: {
      baseStyle: {
        field: {
          bg: 'gray.50',
          ringColor: 'gray.300',
          focusBorderColor: 'grey.300',
          border: '1px solid gray.300',
          _hover: {
            bg: 'gray.50',
          },
          _focus: {
            focusBorderColor: 'brand.500',
            borderColor: 'brand.500',
          },
          _autofill: {
            border: '1px solid transparent',
            textFillColor: 'gray.700',
            focusBorderColor: 'brand.500',
          },
        },
      },
      variants: {
        filled: {
          field: {
            bg: 'gray.50',
            ringColor: 'gray.200',
            focusBorderColor: 'brand.500',
            borderColor: 'brand.500',
            _hover: {
              bg: 'gray.50',
            },
            _focus: {
              bg: 'gray.50',
              focusBorderColor: 'brand.500',
              borderColor: 'brand.500',
            },
            _autofill: {
              border: '1px solid transparent',
              textFillColor: 'gray.800',
              focusBorderColor: 'brand.500',
            },
          },
        }
      },
    },
    Select: {
      baseStyle: {
        field: {
          bg: 'gray.50',
          ringColor: 'gray.200',
          focusBorderColor: 'brand.500',
          borderColor: 'gray.200',
          _hover: {
            bg: 'gray.50',
          },
          _focus: {
            bg: 'gray.50',
            focusBorderColor: 'brand.500',
            borderColor: 'brand.500',
          },
        },
      },
      variants: {
        filled: {
          field: {
            bg: 'gray.50',
            ringColor: 'gray.200',
            focusBorderColor: 'brand.500',
            borderColor: 'gray.200',
            _hover: {
              bg: 'gray.50',
            },
            _focus: {
              bg: 'gray.50',
              focusBorderColor: 'brand.500',
              borderColor: 'brand.500',
            },
          },
        }
      },
    },
    Checkbox: {
      baseStyle: {
        control: { ...brandRing }
      }
    },
    Toast: {
      variants: {
        information: (props: any) => ({
          color: mode('gray.800', 'white')(props),
          backgroundColor: 'brand.500',
        }),
      },
    },
  },
},

  withDefaultColorScheme({
    colorScheme: 'brand',
    components: ['Checkbox', 'Input', 'Select'],
  }),
  withDefaultVariant({
    variant: 'filled',
    components: ['Input', 'Select'],
  }),
  withDefaultProps({
    defaultProps: {
      variant: 'outline',
      size: 'lg',
      focusBorderColor: 'gray.500',
    },
    components: ['Input', 'NumberInput', 'TextInput', 'Select'],
  }),
);

export default theme;