import { JSXElementConstructor, MouseEventHandler, ReactElement } from 'react';
import { MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { uuid } from 'utils';

export type ActionBarWrapOptionType = {
  name?: string;
  icon: ReactElement<any, string | JSXElementConstructor<any>>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  Component?: ReactElement;
};

export type ActionBarWrapPropsType = {
  options: Array<any>;
};

const ActionBarWrap = ({ options }: ActionBarWrapPropsType) => (
  <Portal>
    <MenuList>
      {options.map(({ name, icon, onClick, Component }: ActionBarWrapOptionType) => (
        <MenuItem icon={icon} key={name || uuid()} onClick={onClick}>
          {Component || name}
        </MenuItem>
      ))}
    </MenuList>
  </Portal>
);

export default ActionBarWrap;