import styled from '@emotion/styled';

import { backgroundColor, borderRadius } from 'styles/variables';

export const SignInStyle = styled.div`
with: 100%;
input {
  border: 1px solid #D4D4D4;
}
@media screen and (max-width: 700px) {
  .rp-sign-up {
    flex-direction: column;
  }
  .rp-inp {
    margin: 0;
    padding-top: 16px
  }
}
`;
export const SignUpStyle = styled.div``;

export const SplashScreenStyle = styled.div`
  width: 100%;
  .splash-screen-holder {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 6rem;
    background: ${backgroundColor};
    color: #fff;

    .splash-content {
      height: 100%;
      max-width: 65%;
      padding: 12rem 10rem 0 0;
      display: flex;
      flex-direction: column;

      .logo {
        width: 50%;
        margin-bottom: 2rem;
      }

      .splash-content-body {
        max-width: 100rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h4 {
          font-size: 2rem;
        }
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .splash-screen-holder {
      flex-direction: column;
      height: 100vh;
      .splash-content {
        padding: 0;
        max-width: 100%;
        .logo_ {
          justify-content: center;
        }
        .splash-content-title {
          text-align: center;
          padding: 0 0 20px 0;
        }
      }
    }
    .sign-up-content {
      height: -webkit-fill-available;
    }
  }
  @media screen and (max-width: 700px) {
    .splash-screen-holder {
      padding: 2rem 2rem;
      .splash-content {
        .splash-content-title {
          h4 {
            font-size: 20px
          }
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .splash-screen-holder {
      padding: 2rem 1rem;
      .splash-content {
        .splash-content-title {
          h4 {
            font-size: 18px
          }
        }
      }
    }
  }
`;

export const LoginComponentStyle = styled.div`
  min-width: 35%;
  background-color: white;
  border: 1px solid #D4D4D4;
  padding: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 12px #00000057;
  border-radius: ${borderRadius};
  color: #000;

  input {
    border: 1px solid #D4D4D4;
  }

  &.mobile {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      width: 100%;
    }
  }

  input {
    width: 100%;
  }
`;
