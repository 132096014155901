import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useToast,
  Button,
  Text,
  Input,
  ModalFooter,
  FormLabel,
  InputGroup,
  FormControl,
  Tooltip,
} from '@chakra-ui/react';
import { SiMicrosoftexcel } from 'react-icons/si';

import { EXCELL_ESTIMATE } from 'constants/api';
import { ERROR, SUCCESS } from 'constants/toaster';
import { useTranslator } from 'utils/translator';

import { uploadPropsType, uploadFormType } from './types';

const Upload = ({
  closeModal,
}: uploadPropsType) => {
  const { t } = useTranslator();
  const toast = useToast();

  const [isLoading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [isFilePicked, setIsFilePicked] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<uploadFormType>();

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    event.target.files[0] && setIsFilePicked(true);
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const uploadFile = async (data: uploadFormType) => {
    if (!isFilePicked) return;
    setLoading(true);
    const formData = new FormData();

    formData.append('file', selectedFile);

    const response = await fetch(`${EXCELL_ESTIMATE}?sheetName=${data.sheetName}`, {
      method: 'POST',
      body: formData
    });

    setLoading(false);

    if (response?.ok) {
      toast(SUCCESS);
      handleCloseModal();
    } else {
      toast(ERROR);
    }
  };

  const handleUploadFile: SubmitHandler<uploadFormType> = async data => uploadFile(data);

  return (
    <Modal isOpen onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleUploadFile)}>
          <ModalHeader>{t('_UploadDatabase_')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <InputGroup size='md' variant='outline' w='100%' marginBottom='20px'>
              <Input type='file' id='upload' w='100%' name='file' onChange={e => changeHandler(e)} />
              <FormLabel
                htmlFor='upload'
                background='#00AB39 0% 0% no-repeat padding-box'
                position='absolute'
                width='127px'
                height='40px'
                display='flex'
                justifyContent='center'
                alignItems='center'
                color='#FFFFFF'
                fontStyle='normal normal bold 18px/20px Arial'
                borderRadius='10px'
                zIndex='10'>
                <Text marginRight='5px'>Upload</Text>
                <SiMicrosoftexcel />
              </FormLabel>
            </InputGroup>
            <FormControl isInvalid={!!errors.sheetName} marginBottom={2}>
              <FormLabel>{t('_SheetName_') + ' *'}</FormLabel>
              <Tooltip label={errors?.sheetName?.message}>
                <Input
                  placeholder={t('_SheetName_')}
                  {...register('sheetName', {
                    required: t('_ErrorFill_'),
                  })}
                />
              </Tooltip>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => handleCloseModal()} mr={3}>{t('_Cancel_')}</Button>
            <Button
              isLoading={isLoading}
              loadingText="Uploading"
              variant='primary'
              type='submit'
            >
              {t('_Submit_')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default Upload;
