import { isString } from 'utils';

import { SectionStyle } from './style';

const Section = ({ children, title, actionBar }: any) => (
  <SectionStyle>
    <div className="section-header">
      {isString(title)
        ? <p className="section-title">{title}</p>
        : <div className="section-title">{title}</div>
      }
      <div className="action-bar">
        {actionBar}
      </div>
    </div>
    <div className="section-content">
      {children}
    </div>
  </SectionStyle>
);

export default Section;
