import { useEffect, useMemo } from 'react';
import {
  Flex,
  Box,
  Text,
  TableContainer,
  Table, 
  Tr, 
  Tbody, 
  Td,
  Select
} from '@chakra-ui/react';
import { CloseIcon, CheckIcon } from '@chakra-ui/icons'

import BreadcrumbWrapper from 'components/Breadcrumb';
import Section from 'components/Section';

import { useTranslator } from 'utils/translator';
import { useFetchOnce, useQuery, useFetchData } from 'utils/hooks';
import { POST } from 'constants/method';
import { CUSTOMER_TIER_ASSOCIATION, DEVICE_TIER, SERVICE_TIER } from 'constants/api';

import TierTable from './TierTable';

const CustDetails = () => {
  const { t } = useTranslator();

  const { orgName } = useQuery(['orgName']);
  const { data: customerDetails, fetchData:fetchDetails } = useFetchOnce({}, POST);
  const { data: deviceTiers } = useFetchData({ content: [] }, `${DEVICE_TIER}`);
  const { data: serviceTiers } = useFetchData({ content: [] }, `${SERVICE_TIER}`);

  useEffect(()=>{
    fetchDetails(`${CUSTOMER_TIER_ASSOCIATION}/customer-details?organizationName=${orgName}`);
  },[fetchDetails, orgName])

  const TitleComponent = useMemo(() => (
    <BreadcrumbWrapper items={[
      { name: t('_CustomerList_'), path: '/dynamo/customer-list' },
      { name: t('_CustomerDetails_') },
      { name: orgName },
    ]}
    />
  ), [orgName, t]);

  const handleSelect = (e:any, type:string) => {
    const tierType = type === 'service' ? 
    'associate-device-service-tier-with-greenwave-organization?deviceServiceTierId':
    type === 'device' ? 'associate-device-cost-tier-with-greenwave-organization?deviceCostTierId':''
    fetchDetails(`${CUSTOMER_TIER_ASSOCIATION}/${tierType}=${e.target.value}&organizationName=${orgName}`);
    fetchDetails(`${CUSTOMER_TIER_ASSOCIATION}/customer-details?organizationName=${orgName}`);
  }

  return (
    <Section title={TitleComponent}>
      <Box padding={4}>
        <Flex flexDirection='column'>
          <Flex flexDirection='row' justifyContent='space-between'>
            <Box 
            bg='#FFFFFF 0% 0% no-repeat padding-box'
            w='38vw'
            p={4}
            boxShadow='0px 0px 6px #00000033'
            opacity='1'
            border='1px solid #CBCBCB'
            borderRadius='10px'
            borderTop='10px solid #007B00'
            >
              <Flex position='relative' flexDirection='column'>
                <Flex alignItems='center' marginBottom='10px' flexDirection='row'justifyContent='space-between'>
                  <Text textColor='#048000' paddingLeft='16px'>Device Cost Tier</Text>
                  <Select 
                  onChange={(e:any) =>handleSelect(e, 'device')} 
                  variant="primary" 
                  size='lg' 
                  width='12vw' 
                  placeholder='Select Tier'
                  boxShadow='0px 0px 6px #00000033'
                  border='1px solid #CBCBCB'
                  background='#FFFFFF 0% 0% no-repeat padding-box'
                  >
                    {deviceTiers?.content?.map((tier:any) => (
                      <option key={tier.id} value={tier.id} >{tier.tier_name}</option>
                    ))}
                  </Select>
                </Flex>
                <TierTable type='DEVICE' tier={customerDetails?.device_cost_tier} />
              </Flex>
            </Box>
            <Box 
            bg='#FFFFFF 0% 0% no-repeat padding-box'
            w='38vw'
            p={4}
            boxShadow='0px 0px 6px #00000033'
            opacity='1'
            border='1px solid #CBCBCB'
            borderRadius='10px'
            borderTop='10px solid #005DFF'
            >
              <Flex position='relative' flexDirection='column'>                               
                <Flex alignItems='center' marginBottom='10px' flexDirection='row'justifyContent='space-between'>
                  <Text textColor='#048000' paddingLeft='16px'>Service Cost Tier</Text>
                  <Select 
                  onChange={(e:any) =>handleSelect(e, 'service')} 
                  variant="primary" 
                  size='lg' 
                  width='12vw' 
                  placeholder='Select Tier'
                  boxShadow='0px 0px 6px #00000033'
                  border='1px solid #CBCBCB'
                  background='#FFFFFF 0% 0% no-repeat padding-box'
                  >
                    {serviceTiers?.content?.map((tier:any) => (
                      <option key={tier.id} value={tier.id} >{tier.tier_name}</option>
                    ))}
                  </Select>
                </Flex>
                <TierTable type='SERVICE' tier={customerDetails?.device_service_tier} />
              </Flex>
            </Box>
          </Flex>
          <Flex marginTop='20px' className='customerInfo' flexDirection='row' justifyContent='space-between'>
            <Box 
            bg='#FFFFFF 0% 0% no-repeat padding-box'
            w='38vw'
            p={4}
            boxShadow='0px 0px 6px #00000033'
            opacity='1'
            border='1px solid #CBCBCB'
            borderRadius='10px'
            borderTop='10px solid #8800FE'
            >
              <TableContainer>
                <Table>
                  <Tbody>
                    <Tr display='flex' flexDirection='row' justifyContent='space-between'>
                      <Td>Customer Name</Td>
                      <Td>{customerDetails.customer_name}</Td>
                    </Tr>
                    <Tr display='flex' flexDirection='row' justifyContent='space-between'>
                      <Td>Customer Type</Td>
                      <Td>{customerDetails.customer_type}</Td>
                    </Tr>
                    <Tr display='flex' flexDirection='row' justifyContent='space-between'>
                      <Td>Customer Email</Td>
                      <Td>{customerDetails.customer_email}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
            <Flex className='WaveApp' flexDirection='column' justifyContent='space-between'>
              <Box 
              bg='#FFFFFF 0% 0% no-repeat padding-box'
              w='38vw'
              p={4}
              boxShadow='0px 0px 6px #00000033'
              opacity='1'
              border='1px solid #CBCBCB'
              borderRadius='10px'
              borderLeft='10px solid #FFB300'
              h='75px'
              >
                <Flex h='100%' alignItems='center' flexDirection='row' justifyContent='space-between'>
                  <Text>Wave System Account</Text>
                  {customerDetails?.wave_system_account_id ? <CheckIcon color='#00AB39' /> : <CloseIcon color='#D40000' />}
                </Flex>
              </Box>
              <Box 
              bg='#FFFFFF 0% 0% no-repeat padding-box'
              w='38vw'
              p={4}
              boxShadow='0px 0px 6px #00000033'
              opacity='1'
              border='1px solid #CBCBCB'
              borderRadius='10px'
              borderLeft='10px solid #00FF90'
              h='75px'
              >
                <Flex h='100%'  alignItems='center' flexDirection='row' justifyContent='space-between'>
                  <Text>GREENWAVE Organization </Text>
                  {customerDetails?.green_wave_organization_id ? <CheckIcon color='#00AB39' /> : <CloseIcon color='#D40000' />}
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </Flex>
        </Box>
        </Section>
  );
};

export default CustDetails;


