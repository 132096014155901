const dataDuration = (dateTwo: any) => {
  const date1 = new Date();
  const date2 = new Date(dateTwo);

  const Difference_In_Time = date1.getTime() - date2.getTime();

  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  return Math.round(Difference_In_Days);
};

export default dataDuration;
