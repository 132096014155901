import React, { useState, Suspense, useCallback, useEffect, useMemo } from 'react';
import { Button, useDisclosure, useToast, Box, Flex, Spacer } from '@chakra-ui/react';
import { SubmitHandler } from 'react-hook-form';
import { HiOutlineFilter } from 'react-icons/hi';

import Filter from 'components/Filter';
import Section from 'components/Section';
import BreadcrumbWrapper from 'components/Breadcrumb';

import { GET } from 'constants/method';
import { DATADETAILS } from 'constants/api';
import { useTranslator } from 'utils/translator';
import { ERROR, SUCCESS } from 'constants/toaster';
import { useFetchOnce, useQuery } from 'utils/hooks';
import descIcons, { dateDesc, periodDesc, risksDesc } from 'constants/chartIcons';

import CmdButtons from './CmdButtons';

import { DetailsFilterType } from './types';
import { scrollbarCss } from '../LocationsMap/configs';
import CircleIndicators from './CircleIndicators';
//import QRCode from './QRCode';

const FusionChart = React.lazy(() => import('./FusionCharts'));

function Details() {
  const { t } = useTranslator();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    unitType,
    historical,
    deviceId,
    deviceName,
    roomId,
    roomName,
    locationId,
    locationName,
  } = useQuery(['unitType', 'historical', 'deviceId', 'deviceName', 'roomId', 'roomName', 'locationName', 'locationId']);

  const [selectedIconType, setSelectedIconType] = useState(descIcons[0].key);
  const [selectedCharType, setSelectedCharType] = useState('risks');
  const [selectedDate, setSelectedDate] = useState(historical);

  const { data, fetchData: fetchFilterDetail, hasError } = useFetchOnce({}, GET);
  const { data: chartData, fetchData: fetchDataDevice, hasError: hasErrorChartData } = useFetchOnce([], GET);

  useEffect(() => {
    fetchFilterDetail(`${DATADETAILS}/v2/quality/air?historical=${historical}&id=${deviceId}&unitType=${unitType}`);
    fetchDataDevice(`${DATADETAILS}/averages/${unitType.toLowerCase()}/${deviceId}/historical?historical=${historical}`);
  }, [historical, deviceId, unitType, fetchFilterDetail, fetchDataDevice]);

  const TitleComponent = useMemo(() => (
    <BreadcrumbWrapper items={[
      { name: t('_Locations_'), path: '/cmd/locations' },
      { name: locationName, path: '/cmd/locations' },
      { name: roomName, path: `/cmd/rooms?locationName=${locationName}&locationId=${locationId}` },
      { name: deviceName, path: `/cmd/devices?roomId=${roomId}&roomName=${roomName}&locationName=${locationName}&locationId=${locationId}` },
      { name: t('_DeviceDetails_') }
    ]}
    />
  ), [locationId, locationName, roomName, roomId, deviceName, t]);

  const handleFilter: SubmitHandler<DetailsFilterType> = useCallback(async ({ risk, date, period }: DetailsFilterType) => {
    if (risk || date || period) {
      if (date && period) {
        await fetchFilterDetail(period === 'Last' ?
          `${DATADETAILS}/v2/quality/air/now?id=${deviceId}&unitType=${unitType}` :
          `${DATADETAILS}/v2/quality/air?historical=${date}&id=${deviceId}&unitType=${unitType}`);
        await fetchDataDevice(`${DATADETAILS}/averages/${unitType.toLowerCase()}/${deviceId}/historical?historical=${date}`);

        setSelectedDate(date);
      } else {
        if (date) {
          await fetchFilterDetail(`${DATADETAILS}/v2/quality/air?historical=${date}&id=${deviceId}&unitType=${unitType}`);
          await fetchDataDevice(`${DATADETAILS}/averages/${unitType.toLowerCase()}/${deviceId}/historical?historical=${date}`);

          setSelectedDate(date);
        }

        period && await fetchFilterDetail(period === 'Last' ?
          `${DATADETAILS}/v2/quality/air/now?id=${deviceId}&unitType=${unitType}` :
          `${DATADETAILS}/v2/quality/air?historical=${historical}&id=${deviceId}&unitType=${unitType}`);
      };

      risk && setSelectedCharType(risk);

      if (!hasError || !hasErrorChartData) {
        toast(SUCCESS);
      } else {
        toast(ERROR);
      }

      onClose();
    }
  }, [hasError, hasErrorChartData, onClose, fetchFilterDetail, deviceId, unitType, fetchDataDevice, historical, toast]);

  const handleSelectedIconType = useCallback((iconType: string) => {
    setSelectedIconType(iconType);
  }, []);

  return (
    <>
      <Section
        title={TitleComponent}
        actionBar={(
          <>
            {/* <QRCode /> */}
            <Button size='md' variant='primary' onClick={onOpen}>
              <HiOutlineFilter />
              {t('_Filter_')}
            </Button>
          </>
        )}
      >
        <Flex p='3' direction='column' overflowY='auto' maxHeight='70vh' css={scrollbarCss}>
          <Flex flex='1'>
            <Box flex='1'>
              <CmdButtons measure={data} selectedIcon={selectedIconType} handleSelectedIcon={handleSelectedIconType} />
            </Box>
            <Box flex='3' ml='3' borderRadius='10px'>
              <Box flex='1' flexDirection='column'>
                <Suspense fallback={<div>Loading...</div>}>
                  <CircleIndicators data={data} type={selectedCharType} />
                </Suspense>
                <Suspense fallback={<div>Loading...</div>}>
                  <FusionChart data={chartData} selectedType={selectedIconType} date={selectedDate} />
                </Suspense>
              </Box>
            </Box>
          </Flex>
          <Spacer />
        </Flex>
      </Section>
      <Filter isOpen={isOpen} onClose={onClose} submitHandler={handleFilter}
        elements={[
          {
            placeholder: t('_FilterByDate_'),
            registerProp: ['date'],
            options: dateDesc,
            label: t('_FilterByDate_'),
            type: 'select',
          },
          {
            placeholder: t('_FilterByPeriod_'),
            registerProp: ['period'],
            options: periodDesc,
            label: t('_FilterByPeriod_'),
            type: 'select',
          },
          {
            placeholder: t('_FilterByRisk_'),
            registerProp: ['risk'],
            options: risksDesc,
            label: t('_FilterByRisk_'),
            type: 'select',
          },
        ]} />
    </>
  );
}

export default Details;
