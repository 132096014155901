import { useCallback, useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const ALed = () => {
  const { ref, inView } = useInView({ threshold: 0.2, triggerOnce: true })

  const road = useAnimation()
  const home = useAnimation()
  const wire = useAnimation()
  const bulb = useAnimation()
  const bulbInner = useAnimation()
  const leaves = useAnimation()
  const sun = useAnimation()
  const app = useAnimation()

  const animateIn = useCallback(() => {
    //* Animation
    road.start({ opacity: 1, transition: { duration: 1, delay: 0 } })
    home.start({ opacity: 1, transition: { duration: 1, delay: 1 } })
    app.start({ opacity: 1, transition: { duration: 1, delay: 2 } })
    sun.start({ opacity: 1, transition: { duration: 1, delay: 3 } })
    bulb.start({ opacity: 1, transition: { duration: 1, delay: 3 } })
    wire.start({ pathLength: 1, transition: { duration: 1, delay: 4 } })
    bulbInner.start({
      stroke: '#707070',
      fill: '#e6f600',
      transition: { duration: 1, delay: 5 },
    })
    leaves.start({ opacity: 1, transition: { duration: 1, delay: 4 } })
  }, [road, home, app, sun, bulb, bulbInner, wire, leaves])

  const animateOut = useCallback(() => {
    //* Setting Initials
    road.set({ opacity: 0 })
    home.set({ opacity: 0 })
    app.set({ opacity: 0 })
    wire.set({ pathLength: 0 })
    bulb.set({ opacity: 0 })
    bulbInner.set({ fill: 'transparent', stroke: 'none' })
    leaves.set({ opacity: 0 })
    sun.set({ opacity: 0 })
  }, [road, home, app, wire, bulb, bulbInner, leaves, sun])

  useEffect(() => {
    if (inView) animateIn()
    else animateOut()
  }, [inView, animateIn, animateOut])

  return (
    <svg
      ref={ref}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='250'
      height='250'
      viewBox='0 0 598.498 411.535'
    >
      <defs>
        <filter
          id='Rectangle_3238'
          x='17.873'
          y='363.38'
          width='527'
          height='48.155'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' result='blur' />
          <feFlood flood-opacity='0.89' />
          <feComposite operator='in' in2='blur' />
          <feComposite in='SourceGraphic' />
        </filter>
      </defs>
      <g
        id='Group_1759'
        data-name='Group 1759'
        transform='translate(-105.997 -2252.833)'
      >
        <g id='Group_1733' data-name='Group 1733'>
          {/* Road */}
          <motion.g
            animate={road}
            id='Group_1624'
            data-name='Group 1624'
            transform='translate(-606.385 477.408)'
          >
            <g
              transform='matrix(1, 0, 0, 1, 712.38, 1775.43)'
              filter='url(#Rectangle_3238)'
            >
              <path
                id='Rectangle_323-2'
                data-name='Rectangle 323'
                d='M22.04,36.155H504.96c1.8,0,3.359-.056,4.633-.158a17.119,17.119,0,0,0,2.924-.428c.662-.18,1-.394.979-.63s-.4-.493-1.174-.763L434.828,7.168a13,13,0,0,0-2.1-.458c-.9-.14-1.989-.266-3.185-.371s-2.5-.19-3.848-.248S422.964,6,421.6,6H55.832c-1.364,0-2.7.032-3.95.091s-2.418.143-3.439.248-1.9.231-2.576.371a3.667,3.667,0,0,0-1.343.458l-33.1,27.008c-.33.27-.286.527.08.763a6.257,6.257,0,0,0,2.015.63A32.752,32.752,0,0,0,17.148,36C18.589,36.1,20.24,36.155,22.04,36.155Z'
                transform='translate(17.87 363.38)'
                fill='#434343'
              />
            </g>
          </motion.g>
          {/* Wire */}
          <motion.path
            animate={wire}
            id='Path_20587'
            data-name='Path 20587'
            d='M-13.5,79.5s21-36,6-51-20.083-34.3,0-44c54.871-26.5,111.64,34,182,34,55.442,0,69.485-54.791,147.735-54.791s67.076,90.76,108.392,104.458'
            transform='translate(163.747 2291.624)'
            fill='none'
            stroke='#434343'
            stroke-width='5'
            stroke-dasharray='2'
          />
          <g
            id='Group_1626'
            data-name='Group 1626'
            transform='translate(-585.003 728.124)'
          >
            {/* Bulb outline */}
            <motion.g
              animate={bulb}
              id='lamp-svgrepo-com_2_'
              data-name='lamp-svgrepo-com (2)'
              transform='translate(691 1641)'
            >
              <g
                id='Group_1618'
                data-name='Group 1618'
                transform='translate(0)'
              >
                <path
                  id='Path_20574'
                  data-name='Path 20574'
                  d='M165.167,72.2,153.917,4.7A5.624,5.624,0,0,0,148.368,0H80.87a5.625,5.625,0,0,0-5.548,4.7L64.072,72.2a5.625,5.625,0,0,0,5.548,6.55h9.443a39.373,39.373,0,1,0,71.111,0h9.443A5.625,5.625,0,0,0,165.167,72.2ZM142.742,95.622a28.124,28.124,0,1,1-50.58-16.875h44.913A27.872,27.872,0,0,1,142.742,95.622ZM139.722,67.5H76.261L85.635,11.25H143.6L152.978,67.5Z'
                  transform='translate(-63.994)'
                  fill='#434343'
                />
              </g>
            </motion.g>
            {/* Bulb inner */}
            <motion.path
              animate={bulbInner}
              id='Path_20575'
              data-name='Path 20575'
              d='M2109.259,7850H2169.3l9.114,56.688H2098Z'
              transform='translate(-1397.244 -6198.19)'
              fill='#e6f600'
              stroke='#707070'
              stroke-width='1'
            />
            <motion.path
              animate={bulbInner}
              id='Subtraction_15'
              data-name='Subtraction 15'
              d='M28.466,46.581a26.553,26.553,0,0,1-11.08-2.418,27.865,27.865,0,0,1-4.835-2.837,29.279,29.279,0,0,1-4.213-3.758,30.6,30.6,0,0,1-3.476-4.554,31.61,31.61,0,0,1-2.625-5.227A32.731,32.731,0,0,1,0,15.809a33.178,33.178,0,0,1,1.058-8.34A31.96,31.96,0,0,1,4.039,0H52.894a31.96,31.96,0,0,1,2.981,7.469,33.18,33.18,0,0,1,1.058,8.34A32.732,32.732,0,0,1,54.7,27.787a31.62,31.62,0,0,1-2.625,5.227A30.6,30.6,0,0,1,48.6,37.568a29.281,29.281,0,0,1-4.213,3.758,27.868,27.868,0,0,1-4.835,2.837,26.554,26.554,0,0,1-11.08,2.418Z'
              transform='translate(713.644 1719.283)'
              fill='#e6f600'
            />
            {/*  */}
          </g>
          {/* Sun */}
          <motion.g
            animate={sun}
            id='Group_1625'
            data-name='Group 1625'
            transform='translate(-38.625 422.124)'
          >
            <path
              id='sun'
              d='M1037.083,255.917h-9.833a4.917,4.917,0,1,1,0-9.833h9.833a4.917,4.917,0,1,1,0,9.833Zm-22.154-35.636a4.332,4.332,0,0,1-6.126-6.126l6.126-6.121a4.328,4.328,0,1,1,6.122,6.121ZM983,275.583A24.583,24.583,0,1,1,1007.583,251,24.583,24.583,0,0,1,983,275.583Zm0-39.333A14.75,14.75,0,1,0,997.75,251,14.75,14.75,0,0,0,983,236.25Zm0-24.583a4.917,4.917,0,0,1-4.917-4.917v-9.833a4.917,4.917,0,0,1,9.833,0v9.833A4.917,4.917,0,0,1,983,211.667Zm-36.846,82.3a4.328,4.328,0,0,1-6.121-6.121l6.121-6.126a4.332,4.332,0,0,1,6.126,6.126Zm0-73.686-6.121-6.126a4.328,4.328,0,0,1,6.121-6.121l6.126,6.121a4.332,4.332,0,1,1-6.125,6.126ZM943.667,251a4.917,4.917,0,0,1-4.917,4.917h-9.833a4.917,4.917,0,0,1,0-9.833h9.833A4.917,4.917,0,0,1,943.667,251ZM983,290.333a4.917,4.917,0,0,1,4.917,4.917v9.833a4.917,4.917,0,1,1-9.833,0V295.25A4.917,4.917,0,0,1,983,290.333Zm36.845-8.614,6.122,6.126a4.328,4.328,0,1,1-6.121,6.121l-6.127-6.121a4.332,4.332,0,0,1,6.126-6.126Z'
              transform='translate(-343.128 1707)'
              fill='#434343'
              fill-rule='evenodd'
            />
            <ellipse
              id='Ellipse_1266'
              data-name='Ellipse 1266'
              cx='16'
              cy='15.5'
              rx='16'
              ry='15.5'
              transform='translate(623.873 1942.5)'
              fill='#e6f600'
            />
          </motion.g>
          {/* Leaves left */}
          <motion.g
            animate={leaves}
            id='leaves-svgrepo-com'
            transform='translate(177.875 2470.818)'
          >
            <g
              id='Group_1623'
              data-name='Group 1623'
              transform='translate(0 35.934)'
            >
              <path
                id='Path_20577'
                data-name='Path 20577'
                d='M284.57,317.82a32.118,32.118,0,0,1,10.842-7.159c1.38-.595,3.975-2.118,5.544-2.006-6.332-.517-13.048-.872-19.089,1.463-5.277,2.037-9.556,6.078-12.867,10.573a78.967,78.967,0,0,0-5.514,9.464c-2.149,3.963-4.978,9.81-9.6,11.413a31.883,31.883,0,0,0,16.136-6.881C275.881,329.974,279.477,323.257,284.57,317.82Z'
                transform='translate(-183.989 -233.301)'
                fill='#00af40'
              />
              <path
                id='Path_20578'
                data-name='Path 20578'
                d='M306.811,315.361c-6.575,3.5-10.753,7.945-15.253,13.729-3.273,4.2-6.968,9.022-11.548,11.9-3.624,2.278-8.847,4.988-13.2,5.386a32.5,32.5,0,0,0,33.706-.206c9.49-5.808,13-15.414,18.368-24.511a31.344,31.344,0,0,1,6.376-7.672c.59-.515,2.453-2.5,3.344-2.466C321.145,310.916,313.477,311.809,306.811,315.361Z'
                transform='translate(-193.358 -235.528)'
                fill='#00af40'
              />
              <path
                id='Path_20579'
                data-name='Path 20579'
                d='M267.884,191.5a67.436,67.436,0,0,0-4.718,8.1c-1.839,3.39-4.26,8.395-8.218,9.766a27.272,27.272,0,0,0,13.807-5.889c5.018-4.031,8.094-9.779,12.452-14.431a27.5,27.5,0,0,1,9.276-6.127c1.18-.509,3.4-1.812,4.743-1.716-5.418-.443-11.165-.745-16.333,1.251C274.378,184.2,270.717,187.657,267.884,191.5Z'
                transform='translate(-184.763 -140.977)'
                fill='#00af40'
              />
              <path
                id='Path_20580'
                data-name='Path 20580'
                d='M316.395,186.144c.505-.44,2.1-2.139,2.862-2.11-6.381-.519-12.942.246-18.646,3.285-5.626,3-9.2,6.8-13.051,11.747-2.8,3.6-5.962,7.721-9.882,10.184-3.1,1.95-7.57,4.268-11.3,4.609a27.806,27.806,0,0,0,28.841-.177c8.119-4.969,11.125-13.188,15.717-20.972A26.765,26.765,0,0,1,316.395,186.144Z'
                transform='translate(-193.049 -143.154)'
                fill='#00af40'
              />
              <path
                id='Path_20581'
                data-name='Path 20581'
                d='M82.783,317.819c5.093,5.438,8.69,12.154,14.553,16.866a31.873,31.873,0,0,0,16.136,6.881c-4.626-1.6-7.455-7.45-9.6-11.413a78.919,78.919,0,0,0-5.514-9.464c-3.312-4.5-7.591-8.537-12.867-10.574-6.041-2.334-12.757-1.979-19.089-1.462,1.569-.113,4.164,1.41,5.543,2.005A32.122,32.122,0,0,1,82.783,317.819Z'
                transform='translate(-48.119 -233.3)'
                fill='#00af40'
              />
              <path
                id='Path_20582'
                data-name='Path 20582'
                d='M9.72,321.664c5.366,9.1,8.879,18.7,18.368,24.51a32.5,32.5,0,0,0,33.706.206c-4.354-.4-9.577-3.109-13.2-5.386-4.581-2.879-8.275-7.7-11.548-11.9-4.5-5.784-8.678-10.227-15.253-13.728C15.125,311.812,7.458,310.919,0,311.526c.891-.034,2.754,1.951,3.344,2.466A31.3,31.3,0,0,1,9.72,321.664Z'
                transform='translate(0 -235.53)'
                fill='#00af40'
              />
              <path
                id='Path_20583'
                data-name='Path 20583'
                d='M106.343,182.455c-5.169-2-10.916-1.694-16.334-1.251,1.342-.1,3.563,1.206,4.743,1.716a27.48,27.48,0,0,1,9.277,6.127c4.358,4.652,7.436,10.4,12.452,14.431a27.286,27.286,0,0,0,13.807,5.889c-3.958-1.371-6.379-6.375-8.217-9.766a67.513,67.513,0,0,0-4.718-8.1C114.52,187.656,110.858,184.2,106.343,182.455Z'
                transform='translate(-65.23 -140.976)'
                fill='#00af40'
              />
              <path
                id='Path_20584'
                data-name='Path 20584'
                d='M32.824,184.033c.763-.029,2.357,1.67,2.862,2.11a26.787,26.787,0,0,1,5.455,6.565c4.591,7.784,7.6,16,15.717,20.973a27.805,27.805,0,0,0,28.841.177c-3.726-.341-8.195-2.661-11.3-4.609-3.919-2.463-7.081-6.585-9.881-10.184-3.85-4.949-7.425-8.752-13.051-11.748C45.767,184.277,39.205,183.512,32.824,184.033Z'
                transform='translate(-23.788 -143.153)'
                fill='#00af40'
              />
              <path
                id='Path_20585'
                data-name='Path 20585'
                d='M258.035,104.656c-3.59,5.268-8.528,9.524-11.369,15.3a27.276,27.276,0,0,0-2.736,14.758c.475-4.162,4.83-7.615,7.739-10.149a67.514,67.514,0,0,0,6.874-6.371c3.136-3.6,5.712-7.931,6.429-12.718.821-5.48-.727-11.022-2.341-16.213.387,1.289-.4,3.74-.64,5A27.492,27.492,0,0,1,258.035,104.656Z'
                transform='translate(-176.675 -74.581)'
                fill='#00af40'
              />
              <path
                id='Path_20586'
                data-name='Path 20586'
                d='M211.458,94.039c-.48-3.71.81-8.577,2.036-12.029,1.549-4.362,4.883-8.346,7.784-11.864,3.99-4.837,6.921-9.155,8.619-15.3,1.723-6.23,1.038-12.8-.861-18.914.195.739-1.115,2.664-1.436,3.253a26.8,26.8,0,0,1-5.218,6.755c-6.6,6.178-13.962,10.9-17.041,19.912A27.8,27.8,0,0,0,211.458,94.039Z'
                transform='translate(-147.721 -35.934)'
                fill='#00af40'
              />
            </g>
          </motion.g>
          {/* Leaves right */}
          <motion.g
            animate={leaves}
            id='leaves-svgrepo-com-2'
            data-name='leaves-svgrepo-com'
            transform='translate(412.002 2476.063)'
          >
            <g
              id='Group_1623-2'
              data-name='Group 1623'
              transform='translate(0 35.934)'
            >
              <path
                id='Path_20577-2'
                data-name='Path 20577'
                d='M284.57,317.82a32.118,32.118,0,0,1,10.842-7.159c1.38-.595,3.975-2.118,5.544-2.006-6.332-.517-13.048-.872-19.089,1.463-5.277,2.037-9.556,6.078-12.867,10.573a78.967,78.967,0,0,0-5.514,9.464c-2.149,3.963-4.978,9.81-9.6,11.413a31.883,31.883,0,0,0,16.136-6.881C275.881,329.974,279.477,323.257,284.57,317.82Z'
                transform='translate(-183.989 -233.301)'
                fill='#00af40'
              />
              <path
                id='Path_20578-2'
                data-name='Path 20578'
                d='M306.811,315.361c-6.575,3.5-10.753,7.945-15.253,13.729-3.273,4.2-6.968,9.022-11.548,11.9-3.624,2.278-8.847,4.988-13.2,5.386a32.5,32.5,0,0,0,33.706-.206c9.49-5.808,13-15.414,18.368-24.511a31.344,31.344,0,0,1,6.376-7.672c.59-.515,2.453-2.5,3.344-2.466C321.145,310.916,313.477,311.809,306.811,315.361Z'
                transform='translate(-193.358 -235.528)'
                fill='#00af40'
              />
              <path
                id='Path_20579-2'
                data-name='Path 20579'
                d='M267.884,191.5a67.436,67.436,0,0,0-4.718,8.1c-1.839,3.39-4.26,8.395-8.218,9.766a27.272,27.272,0,0,0,13.807-5.889c5.018-4.031,8.094-9.779,12.452-14.431a27.5,27.5,0,0,1,9.276-6.127c1.18-.509,3.4-1.812,4.743-1.716-5.418-.443-11.165-.745-16.333,1.251C274.378,184.2,270.717,187.657,267.884,191.5Z'
                transform='translate(-184.763 -140.977)'
                fill='#00af40'
              />
              <path
                id='Path_20580-2'
                data-name='Path 20580'
                d='M316.395,186.144c.505-.44,2.1-2.139,2.862-2.11-6.381-.519-12.942.246-18.646,3.285-5.626,3-9.2,6.8-13.051,11.747-2.8,3.6-5.962,7.721-9.882,10.184-3.1,1.95-7.57,4.268-11.3,4.609a27.806,27.806,0,0,0,28.841-.177c8.119-4.969,11.125-13.188,15.717-20.972A26.765,26.765,0,0,1,316.395,186.144Z'
                transform='translate(-193.049 -143.154)'
                fill='#00af40'
              />
              <path
                id='Path_20581-2'
                data-name='Path 20581'
                d='M82.783,317.819c5.093,5.438,8.69,12.154,14.553,16.866a31.873,31.873,0,0,0,16.136,6.881c-4.626-1.6-7.455-7.45-9.6-11.413a78.919,78.919,0,0,0-5.514-9.464c-3.312-4.5-7.591-8.537-12.867-10.574-6.041-2.334-12.757-1.979-19.089-1.462,1.569-.113,4.164,1.41,5.543,2.005A32.122,32.122,0,0,1,82.783,317.819Z'
                transform='translate(-48.119 -233.3)'
                fill='#00af40'
              />
              <path
                id='Path_20582-2'
                data-name='Path 20582'
                d='M9.72,321.664c5.366,9.1,8.879,18.7,18.368,24.51a32.5,32.5,0,0,0,33.706.206c-4.354-.4-9.577-3.109-13.2-5.386-4.581-2.879-8.275-7.7-11.548-11.9-4.5-5.784-8.678-10.227-15.253-13.728C15.125,311.812,7.458,310.919,0,311.526c.891-.034,2.754,1.951,3.344,2.466A31.3,31.3,0,0,1,9.72,321.664Z'
                transform='translate(0 -235.53)'
                fill='#00af40'
              />
              <path
                id='Path_20583-2'
                data-name='Path 20583'
                d='M106.343,182.455c-5.169-2-10.916-1.694-16.334-1.251,1.342-.1,3.563,1.206,4.743,1.716a27.48,27.48,0,0,1,9.277,6.127c4.358,4.652,7.436,10.4,12.452,14.431a27.286,27.286,0,0,0,13.807,5.889c-3.958-1.371-6.379-6.375-8.217-9.766a67.513,67.513,0,0,0-4.718-8.1C114.52,187.656,110.858,184.2,106.343,182.455Z'
                transform='translate(-65.23 -140.976)'
                fill='#00af40'
              />
              <path
                id='Path_20584-2'
                data-name='Path 20584'
                d='M32.824,184.033c.763-.029,2.357,1.67,2.862,2.11a26.787,26.787,0,0,1,5.455,6.565c4.591,7.784,7.6,16,15.717,20.973a27.805,27.805,0,0,0,28.841.177c-3.726-.341-8.195-2.661-11.3-4.609-3.919-2.463-7.081-6.585-9.881-10.184-3.85-4.949-7.425-8.752-13.051-11.748C45.767,184.277,39.205,183.512,32.824,184.033Z'
                transform='translate(-23.788 -143.153)'
                fill='#00af40'
              />
              <path
                id='Path_20585-2'
                data-name='Path 20585'
                d='M258.035,104.656c-3.59,5.268-8.528,9.524-11.369,15.3a27.276,27.276,0,0,0-2.736,14.758c.475-4.162,4.83-7.615,7.739-10.149a67.514,67.514,0,0,0,6.874-6.371c3.136-3.6,5.712-7.931,6.429-12.718.821-5.48-.727-11.022-2.341-16.213.387,1.289-.4,3.74-.64,5A27.492,27.492,0,0,1,258.035,104.656Z'
                transform='translate(-176.675 -74.581)'
                fill='#00af40'
              />
              <path
                id='Path_20586-2'
                data-name='Path 20586'
                d='M211.458,94.039c-.48-3.71.81-8.577,2.036-12.029,1.549-4.362,4.883-8.346,7.784-11.864,3.99-4.837,6.921-9.155,8.619-15.3,1.723-6.23,1.038-12.8-.861-18.914.195.739-1.115,2.664-1.436,3.253a26.8,26.8,0,0,1-5.218,6.755c-6.6,6.178-13.962,10.9-17.041,19.912A27.8,27.8,0,0,0,211.458,94.039Z'
                transform='translate(-147.721 -35.934)'
                fill='#00af40'
              />
            </g>
          </motion.g>
          {/* App */}
          <motion.g
            animate={app}
            id='页面-1'
            transform='translate(555.495 2472.996)'
          >
            <g id='导航图标' transform='translate(1 1)'>
              <g id='数据' transform='translate(0 0)'>
                <g id='编组'>
                  <rect
                    id='矩形'
                    width='148'
                    height='148'
                    fill='rgba(255,255,255,0.01)'
                  />
                  <path
                    id='路径'
                    d='M125.167,2.292H1.833v37H125.167Z'
                    transform='translate(10.5 13.125)'
                    fill='none'
                    stroke='#434343'
                    stroke-linejoin='round'
                    stroke-width='9.5'
                    fill-rule='evenodd'
                  />
                  <path
                    id='路径-2'
                    data-name='路径'
                    d='M1.833,55.635,39.375,17.707,59.661,37.135l24.8-24.76L98.276,25.843'
                    transform='translate(10.5 70.875)'
                    fill='none'
                    stroke='#434343'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='9.5'
                    fill-rule='evenodd'
                  />
                  <line
                    id='路径-3'
                    data-name='路径'
                    y2='80.167'
                    transform='translate(135.667 49.863)'
                    fill='none'
                    stroke='#434343'
                    stroke-linecap='round'
                    stroke-width='9.5'
                  />
                  <line
                    id='路径-4'
                    data-name='路径'
                    y2='43.167'
                    transform='translate(12.333 49.863)'
                    fill='none'
                    stroke='#434343'
                    stroke-linecap='round'
                    stroke-width='9.5'
                  />
                  <line
                    id='路径-5'
                    data-name='路径'
                    x2='95.535'
                    transform='translate(40.131 132.583)'
                    fill='none'
                    stroke='#434343'
                    stroke-linecap='round'
                    stroke-width='9.5'
                  />
                  <line
                    id='路径-6'
                    data-name='路径'
                    x2='64.75'
                    transform='translate(52.417 33.917)'
                    fill='none'
                    stroke='#434343'
                    stroke-linecap='round'
                    stroke-width='9.5'
                  />
                  <line
                    id='路径-7'
                    data-name='路径'
                    x2='3.083'
                    transform='translate(30.833 33.906)'
                    fill='none'
                    stroke='#434343'
                    stroke-linecap='round'
                    stroke-width='9.5'
                  />
                </g>
              </g>
            </g>
          </motion.g>
          {/* Home */}
          <motion.g
            animate={home}
            id='home-svgrepo-com_3_'
            data-name='home-svgrepo-com (3)'
            transform='translate(211.359 2321.124)'
          >
            <path
              id='Path_20478'
              data-name='Path 20478'
              d='M302.285,138.283l-72.344-69.65V13.2a13.028,13.028,0,1,0-26.056,0V43.578L162.17,3.429c-4.572-4.572-12.4-4.572-17.6,0L4.517,138.283c-5.2,5.2-5.888,13.028-.626,18.227s13.028,5.888,18.227.626l1.942-1.879V293.3a13.1,13.1,0,0,0,13.028,13.028H270.341c7.14,0,12.4-5.888,13.028-12.4V156.572l1.315,1.253c9.521,6.577,16.285,1.942,18.227-1.315C307.483,151.311,307.483,142.855,302.285,138.283ZM176.513,280.966h-45.6V211.253h45.6Zm80.8,0h-55.37V198.225A13.1,13.1,0,0,0,188.915,185.2H117.887a13.1,13.1,0,0,0-13.028,13.028v82.741H50.115V153.253h207.2V280.966ZM52.621,127.885,153.714,30.8l100.091,97.085Z'
              transform='translate(0)'
              fill='#434343'
            />
          </motion.g>
        </g>
      </g>
    </svg>
  )
}

export default ALed
