import { useCallback, useEffect, useMemo } from 'react';
import { Divider, Center, Button, useDisclosure } from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { FiRefreshCcw } from 'react-icons/fi';

import Section from 'components/Section';
import ActionBarWrap from 'components/ActionBar';
import ComboTable from 'components/Table/ComboTable';
import BreadcrumbWrapper from 'components/Breadcrumb';
import ModalWrapper from 'components/Modal';

import { useTranslator } from 'utils/translator';
import { COMPONENT, ESTIMATE_LINE_ITEM, ESTIMATE_LINE_ITEMS } from 'constants/api';
import { DELETE, GET, POST } from 'constants/method';
import { useConfirmationDialog, useFetchData, useFetchOnce, useQuery } from 'utils/hooks';

import AddItem from './AddItem';
import EditItem from './EditItem';

import { devicesListColumns } from '../configs';

const LineItems = () => {
  const { t } = useTranslator();
  const {
    deviceName,
    deviceEstimateId,
  } = useQuery(['deviceEstimateId', 'deviceName']);
  const { getConfirmation } = useConfirmationDialog();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { fetchData: fetchOnce } = useFetchOnce({}, DELETE);
  const { data, fetchData, isLoading } = useFetchOnce([], POST);
  const { data: componentList } = useFetchData({ content: [] }, `${COMPONENT}`);
  const { data: deviceInfo, setData, fetchData: fetchDeviceInfo } = useFetchOnce({}, GET);

  const getDevices = useCallback(() => {
    fetchData(`${ESTIMATE_LINE_ITEMS}?deviceEstimateId=${deviceEstimateId}`);
  }, [deviceEstimateId, fetchData]);
  
  useEffect(() => {
    if (deviceEstimateId) {
      getDevices();
    }
  }, [getDevices, deviceEstimateId]);

  const handleOnDelete = async (id: string) => {
    const onDelete = async () => {
      const response = await fetchOnce(`${ESTIMATE_LINE_ITEM}/${id}`);
      !response?.hasError && getDevices();
    };
    await getConfirmation(onDelete);
  };

  const handleOnEdit =  async (id: string) => {
    const response = await fetchDeviceInfo(`${ESTIMATE_LINE_ITEM}/${id}`);
    response?.ok && onOpen();
  };

  const TitleComponent = useMemo(() => (
    <BreadcrumbWrapper items={[
      { name: t('_Dynamo_'), path: '/dynamo/devices/device-estimate' },
      { name: t('_DeviceEstimate_'), path: '/dynamo/devices/device-estimate' },
      { name: deviceName, path: '/dynamo/devices/device-estimate' },
      { name: t('_LineItems_') }
    ]}
    />
  ), [deviceName, t]);

  const getActions = (row: any) => {
    const { original: { id } } = row;
    return (
      <ActionBarWrap options={[
        {
          name: t('_Edit_'),
          icon: <EditIcon />,
          onClick: () => handleOnEdit(id),
        },
        {
          name: t('_Delete_'),
          icon: <DeleteIcon />,
          onClick: () => handleOnDelete(id),
        },
      ]}
      />
    );
  };

  const handleCloseModal = (isUpdate: boolean) => {
    if (isUpdate) getDevices();
    setData(null);
    onClose();
  };

  return (
    <Section
      title={TitleComponent}
      actionBar={(
        <>
          <Button size="md" variant="primary" onClick={onOpen}>
            {t('_AddItem_')}
          </Button>
          <Center height="40px" marginLeft="5px">
            <Divider orientation="vertical" />
          </Center>
          <Button
            size="sm"
            variant="secondary"
            onClick={getDevices}
          >
            <FiRefreshCcw />
          </Button>
        </>
      )}
    >
      <ComboTable
        columns={devicesListColumns}
        data={data}
        isLoading={isLoading}
        getActions={getActions}
      />
      <ModalWrapper isOpen={isOpen}>
        {deviceInfo
          ? <EditItem
              qty={deviceInfo?.line_item_qty}
              componentId={deviceInfo.line_item_component?.id}
              deviceEstimateId={deviceEstimateId}
              closeModal={handleCloseModal}
            />
          : <AddItem
              lineItem={deviceInfo}
              componentList={componentList}
              deviceEstimateId={deviceEstimateId}
              closeModal={handleCloseModal}
            />
          }
      </ModalWrapper>
    </Section>
  );
};

export default LineItems;
