import { useState, createContext } from 'react';

import noop from 'utils/noop';

import Dialog from './ConfirmDialog';

import { ConfirmationDialogType, DialogContextType } from './type';

export const ConfirmDialogContext = createContext({} as DialogContextType);

export const defaultDialogOption = {
  isOpen: false,
  actionCallback: noop,
  noBtnText: 'No',
  yesBtnText: 'Yes',
  text: 'Are you sure?',
};

const ConfirmDialogProvider = ({ children, ...props }: ConfirmationDialogType) => {
  const [dialog, setDialog] = useState(defaultDialogOption);

  return (
    <ConfirmDialogContext.Provider value={{ dialog, setDialog }} {...props}>
      {children}
      <Dialog />
    </ConfirmDialogContext.Provider>
  );
};

export default ConfirmDialogProvider;
