import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IUserInfoState {
  value: Object | null;
  userDetails: Object | null;
  roles: string[];
}


const initialState: IUserInfoState = {
  value: null,
  userDetails: null,
  roles: []
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
      state.userDetails = action.payload.user;
      state.roles = action.payload.roles || [];
    },
  },
});

export const { setUserInfo } = userSlice.actions;

export default userSlice.reducer;
