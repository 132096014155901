import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useToast,
  Button,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Tooltip,
  Select,
  Divider,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';

import { uuid } from 'utils';
import { AnyObject } from 'utils/types';
import { POST } from 'constants/method';
import { useTranslator } from 'utils/translator';
import { ERROR, SUCCESS } from 'constants/toaster';
import useFetchOnce from 'utils/hooks/useFetchOnce';
import { DEVICE_ESTIMATE_FOR_QTY, DEVICE_ESTIMATE_FOR_QTY_TIER } from 'constants/api';

import { EstimateType } from './DeviceEstimate/types';

type PriceGeneratePropsType = {
  deviceList?: Array<any>,
  tierList?: Array<any>,
  closeModal: Function;
  estimateInfo?: AnyObject;
};

const PriceGenerate = ({
  deviceList,
  tierList,
  closeModal,
}: PriceGeneratePropsType) => {
  const { t } = useTranslator();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const [baseCost, setBaseCost] = useState(0);

  const { fetchData, isLoading } = useFetchOnce({}, POST);

  const handleCloseModal = (update?: boolean) => {
    closeModal(update);
  };

  const calculate = async (data: EstimateType) => {
    const url: string = data.deviceTier
      ? `${DEVICE_ESTIMATE_FOR_QTY_TIER}?deviceName=${data.device_name}&deviceTier=${data.deviceTier}&qty=${data.qty}`
      : `${DEVICE_ESTIMATE_FOR_QTY}?deviceName=${data.device_name}&qty=${data.qty}`;
    const response = await fetchData(url);
    if (response?.ok) {
      toast(SUCCESS);
      setBaseCost(response.data.base_cost);
    } else {
      toast(ERROR);
    }
  };

  const handlePriceCalculate: SubmitHandler<EstimateType> = async (
    data: EstimateType
  ) => calculate(data);

  const tierOptions = tierList ? tierList.map(({ tier_name }) => ({ label: tier_name, value: tier_name })) : [];
  const deviceOptions = deviceList ? deviceList.map(({ device_name }) => ({ label: device_name, value: device_name })) : [];

  return (
    <Modal isOpen onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('_DeviceQuantityCalculate_')}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handlePriceCalculate)}>
          <ModalBody pb={6}>
            <FormControl isInvalid={!!errors.device_name}>
              <FormLabel>{t('_Device_') + ' *'}</FormLabel>
              <Tooltip label={errors?.device_name?.message}>
                <div style={{
                  border: `${errors?.device_name?.message ? 2 : 1}px solid`,
                  borderColor: errors?.device_name?.message ? 'red' : 'inherit',
                  borderRadius: '8px',
                  marginBottom: '10px',
                }}>
                  <Select
                    size='lg'
                    variant='primary'
                    placeholder={t('_DeviceName_')}
                    {...register('device_name', {
                      required: t('_ErrorFill_'),
                    })}
                  >
                    {deviceOptions?.map(option => (
                      <option style={{ fontSize: '14px', color: 'green.800' }}
                        key={option?.value || uuid()}
                        value={option?.value || option}
                      >
                        {option?.label || option}
                      </option>
                    ))}
                  </Select>
                </div>
              </Tooltip>
            </FormControl>
            <FormControl>
              <FormLabel>{t('_Tier_')}</FormLabel>
              <div style={{
                border: '1px solid',
                borderColor: 'inherit',
                borderRadius: '8px',
                marginBottom: '10px',
              }}>
                <Select
                  size='lg'
                  variant='primary'
                  placeholder={t('_TierName_')}
                  {...register('deviceTier')}
                >
                  {tierOptions?.map(option => (
                    <option style={{ fontSize: '14px', color: 'green.800' }}
                      key={option?.value || uuid()}
                      value={option?.value || option}
                    >
                      {option?.label || option}
                    </option>
                  ))}
                </Select>
              </div>
            </FormControl>
            <FormControl isInvalid={!!errors.qty} marginBottom={2}>
              <FormLabel>{t('_Qty_') + ' *'}</FormLabel>
              <Tooltip label={errors?.qty?.message}>
                <Input
                  type="number"
                  placeholder={t('_Qty_')}
                  {...register('qty', {
                    required: t('_ErrorFill_'),
                    pattern: {
                      value: /^[0-9]*$/,
                      message: t('_ErrorNumber_'),
                    }
                  })}
                />
              </Tooltip>
            </FormControl>
            <Divider orientation='horizontal' margin="20px 0" />
            <FormControl isInvalid={!!errors.base_cost}>
              <Tooltip label={errors?.base_cost?.message}>
                <InputGroup>
                  <InputLeftAddon children={t('_BaseCost_')} />
                  <Input
                    readOnly
                    value={baseCost + '$'}
                    {...register('base_cost')}
                  />
                </InputGroup>
              </Tooltip>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={() => handleCloseModal()}>{t('_Cancel_')}</Button>
            <Button variant='primary' ml={3} type='submit' isLoading={isLoading} loadingText="Calculate">
              {t('_Submit_')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default PriceGenerate;
