import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  Text,
  Menu,
  Avatar,
  HStack,
  VStack,
  MenuItem,
  MenuList,
  IconButton,
  MenuButton,
  MenuDivider,
  useColorModeValue,
  useDisclosure,
  SkeletonText,
} from '@chakra-ui/react';

import { FiBell, FiChevronDown } from 'react-icons/fi';

import { useTranslator } from 'utils/translator';
import { clearToken } from 'store/features/token';

import ChangePass from './ChangePass';
import UserDetails from './UserDetails';
import { setUserInfo } from 'store/features/user';

const Profile = ({ user }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslator();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: changePassModal,
    onOpen: openChangePassModal,
    onClose: closeChangePassModal,
  } = useDisclosure();

  const handleLogOut = () => {
    dispatch(clearToken());
    dispatch(setUserInfo({}))
    navigate('/home');
  };

  return (
    <>
      <HStack spacing={{ base: '0', md: '6' }}>
        <IconButton
          size='lg'
          variant='ghost'
          aria-label='open menu'
          icon={<FiBell />}
        />
        <Flex alignItems='center'>
          <Menu>
            <MenuButton
              py={2}
              transition='all 0.3s'
              _focus={{ boxShadow: 'none' }}
            >
              <HStack>
                <Avatar size="sm" src={user?.avatarUrl} />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems='flex-start'
                  spacing='1px'
                  ml='2'
                >
                  {user
                    ? <>
                        <Text fontSize='sm'>{`${user?.firstName} ${user?.lastName}`}</Text>
                        <Text fontSize='xs' color='gray.600'>
                          {user?.email}
                        </Text>
                      </>
                    : <SkeletonText w="10rem" mt='2' noOfLines={2} spacing='2' />
                  }
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              zIndex='5'
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem onClick={onOpen}>{t('_Profile_')}</MenuItem>
              <MenuItem onClick={openChangePassModal}>{t('_ChangePassword_')}</MenuItem>
              <MenuDivider />
              <MenuItem onClick={handleLogOut}>{t('_SignOut_')}</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
      <UserDetails isOpen={isOpen} closeModal={onClose} user={user} />
      <ChangePass
        openModal={changePassModal}
        setOpenModal={closeChangePassModal}
      />
    </>
  );
};

export default Profile;
