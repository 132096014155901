import { Input, InputGroup, InputRightElement, Stack } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSearchInfoValue } from 'store/features/locationSearch';
import useDebounce from 'utils/hooks/useDebounce';

const SearchInput = () => {

  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('')

  const debouncedValue = useDebounce(searchValue, 800)

  useEffect(() => {
    dispatch(setSearchInfoValue(debouncedValue));
  },[dispatch, debouncedValue])
  
  return (
    <Stack spacing={4}>
      <InputGroup>
        <InputRightElement
          height='100%'
          pointerEvents='none'
          children={<SearchIcon color='gray.300' />}
        />
        <Input
          value={searchValue}
          type='search'
          placeholder='Search'
          size='md'
          background='#FFFFFF 0% 0% no-repeat padding-box'
          boxShadow='0px 0px 6px #00000033'
          border='1px solid #CBCBCB'
          onChange={(e : any) => setSearchValue(e.target.value)}
        />
      </InputGroup>
    </Stack>
  );
};

export default SearchInput;