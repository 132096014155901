const MAP_SETTINGS = {
  DEFAULT_MAP_OPTIONS: {
    // disableDefaultUI: true,
    zoomControl: true,
    mapTypeControl: true,
    scaleControl: true,
    rotateControl: true,
  },
  DEFAULT_CENTER: { lat: 36.778259, lng: -119.417931 },
  DEFAULT_ZOOM: 7,
  MARKER_SIZE: 30,
  PIXEL_OFFSET: {
    MARKER: {
      X: 0,
      Y: -35,
    },
  },
  DIRECTIONS_OPTIONS: { suppressMarkers: true, preserveViewport: true },
  CONTAINERSTYLE: {
    width: '100%',
    height: '75vh',
  },
};

export default MAP_SETTINGS;
