export const tierListColumns = [
  {
    Header: 'Tier name',
    accessor: 'tier_name'
  },
  {
    Header: 'Ownership type',
    accessor: 'ownership_type'
  },
  {
    Header: 'Overhead',
    accessor: 'overhead_p'
  },
  {
    Header: 'Margin',
    accessor: 'margine_p'
  },
  {
    Header: 'R & D',
    accessor: 'rnd_p'
  },
];
